import React from "react";
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import ProDisplay from 'ui/typography/proDisplay';
import {
  Link,
  Text,
  Title,
  ShowCalendar,
} from "./style";

const SectionTitle = ({
  children,
  description,
  link,
  toggle,
  showCalendarOnMobile,
  // HOC, dispatch
  pushUrl,
}) => (
  <Title>
    {children ? (
      <Text>
        <ProDisplay
          type="5"
          configuration={{
            fontSize: 20,
            lineHeight: 1.86
          }}
        >
          {children}
        </ProDisplay>
      </Text>
    ) : null}
    {description ? (
      <Text>
        <ProDisplay
          type="2"
          configuration={{
            fontSize: 18,
            lineHeight: 1.2
          }}
        >
          {description}
        </ProDisplay>
      </Text>
    ) : null}
    {link?.to ? (
      <Link
        className="align-right"
        onClick={() => pushUrl(link.to)}
      >
        <ProDisplay
          type="2"
          configuration={{
            color: 'primary',
            colorType: 'base',
            fontSize: 18,
            lineHeight: 1.14
          }}
        >
          {link?.text || link?.to}
        </ProDisplay>
      </Link>
    ) : null}
    {toggle ? (
      <ShowCalendar onClick={toggle}>
        <ProDisplay
          type="2"
          configuration={{
            color: 'primary',
            colorType: 'base',
            fontSize: 18,
            lineHeight: 1.14
          }}
        >
          {showCalendarOnMobile ? 'Nascondi' : 'Vedi'} Calendario
        </ProDisplay>
      </ShowCalendar>
    ): null}
  </Title>
);

SectionTitle.propTypes = {
  description: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    to: PropTypes.string
  }),
  toggle: PropTypes.func,
  showCalendarOnMobile: PropTypes.bool,

  // HOC, dispatch
  pushUrl: PropTypes.func,
};

export default connect(
  state => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
  })
)(SectionTitle);
