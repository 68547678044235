import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'ui/gridSystem';
import {
  SelectWrapper,
  SessionsPopup,
  TableCardContainer
} from 'ui/components/TableCard/style';
import { CustomInput, CustomSelect } from 'ui/atoms';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { styles } from 'utils/multi-select';
import validator from 'utils/regex-validator';
import { getLocaleStringFromDateTime } from 'utils/date-format';
import { mappingVariations } from '../NewSubscriptions';
import ProText from 'ui/typography/proText';

const TableCardEmpty = ({
  subscription,
  handleOnChangeEmail,
  emailName,
  options,
  calendarDates,
  handleOnChangeVariation,
  handleSelectCalendar
}) => {
  const [value, setValue] = useState(emailName);
  const [showSessions, setShowSessions] = useState(false);
  const [isBasePkg, setIsBasePkg] = useState(true);
  const [selectedCalendar, setSelectedCalendar] = useState(null);

  const { id, email, variations, error } = subscription;

  const handleOnChange = newValue => {
    setValue(newValue);
  };

  useEffect(() => {
    if (emailName) {
      setValue(emailName);
    }
  }, [emailName]);

  const handleOnBlur = () => {
    handleOnChangeEmail(id, value);
  };

  const formatProductOptionLabel = ({label, packageType = 'empty' }) => (
    <div className='premium-container'>
      <div>{label}</div>
      {packageType.toLowerCase() === 'premium' ?
      <span className='premium'>
        Premium
        <span className='icon-premium'></span>
      </span>
      : ''}
    </div>
  );

  const formatCalendarOptionLabel = ({label, unavailable = false }) => (
    <div className='premium-container'>
      <div>{label}</div>
      {unavailable ? (
        <span className='unavailable'>
          Posti<br />Esauriti
        </span>
      ) : null}
    </div>
  );

  const getSessionDates = (session) => {
    const [startDate, startTime] = getLocaleStringFromDateTime(new Date(session.startDate)).split('/');
    const [endDate, endTime] = getLocaleStringFromDateTime(new Date(session.endDate)).split('/');

    const date = `${startDate}${endDate !== startDate ? ` - ${endDate}` : ''}`;
    const time = `${startTime}${endTime !== startTime ? ` - ${endTime}` : ''}`;

    return `${date}   ${time}`;
  }

  const sessions = (() => (
    /* NOTE, requires product select to be single selection */
    subscription?.variations[0]?.calendarId
      ? calendarDates.find(calendarDate => (
        calendarDate.calendarId === subscription.variations[0].calendarId
      ))?.sessions.sort(function(x, y){
          return new Date(x.startDate) - new Date(y.startDate);
        }) ?? []
      : []
  ))();

  return (
    <>
      <TableCardContainer grayBg className='mb-4 mb-md-0'>
        <Row className='position-relative'>
          <Col lg={4} md={4}>
            <CustomInput
              type="text"
              value={email}
              placeholder="Email utente"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              error={error}
            />
          </Col>
          <Col lg={4} md={4} className="mb-3 mb-sm-5 mb-md-0">
            <SelectWrapper>
              <CustomSelect
                placeholder="Seleziona"
                options={options}
                onChange={newVariations =>{
                  setIsBasePkg(newVariations.packageType.toLowerCase() === 'base');
                  handleOnChangeVariation(id, variations, newVariations)
                  }
                }
                formatOptionLabel={formatProductOptionLabel}
                disabled={!email || (email && !validator.email(email))}
                selected={variations.map(mappingVariations)}
                styles={styles}
                hideSelectedOptions={true}
              />
            </SelectWrapper>
          </Col>
          <Col lg={4} md={4}>
            <SelectWrapper
              onMouseEnter={() => sessions?.length && setShowSessions(true)}
              onMouseLeave={() => setShowSessions(false)}
            >
              <CustomSelect
                placeholder={(
                  <span className='placeholder-icon'>
                    <span className='icon-calendar'>
                    </span>Pianificazione
                  </span>
                )}
                options={
                  calendarDates
                    .map((calendar) => ({
                      value: calendar.calendarId,
                      label: calendar.title,
                      unavailable: calendar.availableSeats < 1,
                    }))
                }
                formatOptionLabel={formatCalendarOptionLabel}
                onChange={ selectedCalendar => {
                  const actualCalendar = calendarDates.find(calendarDate => (
                      calendarDate.calendarId === selectedCalendar.value
                  ));
                  setSelectedCalendar(selectedCalendar);
                  handleSelectCalendar(
                      actualCalendar,
                      variations.map(mappingVariations),
                      emailName,
                  );
                  }
                }
                selected={selectedCalendar}
                className={isBasePkg ? 'disabled' : ''}
                disabled={(calendarDates?.length === 0 || isBasePkg) || (!email || (email && !validator.email(email))) }
                styles={styles}
                hideSelectedOptions={true}
              />
            </SelectWrapper>
          </Col>
          {showSessions ? (
            <SessionsPopup>
              {sessions?.map(session => (
                <>
                {session.liveEventMode === 'virtual' ?
                <ProText
                configuration={{
                  fontSize: 14,
                  fontWeight: 500
                }}
                ><span className='mode'>{session.liveEventMode}</span> | {getSessionDates(session)}</ProText>
                :
                <ProText
                configuration={{
                  fontSize: 14,
                  fontWeight: 500
                }}
                ><span className='mode'>{session.liveEventMode}</span> | {getSessionDates(session)} | {session?.locations[0]?.city}</ProText>
              }
                </>
              ))}
            </SessionsPopup>
          ) : null}
        </Row>
      </TableCardContainer>
    </>
  );
};

TableCardEmpty.propTypes = {
  subscription: PropTypes.object.isRequired,
  handleOnChangeEmail: PropTypes.func.isRequired,

  emailName: PropTypes.string,
  options: PropTypes.array,
  handleOnChangeVariation: PropTypes.func.isRequired,
  calendarDates: PropTypes.array,
  handleSelectCalendar: PropTypes.func
};

TableCardEmpty.defaultProps = {
  calendarDates: []
}

export default withMediaQueries(TableCardEmpty);
