import styled from "styled-components/macro";

export const Banner = styled.div`
  background: ${props =>
    props.bg
      ? `url(${props.bg})`
      : props.theme.colors.primary.base
  };
  background-position-x: right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: ${props => props.height}px;
  justify-content: space-between;
  padding: 55px 60px;
  position: relative;
  background-size: cover;

  &.know-before {
    justify-content: flex-start;
  }


  @media ${(props) => props.theme.device.tablet} {
    padding: 20px;
    max-height: 170px;

    h3 span{
        font-size: 24px;
    }
  }
`;

export const BannerLogo = styled.img`
  &.know-before {
    --height: 130px;

    @media ${(props) => props.theme.device.mobile} {
      height: 30px;
    }
  }

  --height: 70px;
  background-color: ${props =>
    !props.src
      ? `${props.theme.colors.bitterLemon}`
      : ''
  };
  /* height: var(--height); */
  width: calc(var(--height) / 3 * 5);

  @media ${(props) => props.theme.device.tablet} {
    height: 50px;
    width: auto;
    margin-right: auto;
  }
`

export const BannerText = styled.h3`
  &.know-before {
    padding-top: 20px;

    @media ${(props) => props.theme.device.mobile} {
      padding-top: 10px;
    }
  }

  color: ${props => props.theme.colors.white};
  width: 70%;

  @media ${(props) => props.theme.device.tablet} {
    width: 100%;
    span{
      font-size: 30px;
      -webkit-box-orient: unset;
    }
  }
`