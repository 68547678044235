import styled, { css } from 'styled-components/macro';

export const TitleContainer = styled.div`
  margin-bottom: 19px;
`;
export const TitleBackWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const IconBack = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  transform: rotate(180deg);
  cursor: pointer;
  margin-top: 60px;
  margin-bottom: 5px;
  margin-top: 60px;
  margin-right: 15px;
`;
export const Title = styled.div`
  margin-top: 60px;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.primary.base};

  @media ${props => props.theme.device.mobile} {
    margin-top: 32px;
  }
`;

export const SubTitle = styled.div`
  margin: 31px 0 23px;

  &.no-margin-bottom {
    margin-bottom: 0;
  }
`;

export const PrivacyWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  cursor: pointer;
`;

export const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.colors.primary.darker};
  vertical-align: super;
`;

export const CheckboxWrapperText = styled.div`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `};
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 0 30px 0;

  @media ${props => props.theme.device.mobile} {
    padding: 25px 0 19px 0;
  }
`;

export const Icon = styled.span`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  margin-right: 10px;
`;

export const PasswordLegend = styled.div`
  margin: 8px 0 0px;
  color: ${props =>
    props.error ? props.theme.colors.redError : props.theme.colors.placeholder};
`;


export const InputWrapper = styled.div`
  padding-bottom: 16px;

  &.select {
    padding-bottom: 32px;
  }
`;