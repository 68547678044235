import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon
} from './style';
import { SET_RECOMMENDED } from 'redux/actions/playlist';

const modalId = 'RecommendModal';

const RecommendModal = ({ onClose, collectionId, recommendPL, payload }) => {
  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Title>
                <ProDisplay type="modalTitle">
                  Questa collection non fa parte del tuo piano formativo
                </ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                >
                  La collection selezionata non fa parte del tuo piano
                  formativo. Segnalala al tuo manager per poterla aggiungere
                </ProDisplay>
              </Subtitle>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button
                    onClick={onClose}
                    type={Button.TYPE.SECONDARY}
                    text="Annulla"
                  />
                </BtnContainer>
                <BtnContainer>
                  <Button
                    onClick={() => {
                      recommendPL(collectionId, payload);
                      onClose();
                    }}
                    type={Button.TYPE.PRIMARY}
                    text="Segnala"
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

RecommendModal.propTypes = {
  collectionId: PropTypes.string,
  onClick: PropTypes.func,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  recommendPL: PropTypes.func
};

RecommendModal.id = modalId;

export default connect(
  state => {
    const { collectionId } = state.modal.payload;
    return { collectionId };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    recommendPL: (id, payload) => dispatch({ type: SET_RECOMMENDED._REQUEST, id, payload })
  })
)(RecommendModal);
