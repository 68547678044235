import produce from 'immer';
import { LOCATION_CHANGE } from 'redux-first-history';
import { subscription_type } from 'utils/default-filters';

import {
  ACTIVE_SUBSCRIPTION_GET,
  SET_SUBSCRIPTION_ON_EDIT,
  SET_NEW_USERS_SUBSCRIPTIONS,
  RECOMMENDED_SUBSCRIPTION_GET,
  TEAMS_INTERESTS_GET,
  CONTENT_OVERVIEW_SET,
  SET_SUBSCRIPTIONS_FILTER,
  SET_SELECTED_SUBSCRIPTION,
  SET_PREMIUM_SUBSCRIPTIONS_FILTER,
  SET_PREMIUM_SUBSCRIPTIONS,
  ALL_PROSPECT_GET,
  ASSOC_SUBSCRIPTIONS_GET,
  GET_CALENDARS,
  EMAIL_SUBSCRIPTION_DELETE,
  EMAIL_SUBSCRIPTION_DISABLE,
  CATALOG_PRODUCT_GET,
  CREATE_PROSPECT,
  COMPACT_LIST,
  GET_CATEGORIES,
  GET_FILTERED_CATALOG,
  RESET_SUBSCRIPTIONS_INITIALSTATE,
  RESET_SUBSCRIPTIONS_ON_PAYMENT,
  GET_TOTAL_AMOUNT_ANNUAL_PAYMENT,
  GET_SUBSCRIPTION_PRODUCT_NAMES,
  SET_EMAILS_PREMIUM_SUBSCIRPTION, SET_DRAFT_SUBSCRIPTIONS_LOADED,
} from 'redux/actions/subscriptions';
import { priceFormat } from 'utils/price-format';
import { SIGNUP_RESET_CART } from '../actions/signup';

const CONTENT_TYPE = {
  MOST_VIEWED: 'most_viewed',
  MOST_POPULAR: 'most_popular',
};

const initialState = {
  active_subscriptions: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    subscriptions: [],
  },
  subscriptionOnEdit: undefined,
  premiumSubscription: {},
  recommended_subscriptions: {
    data: [],
  },
  teams_interests: [],
  catalog: [],
  filteredCatalog: [],
  most_viewed: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    contents: [],
  },
  most_popular: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    contents: [],
  },
  selected_subscription_plan: undefined,
  filters: {
    type: subscription_type.all.value,
    search: '',
  },
  categoriesOptions: [],
  premium_filters: {
    search: '',
  },
  new_users_subscriptions: [],
  assoc_subscriptions: {},
  all_prospect: {},
  calendars: {},
  mySubscriptions: {},
  content_learnings: {},
  premiumSubscriptions: [],
  dataLoaded: {},
  premium_subscription_emails: [],
};

export const getVariation = (
  { type = '', id: offerId = '', subscriptionId = '' },
  { orders: assoc_subscriptions }
) => {
  let productVariation = {};
  let varInd;
  (assoc_subscriptions || []).forEach(product => {
    if (subscriptionId) {
      const { order_item_id: productId } = product;

      varInd = productId?.toString() === subscriptionId.toString() ? 1 : -1;
    } else {
      const { variation_id, product_variation_type } = product;
      if (offerId) {
        varInd = variation_id?.toString() === offerId.toString() ? 1 : -1;
      } else if (type) {
        varInd = product_variation_type === type ? 1 : -1;
      }
    }
    if (varInd >= 0) productVariation = product;
  });
  return productVariation;
};

export const getProductLabelByType = (subType = '') => {
  switch (subType) {
    case 'Learning subscription':
      return 'Abbonamento';
    case 'Learning':
      return 'Pacchetto';
    default:
      return '-';
  }
};

export const getStatusLabelByStatus = (status = '') => {
  switch (status) {
    case 'active':
      return 'Attivo';
    case 'inactive':
      return 'Eliminato';
    case 'payment_pending':
      return 'Non attivo'; //In elaborazione';
    case 'payment-failed':
      return 'Non attivo'; //'Pagamento fallito';
    case 'registered':
      return 'In fase di attivazione'; //'Registrato';
    case 'not-registered':
      return 'Non attivo'; //'Non registrato';
    case 'inactiveready':
      return 'In fase di eliminazione';
    default:
      return '-';
  }
};

/* CURRENTLY NOT USED */
export const calcMySubscriptions = ({
  mySubscriptions = {},
  assoc_subscriptions = [],
  all_prospect = {},
} = {}) => {
  let total = 0;
  (Object.keys(all_prospect) || []).forEach((prospects = []) => {
    (all_prospect[prospects] || []).forEach(({ offerMain = [{}] } = {}) => {
      const { field_product_variation_ref = [{}], field_addons = [] } = getVariation(offerMain[0], assoc_subscriptions) || {};
      const { price = 0 } = field_product_variation_ref[0];

      total += Number(price);

      if (field_addons?.length) {
        (field_addons || []).forEach((collection = [{}]) => {
          total += Number(collection[0]?.price || 0);
        });
      }
    });
  });
  return {
    ...mySubscriptions,
    price: priceFormat(total),
  };
};

const subscriptionsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        draft.filters = initialState.filters;
        draft.premium_filters = initialState.premium_filters;

        break;
      }

      case ASSOC_SUBSCRIPTIONS_GET._REQUEST: {
        const { loadMore } = action;
        draft.dataLoaded.orders = !!loadMore;
        if (!loadMore) draft.assoc_subscriptions = initialState.assoc_subscriptions;
        break;
      }
      case ALL_PROSPECT_GET._REQUEST: {
        draft.dataLoaded.prospects = false;
        break;
      }

      case ASSOC_SUBSCRIPTIONS_GET._SUCCESS: {
        const { data: { orders = [], nextpage, nextpagepresent }, forceReload } = action;
        draft.assoc_subscriptions = {
          ...state.assoc_subscriptions,
          orders: state.assoc_subscriptions?.orders && !forceReload ? [...state.assoc_subscriptions?.orders, ...orders] : orders,
          nextpage,
          nextpagepresent,
        };
        draft.dataLoaded.orders = !nextpagepresent;
        break;
      }

      case GET_CALENDARS._SUCCESS: {
        const { productId, calendars } = action;
        const newCalendars = { ...draft.calendars }
        newCalendars[productId] = calendars;
        draft.calendars = newCalendars;
        break;
      }

      case SET_DRAFT_SUBSCRIPTIONS_LOADED: {
        draft.dataLoaded.orders = true;
        break;
      }

      case ACTIVE_SUBSCRIPTION_GET._SUCCESS: {
        const { response } = action;
        draft.active_subscriptions = response;
        const { from, subscriptions } = response;
        const data = !from || from <= 0
          ? subscriptions
          : [...state.active_subscriptions.subscriptions, ...subscriptions];
        draft.active_subscriptions = {
          ...action.response,
          subscriptions: data,
        };
        break;
      }

      case GET_TOTAL_AMOUNT_ANNUAL_PAYMENT._SUCCESS: {
        const { totalAmountAnnualPayment, totalItemsToAssociate } = action;
        draft.totalAmountAnnualPayment = totalAmountAnnualPayment;
        draft.totalItemsToAssociate = totalItemsToAssociate;
        break;
      }
      case ALL_PROSPECT_GET._SUCCESS: {
        const { response } = action;
        draft.all_prospect = response;
        draft.dataLoaded.prospects = true;
        break;
      }

      case SET_SUBSCRIPTION_ON_EDIT:
        draft.subscriptionOnEdit = action.subscription;
        break;

      case SET_PREMIUM_SUBSCRIPTIONS:
        draft.premiumSubscriptions = action.subscriptions;
        break;

      case RECOMMENDED_SUBSCRIPTION_GET._SUCCESS: {
        const { data = [] } = action;
        draft.recommended_subscriptions = data;
        break;
      }

      case TEAMS_INTERESTS_GET._SUCCESS: {
        const { content = [] } = action;
        const filtered_teams_interests = [];
        draft.teams_interests = content;
        if (content) {
          Object.keys(content).forEach((int, id) => {
            /* this still makes zero sense to the world
             * (but at worst it does nothing)
             */
            const prod = state.catalog.find(c => (
              c.learning_contents.length === 1
              && c.learning_contents.find(lc => (
                lc.type === 'collection'
                && lc?.nid?.toString() === int?.toString()
              ))
            ));
            if(prod){
              prod.users = content[int].users;
              filtered_teams_interests.push(prod);
            }
          });
        }
        draft.filtered_teams_interests = filtered_teams_interests;
        break;
      }
      case GET_FILTERED_CATALOG._SUCCESS: {
        const { content = [] } = action;
        draft.filteredCatalog = content;
        break;
      }
      case CATALOG_PRODUCT_GET._SUCCESS: {
        const { content = [] } = action;
        draft.catalog = content;
        break;
      }

      case SET_EMAILS_PREMIUM_SUBSCIRPTION: {
        const { premium_subscription_emails = [] } = action;
        draft.premium_subscription_emails = premium_subscription_emails;
        break;
      }

      case CONTENT_OVERVIEW_SET: {
        const { content_type } = action.response;
        switch (content_type) {
          case CONTENT_TYPE.MOST_POPULAR: {
            draft.most_popular = action.response;
            const { from, contents } = action.response;
            const data = !from || from <= 0
              ? contents
              : [...state.most_popular.contents, ...contents];
            draft.most_popular = {
              ...action.response,
              contents: data,
            };
            break;
          }
          case CONTENT_TYPE.MOST_VIEWED: {
            draft.most_viewed = action.response;
            const { from, contents } = action.response;
            const data = !from || from <= 0
              ? contents
              : [...state.most_popular.contents, ...contents];
            draft.most_viewed = {
              ...action.response,
              contents: data,
            };
            break;
          }
          default:
        }
        break;
      }

      case GET_SUBSCRIPTION_PRODUCT_NAMES._SUCCESS: {
        const { productNames } = action;
        draft.productNames = productNames;
        break;
      }

      case SIGNUP_RESET_CART: {
        draft.selected_subscription_plan = initialState.selected_subscription_plan;
        draft.premium_subscription_emails = initialState.premium_subscription_emails;
        break;
      }

      case SET_SELECTED_SUBSCRIPTION:
        draft.selected_subscription_plan = action.plan;
        break;
      case SET_SUBSCRIPTIONS_FILTER:
        draft.filters = action.filters;
        draft.premium_filters = action.premium_filters;
        break;
      case SET_PREMIUM_SUBSCRIPTIONS_FILTER:
        draft.premium_filters = action.premium_filters;
        break;
      case SET_NEW_USERS_SUBSCRIPTIONS._SUCCESS:
        draft.new_users_subscriptions = action.payload;
        break;

      case EMAIL_SUBSCRIPTION_DELETE._SUCCESS:
      case EMAIL_SUBSCRIPTION_DISABLE._SUCCESS:
        draft.subscriptionOnEdit = initialState.subscriptionOnEdit;
        break;

      case CREATE_PROSPECT._SUCCESS:
        // console.log(action.response);
        break;
      case COMPACT_LIST._SUCCESS: {
        const { type, data } = action.payload;
        draft.content_learnings[type] = data;
        break;
      }
      case GET_CATEGORIES._SUCCESS: {
        draft.categoriesOptions = [{ id: '0', name: 'Tutte' }, ...action.data];
        break;
      }
      case RESET_SUBSCRIPTIONS_INITIALSTATE: {
        draft = initialState;
        break;
      }
      case RESET_SUBSCRIPTIONS_ON_PAYMENT: {
        draft.active_subscriptions = initialState.active_subscriptions;
        draft.assoc_subscriptions = initialState.assoc_subscriptions;
        draft.totalItemsToAssociate = initialState.totalItemsToAssociate;
        draft.dataLoaded = initialState.dataLoaded;
        break;
      }

      case CREATE_PROSPECT._REQUEST: {
        draft.totalItemsToAssociate = initialState.totalItemsToAssociate;
        draft.assoc_subscriptions = initialState.assoc_subscriptions;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default subscriptionsReducer;
