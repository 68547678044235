import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { MODAL_OPEN } from 'redux/actions';
import { Checkbox } from 'ui/atoms';
import AgreementsCheckModal from 'ui/components/Modals/AgreementsCheckModal';
import {
  AGREEMENTS_CREATE,
  CHECK_AGREEMENTS_MODAL
} from 'redux/actions/signup';
import { PROFILE_GET_DATA_PRIVACY } from 'redux/actions/profile';
import {
  PrivacyWrapper,
  Icon,
  Link,
  CheckboxWrapper,
  CheckboxWrapperText
} from './style';

const AgreementCheck = ({
  domain,
  openAgreementsCheckModal,
  agreements,
  validator,
  createAgreements,
  isSimpleUser = false,
  isCompleteManager = false,
  getPrivacyData,
  privacyData,
  isLogged,
  sendDataTreatment,
  checkUserType = false,
  userType,
  checkAgreementsModal,
  agreementsModalChecked
}) => {
  const firstCheck = !isLogged || sendDataTreatment || isCompleteManager;
  const [conditions, setConditions] = useState({});

  useEffect(() => {
    if (!firstCheck) {
      getPrivacyData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formConfirmed = () => {
    let valid = true;
    if (agreements?.user_clauses) {
      (Object.keys(agreements.user_clauses) || []).forEach(key => {
        valid = valid && agreements.user_clauses[key]?.valid;
      });
    } else {
      valid = false;
    }
    return (
      valid &&
      (!checkUserType || !!agreements.userType) &&
      (!isSimpleUser || agreementsModalChecked)
    );
  };

  const setFilteredConditions = conds => {
    if (firstCheck) setConditions(conds);
    else {
      const myPrivacyDataCodes = privacyData.map(pd => pd.type);
      const filteredConds = {
        ...conds,
        consents: conds.consents.filter(c => (
          myPrivacyDataCodes.includes(c.code)
        ))
      };
      setConditions(filteredConds);
    }
  };

  const handleConditionModal = conds => {
    setFilteredConditions(conds);
    if (isSimpleUser) checkAgreementsModal();
    openAgreementsCheckModal();
  };

  const handleCreateAgreements = (keepMandatory = false) => {
    const agreementsPre = {};
    if (domain.policy && !isSimpleUser) {
      domain.policy.consents
        .filter(c => (
          firstCheck
            ? c.user_type === '' || c.user_type === userType
            : (privacyData.map(pd => pd.type) || []).includes(c?.code)
        ))
        .forEach(c => {
          const profilePrivacy = (privacyData || []).find(
            p => p.type === c.code
          );
          agreementsPre[c.id] = {
            authorize: !profilePrivacy?.mandatory
              ? profilePrivacy?.authorized
              : false,
            type: c?.code,
            mandatory: c?.mandatory === 'True',
            valid: !(c?.mandatory === 'True')
          };
        });
    }
    if (domain.contract && !isSimpleUser) {
      domain.contract.consents.forEach(c => {
        const profilePrivacy = (privacyData || []).find(p => p.type === c.code);
        agreementsPre[c.id] = {
          authorize: !profilePrivacy?.mandatory
            ? profilePrivacy?.authorized
            : false,
          type: c?.code,
          mandatory: c?.mandatory === 'True',
          valid: !(c?.mandatory === 'True')
        };
      });
    }
    if (domain.consents && isSimpleUser) {
      domain.consents.consents.forEach(c => {
        agreementsPre[c.id] = {
          authorize: false,
          type: c?.code,
          mandatory: c?.mandatory === 'True',
          valid: !(c?.mandatory === 'True')
        };
      });
    }

    createAgreements(agreementsPre, keepMandatory);
  };

  useEffect(() => {
    if (!Object.keys(agreements).length) {
      const keepMandatory = false;
      handleCreateAgreements(keepMandatory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, createAgreements, isSimpleUser, agreements]);

  useEffect(() => {
    if (userType) handleCreateAgreements(true);
    else handleCreateAgreements(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  const findConditionToCheckOpenModal = () => {
    const nv = Object.keys(agreements.user_clauses || []).find(
      a => !agreements.user_clauses[a].valid
    );
    if (checkUserType && !agreements.userType) {
      setFilteredConditions({ ...domain.policy, isConsents: true });
      openAgreementsCheckModal();
    } else if (nv) {
      const dp = domain.policy.consents.find(c => c.id === `${nv}`);
      const dc = domain.contract.consents.find(c => c.id === `${nv}`);
      if (dp) {
        setFilteredConditions(domain.policy);
      } else if (dc) {
        setFilteredConditions(domain.contract);
      } else {
        setFilteredConditions(domain.consents);
      }

      openAgreementsCheckModal();
    } else if (isSimpleUser && !agreementsModalChecked) {
      setFilteredConditions(domain.consents);
      checkAgreementsModal();
      openAgreementsCheckModal();
    }
  };

  return (
    <Col md={9} sm={12} className={'mt-4'}>
      <ProDisplay type="label">
        Prendi visione dell’informativa: per procedere occorre aprire il
        documento.
      </ProDisplay>
      {!isSimpleUser && (
        <>
          <PrivacyWrapper
            onClick={() => {
              handleConditionModal({ ...domain.policy, isConsents: checkUserType });
            }}
          >
            <ProDisplay type="label">
              <Icon className="icon-note" />{' '}
              <Link>Trattamento dei dati personali</Link>
            </ProDisplay>
          </PrivacyWrapper>
          <PrivacyWrapper
            onClick={() => {
              handleConditionModal(domain.contract);
            }}
          >
            <ProDisplay type="label">
              <Icon className="icon-note" />{' '}
              <Link>Termini e condizioni di utilizzo del servizio</Link>
            </ProDisplay>
          </PrivacyWrapper>
        </>
      )}
      {isSimpleUser && (
        <PrivacyWrapper
          onClick={() => {
            handleConditionModal(domain.consents);
          }}
        >
          <ProDisplay type="label">
            <Icon className="icon-note" />{' '}
            <Link>Trattamento dei dati personali</Link>
          </ProDisplay>
        </PrivacyWrapper>
      )}
      <CheckboxWrapper onClick={findConditionToCheckOpenModal}>
        <Checkbox checked={formConfirmed()} disabled onChange={() => null} />
        <CheckboxWrapperText disabled={!formConfirmed()}>
          <ProDisplay type="label">
            Dichiaro di aver letto attentamente l’informativa disponibile su
            documento elettronico
          </ProDisplay>
        </CheckboxWrapperText>
      </CheckboxWrapper>
      <AgreementsCheckModal
        validator={validator}
        conditions={conditions}
        firstCheck={firstCheck}
      />
    </Col>
  );
};

AgreementCheck.propTypes = {
  validator: PropTypes.func,
  isSimpleUser: PropTypes.bool,
  isCompleteManager: PropTypes.bool,
  checkUserType: PropTypes.bool,
  //HOC (connect, state)
  agreements: PropTypes.object,
  domain: PropTypes.object,
  isLogged: PropTypes.bool,
  sendDataTreatment: PropTypes.bool,
  userType: PropTypes.string,
  privacyData: PropTypes.array,
  agreementsModalChecked: PropTypes.bool,

  //HOC (connect, dispatch)
  openAgreementsCheckModal: PropTypes.func,
  createAgreements: PropTypes.func,
  getPrivacyData: PropTypes.func,
  checkAgreementsModal: PropTypes.func
};

export default connect(
  state => {
    const { agreements, agreementsModalChecked } = state.signup;
    const { isLogged } = state.app.authState;
    const {
      user: { sendDataTreatment }
    } = state.authentication;
    const {
      privacy: { dataPrivacylist: privacyData }
    } = state.profile;
    return {
      agreements,
      domain: state.domain,
      isLogged,
      sendDataTreatment,
      userType: agreements.userType,
      privacyData,
      agreementsModalChecked
    };
  },
  dispatch => ({
    openAgreementsCheckModal: () =>
      dispatch({ type: MODAL_OPEN, id: AgreementsCheckModal.id }),
    createAgreements: (agreements, keepMandatory = false) =>
      dispatch({ type: AGREEMENTS_CREATE, agreements, keepMandatory }),
    getPrivacyData: () => dispatch({ type: PROFILE_GET_DATA_PRIVACY._REQUEST }),
    checkAgreementsModal: () => dispatch({ type: CHECK_AGREEMENTS_MODAL })
  })
)(AgreementCheck);
