import styled from 'styled-components/macro';

export const Head = styled.header`
  height: 90px;
  display: flex;
  align-items: center;
`;

export const IconBack = styled.div`
  width: 35px;
  height: 30px;
  padding-left: 5px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  transform: rotate(180deg);
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  cursor: pointer;
`;

export const BtnContainer = styled.div`
  button {
    font-weight: 600;
  }

  &.show-more {
    //display: none;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  button {
    background: transparent;
    color: ${props => props.theme.colors.primary.base};
    padding: 0;
    margin: 0 auto;

    &:hover {
      background-color: transparent ;
    }
  }
`;

export const Title = styled.h1`
  padding: 10px;

  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const Text = styled.span`
  @media ${props => props.theme.device.mobile} {
    padding-left: 0;
  }
`;

export const SearchItem = styled.div`
    margin: 20px 0;
    border-bottom: 1px solid #ccc;
    display: block;
    cursor: pointer;
    &:hover{
        background-color: #eee;
    }
    .description{
        text-align: left;
    display: block;
        padding: 10px;
    }
`;

export const NewsLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px;
  color: ${props => props.theme.colors.moonstone};
  *{
  font-weight: bold;
  }
`;


export const Index = styled.div`
  width: 8%;
  text-align: center;
`;


export const CheckboxWrapper = styled.div`
    display: flex;
    margin-right: 20px;
    padding-bottom: 20px;
     &.rounded{
        label{
        > span{
           border-radius:50%;
        }}
     }
`;

export const CheckboxesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
     > div{
     padding: 10px 10px 10px 0
     }
`;
export const NoResult = styled.div`
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
    font-size: 30px;

`;


export const Radio = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 32px;
`;


export const BtnWrapper = styled.div`
  width: 140px;
  height: 52px;
`;

export const InputsWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${props => props.theme.device.tablet} {
    width: 100%;
  }
`;


export const SearchLoader = styled.div`
.loader,
.loader:after {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 8px solid #33a7b287;
  border-right: 8px solid #33a7b287;
  border-bottom: 8px solid #33a7b287;
  border-left: 8px solid ${props => props.theme.colors.moonstone};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

`;