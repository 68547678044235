import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import onBoarding from 'api/onBoarding';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  AUTH_LOGIN,
  LOADER_CLOSE,
  LOADER_OPEN,
  MODAL_OPEN
} from 'redux/actions';
import {
  COMPLETE_MANAGER,
  CREATE_USER_ONBOARDING,
  GET_ALL_COL,
  ONBOARDING_GET_NOT_CONFIGURED_ASSETS,
  ONBOARDING_GET_ON_BOARDING,
  ONBOARDING_PUT_ASSETS_ACTIVE,
  ONBOARDING_SELECT_ITEM
} from 'redux/actions/onBoarding';
import routes from 'routes';
import playlist from 'api/playlist';
import { AUTHENTICATION_SIGNIN } from 'redux/actions/authentication';
import { setToken } from 'utils/token';
import { channel } from 'api/config';

import {
  profilePicGet
} from 'redux/sagas/authentication';
import { GET_PATH } from 'redux/actions/playlist';
import { PROFILE_DATA_ACCOUNT_GET } from '../actions/profile';

function* getNotConfiguredAssets() {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      user: { userId }
    } = yield select(state => state.authentication);
    const {
      data: { assets }
    } = yield call(onBoarding.getNotConfiguredAssets, {
      userId
    });
    yield put({
      type: ONBOARDING_GET_NOT_CONFIGURED_ASSETS._SUCCESS,
      payload: assets
    });
  } catch (error) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: error?.message }
    });
    yield put({ type: ONBOARDING_GET_ON_BOARDING._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > onBoarding > getOnBoarding', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNotConfiguredAssetsWatch() {
  yield takeLatest(
    ONBOARDING_GET_NOT_CONFIGURED_ASSETS._REQUEST,
    getNotConfiguredAssets
  );
}

function* getOnBoarding() {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      user: { userId }
    } = yield select(state => state.authentication);
    const {
      data: { needs }
    } = yield call(onBoarding.getOnBoarding, {
      userId
    });
    yield put({ type: ONBOARDING_GET_ON_BOARDING._SUCCESS, payload: needs });
  } catch (error) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: error?.message }
    });
    yield put({ type: ONBOARDING_GET_ON_BOARDING._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > onBoarding > getOnBoarding', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getOnBoardingWatch() {
  yield takeLatest(ONBOARDING_GET_ON_BOARDING._REQUEST, getOnBoarding);
}

function* putAssetsActive({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { status }
    } = yield call(onBoarding.putAssetsActive, payload);

    if (status === 200) {
      yield put({ type: ONBOARDING_PUT_ASSETS_ACTIVE._SUCCESS });
      yield put({ type: GET_PATH._REQUEST, forceCall: true });
      yield put(push(routes.confirmCreateUser.path));
    } else {
      yield put({ type: ONBOARDING_PUT_ASSETS_ACTIVE._ERROR });
    }
  } catch (error) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: error?.message }
    });
    yield put({ type: ONBOARDING_PUT_ASSETS_ACTIVE._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > onBoarding > getOnBoarding', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* putAssetsActiveWatch() {
  yield takeLatest(ONBOARDING_PUT_ASSETS_ACTIVE._REQUEST, putAssetsActive);
}

function* getSelectedNeed({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    if (!payload) {
      yield put({ type: ONBOARDING_SELECT_ITEM._SUCCESS, payload: null });
    } else {
      const { id, locked } = payload;
      const {
        user: { userId }
      } = yield select(state => state.authentication);

      const { data } = yield call(playlist.getPlaylist, { id, userId, locked });
      yield put({
        type: ONBOARDING_SELECT_ITEM._SUCCESS,
        payload: { ...data, ...payload }
      });
    }
  } catch (error) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: error?.message }
    });
    yield put({ type: ONBOARDING_SELECT_ITEM._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > onBoarding > getOnBoarding', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getSelectedNeedWatch() {
  yield takeLatest(ONBOARDING_SELECT_ITEM._REQUEST, getSelectedNeed);
}

function* completeManagerProfile({ payload }) {
  try {
    const { userId } = yield select(state => state.authentication.user);
    const res = yield call(onBoarding.completeManager, { ...payload, userId });
    const {
      data: {
        username,
        givenname,
        familyname,
        email,
        role,
        pictureid,
        organizationid,
        prospectid,
        token,
        accesstoken,
        refreshtoken,
        expiresin,
        clientenabled,
        domainlist,
        cf,
        phonenumber,
        imageurl
      } = {}
    } = res;

    if (token) {
      const { rememberMe } = yield select(state => state.landingPage);
      setToken(
        {
          token,
          accesstoken,
          refreshtoken,
          expiresin,
          userid: username
        },
        !!rememberMe
      );

      yield put({
        type: AUTHENTICATION_SIGNIN._SUCCESS,
        user: {
          userId: username,
          firstname: givenname,
          lastname: familyname,
          email,
          role,
          pictureId: pictureid,
          organizationId: organizationid,
          prospectId: prospectid,
          clientEnabled: clientenabled,
          domainList: domainlist,
          fiscalCode: cf,
          phoneNumber: phonenumber,
          imageurl
        }
      });
      yield put({ type: AUTH_LOGIN._SUCCESS });

      if (!clientenabled) {
        yield put(push(routes.choosePortal.path));
      } else {
        yield put({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST });
      }
    }
    if (pictureid) {
      yield profilePicGet({ pictureId: pictureid });
    }
  } catch (error) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: 'Errore nel salvataggio delle informazioni' }
    });
    yield put({ type: COMPLETE_MANAGER._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > onBoarding > completeManagerProfile', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* completeManagerProfileWatch() {
  yield takeLatest(COMPLETE_MANAGER._REQUEST, completeManagerProfile);
}

function* createUserOnboarding({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    // eslint-disable-next-line no-unused-vars
    const res = yield call(onBoarding.createUser, {
      ...payload,
      channel
    });
    if (res.status === 200) {
      yield put({ type: CREATE_USER_ONBOARDING._SUCCESS });
      /*     
      const { passwordUser } = payload;
      yield put({
        type: AUTHENTICATION_SIGNIN._REQUEST,
        payload: { passwordUser, username: res.data.email, channel }
      }); */

      yield put(push(routes.access.path));
    }
  } catch (err) {
    let error = {};
    switch(err.response?.status) {
      case 400:
        error.errorTitle = 'Accesso non autorizzato.';
        error.errorText =
          'Non sei autorizzato a effettuare l’accesso in piattaforma. Contatta il tuo manager per avere maggiori chiarimenti';
        break;
      case 406:
        error.errorText = 'Account già registrato. Accedere attraverso la pagina di login o reimpostare la password utilizzando la stessa email';
        break;
      default:
        error.errorText = err.message;
    };
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: error,
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > createUser', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* createUserOnboardingWatch() {
  yield takeLatest(CREATE_USER_ONBOARDING._REQUEST, createUserOnboarding);
}

function* getAllCollections() {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      user: { userId }
    } = yield select(state => state.authentication);
    const { status, data } = yield call(onBoarding.getOnBoarding, { userId });
    if (status === 200 || data.resultcode?.toString() === '200') {
      yield put({
        type: GET_ALL_COL._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > getAllCollections', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getAllCollectionsWatch() {
  yield takeLatest(GET_ALL_COL._REQUEST, getAllCollections);
}

export default [
  getOnBoardingWatch(),
  getNotConfiguredAssetsWatch(),
  putAssetsActiveWatch(),
  getSelectedNeedWatch(),
  completeManagerProfileWatch(),
  createUserOnboardingWatch(),
  getAllCollectionsWatch()
];
