import routes from 'routes';

const navbarMenu = [
  {
    label: 'Il mio piano',
    link: routes.mySubscription.path
  },
  {
    label: 'Profilo',
    link: routes.managerProfile.path
  },
  {
    label: 'Segui il team',
    link: routes.teamsActivity.path
  }
];

export default navbarMenu;
