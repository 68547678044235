import produce from 'immer';
import {
  GET_FIVE_MINUTES,
  GET_NEWS,
  SET_COURSE_FIVE_MINUTE
} from 'redux/actions/fiveMinutes';
import { UPDATE_SOCIAL } from 'redux/actions/social';
import { detailType } from 'utils/common';

const initialState = {
  course: {},
  fiveMinutes: [],
  news: {}
};

const fiveMinutesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_FIVE_MINUTES._SUCCESS: {
        const {
          news,
          // relatedlo
        } = action.data;
        const newFiveMinutes = [
          ...(news || []).map(n => ({ ...n, type: detailType.NEWS })),
          // ...(relatedlo || []).map(lo => ({ ...lo, type: detailType.RELATED_COURSE })),
        ]
        // .sort((a, b) => a.creationDate < b.creationDate ? 1 : -1)
        draft.fiveMinutes = newFiveMinutes;
        break;
      }
      case GET_NEWS._SUCCESS: {
        draft.news = action.data;
        break;
      }
      case UPDATE_SOCIAL: {
        if (state.news?.itemid === action.itemId)
          draft.news = { ...state.news, ...action.data };
        if (state.course?.itemid === action.itemId)
          draft.course = { ...state.course, ...action.data };
        break;
      }
      case SET_COURSE_FIVE_MINUTE: {
        draft.course = action.data;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default fiveMinutesReducer;
