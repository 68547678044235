import produce from 'immer';
import { 
  GET_ALL_NEWS_FEED, 
  GET_COMMENTS_BY_NEWS_FEED, 
  RESET_COMMENTS_NEWS_FEED, 
  GET_NEWS_FEED_USERLIKE, 
  ADD_COMMENT_TO_NEWS_FEED,
  UPDATE_NEWS_FEED_COMMENT,
  DELETE_NEWS_FEED, 
  DELETE_NEWS_FEED_COMMENT
} from 'redux/actions/allNewsFeed';

const initialState = {
    feeds: {
      newsfeeds: [],
      nextPage: null,
      previousPage: null
    },
    recentComments: [],
    comments: [],
    myLikesCounter: [],
    myComments: []
};

const allNewsFeedReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ALL_NEWS_FEED._SUCCESS: {
        const { newsfeeds, nextPage } = action;
        const updateNewsFeeds = Boolean(state.feeds.newsfeeds) ? [...state.feeds.newsfeeds, ...newsfeeds] : newsfeeds;
        const recentComments = updateNewsFeeds.map(feed => feed.recentComments).flat();

        draft.recentComments = recentComments.filter(item => item);
        draft.feeds = {newsfeeds: updateNewsFeeds, nextPage: !!nextPage ? nextPage : null, previousPage: state.feeds.nextPage};
        break;
      }

      case GET_COMMENTS_BY_NEWS_FEED._SUCCESS: {
        const { comments } = action;
        const updateComments = [...state.comments, comments];
        draft.comments = updateComments.flat();
        break;
      }

      case RESET_COMMENTS_NEWS_FEED: {
        return initialState;
      }

      case GET_NEWS_FEED_USERLIKE._SUCCESS: {
        const { likeStatus, newsfeedId} = action;
        const myLikesCounter = [{likeStatus, newsfeedId}];
        
        if (state.myLikesCounter.find(likeItem => likeItem.newsfeedId === newsfeedId)) {
          const filteredlikesCounterState = state.myLikesCounter.filter(likeItem => likeItem.newsfeedId !== newsfeedId);
          const likesCounter = !!filteredlikesCounterState.length ? [...filteredlikesCounterState, myLikesCounter] : myLikesCounter;
          draft.myLikesCounter = likesCounter.flat();
        } else {
          const likesCounter = !!state.myLikesCounter.length ? [...state.myLikesCounter, myLikesCounter] : myLikesCounter;
          draft.myLikesCounter = likesCounter.flat();
        }
        break;
      }

      case ADD_COMMENT_TO_NEWS_FEED._SUCCESS: {
        const {comment} = action;
        const myComments = [...state.myComments, comment];
        draft.myComments = myComments;
        break;
      }

      case UPDATE_NEWS_FEED_COMMENT._SUCCESS: {
        const { updateComment } = action;
        const myUpdateComments = [...state.myComments.filter(comment => comment.commentId !== updateComment.commentId), updateComment];

        if (state.comments.find(comment => comment.commentId === updateComment.commentId)) {
          const updateComments = [...state.comments.filter(comment => comment.commentId !== updateComment.commentId), updateComment];
          draft.comments = updateComments;
        }

        if (state.recentComments.find(comment => comment.commentId === updateComment.commentId)) {
          const updateRecentComments = [...state.recentComments.filter(comment => comment.commentId !== updateComment.commentId), updateComment];
          draft.recentComments = updateRecentComments;
        }

        draft.myComments = myUpdateComments;
        break;
      }

      case DELETE_NEWS_FEED._SUCCESS: {
        const { newsfeedId } = action;

        draft.recentComments = state.recentComments.filter(recentComment => recentComment.newsfeedId !== newsfeedId);
        draft.comments = state.comments.filter(comment => comment.newsfeedId !== newsfeedId);;
        draft.myComments = state.myComments.filter(myComment => myComment.newsfeedId !== newsfeedId);;
        draft.feeds.newsfeeds = state.feeds.newsfeeds.filter(feed => feed.newsfeedId !== newsfeedId);
        break;
      }

      case DELETE_NEWS_FEED_COMMENT._SUCCESS: {
        const { commentId } = action;

        draft.recentComments = state.recentComments.filter(recentComment => recentComment.commentId !== commentId);
        draft.myComments = state.myComments.filter(mycomment => mycomment.commentId !== commentId);
        draft.comments = state.comments.filter(comment => comment.commentId !== commentId);
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default allNewsFeedReducer;