import produce from 'immer';
import { LOCATION_CHANGE } from 'redux-first-history';

import {
  LOADER_OPEN,
  LOADER_CLOSE,
  APP_MENU_TOGGLE,
  AUTH_LOGIN,
  SET_TIMESTAMP,
  HIDE_NAVBAR,
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE
} from 'redux/actions';
import routes from 'routes';
import { AUTHENTICATION_SIGNOUT } from 'redux/actions/authentication';
import { isLocalHost } from 'api/config';
import { GO_TO_EDIT_SUBSCRIPTION } from 'redux/actions/subscriptions';

const initialState = {
  loader: {
    instances: 0,
    isSpinning: false
  },
  authState: {
    // TODO change true into your preferred valure in order to test & debug locally
    // eslint-disable-next-line
    isLogged: isLocalHost ? false : false,
    error: null
  },
  menuToggleOpen: false,
  showNavBar: true,
  notificationToggle: false,
  sidebarToggle: false,
  timestamp: 0,
  editSubscriptions: false,
  mode: null
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        const { payload: { pathname = '' } = {} } = action;
        draft.showNavBar = initialState.showNavBar;

        (Object.keys(routes) || []).forEach(route => {
          if (
            routes[route].showNavbar === false &&
            routes[route].path === pathname
          ) {
            draft.showNavBar = false;
          }
        });

        if (state.menuToggleOpen) {
          draft.menuToggleOpen = !state.menuToggleOpen;
        }

        if (state.notificationToggle) {
          draft.notificationToggle = !state.notificationToggle;
        }

        break;
      }

      case LOADER_OPEN: {
        const i = state.loader.instances > 0 ? state.loader.instances + 1 : 1;
        draft.loader.instances = i;
        draft.loader.isSpinning = i > 0;

        break;
      }

      case LOADER_CLOSE: {
        const i = state.loader.instances >= 1 ? state.loader.instances - 1 : 0;
        draft.loader.instances = i;
        draft.loader.isSpinning = i > 0;

        break;
      }

      case AUTH_LOGIN._SUCCESS: {
        draft.authState.isLogged = true;
        draft.authState.error = null;
        break;
      }

      case AUTHENTICATION_SIGNOUT._SUCCESS: {
        draft.authState.isLogged = false;
        draft.authState.error = null;
        break;
      }

      case AUTH_LOGIN._ERROR: {
        draft.authState.isLogged = initialState.authState.isLogged;
        draft.authState.error = initialState.authState.error;
        break;
      }

      case APP_MENU_TOGGLE:
        draft.menuToggleOpen = !state.menuToggleOpen;
        break;

      case SET_TIMESTAMP:
        draft.timestamp = action.timestamp;
        break;

      case GO_TO_EDIT_SUBSCRIPTION:
        draft.editSubscriptions = false; //should be true
        break;
      case HIDE_NAVBAR:
        draft.showNavBar = false;
        break;
      case SIDEBAR_OPEN: {
        draft.notificationToggle = false;
        draft.sidebarToggle = true;
        break;
      }
      case SIDEBAR_CLOSE: {
        draft.sidebarToggle = false;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default appReducer;
