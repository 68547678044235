import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { getToken } from 'utils/token';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import MainContainer from '../style';
import { Footer } from 'ui/components';
import { Head, IconBack, Title, Text, SearchItem, Index, NewsLabel, CheckboxWrapper, BtnWrapper, CheckboxesWrapper, InputsWrapper, SearchLoader, NoResult, BtnContainer} from './style';
import ProDisplay from 'ui/typography/proDisplay';
import { useLocation, useHistory } from 'react-router';
import ProText from 'ui/typography/proText';
import { push } from 'redux-first-history';
import routes from 'routes';
import TYPES from 'ui/typography/pro.types';
import { Checkbox } from 'ui/atoms';
import { CustomInput, CustomSelect, Button } from 'ui/atoms';
import { detailType } from 'utils/common';
import { SET_PLAYLIST_HISTORY} from 'redux/actions/playlist';
import { apiHost } from 'api/config.js';
import { unescape } from 'utils/common.js';

const Search = ({pushUrl, myUserId, id, categories, setPlaylistHistory, router}) => {

  const [data, setData] = useState([]);
  const [isNews, setIsNews] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextPageToken, setNextPageToken] = useState('');
  const [categoriesSelected, setCategoriesSelected] = useState(null);
  const [oneCategorySelected, setOneCategorySelected] = useState(false);
  const searchLocation = useLocation().search
  const searchParams = new URLSearchParams(searchLocation)
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchNoResult, setSearchNoResult] = useState(false);
  const [search, setSearch] = useState(searchLocation.split("=")[1]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    submitSearch();
  }, []);

    function submitSearch(reset) {
      const xhr = new XMLHttpRequest();
      let url = `${apiHost}/search/api/v1/public/catalog?userId=` + myUserId + '&search=' + search + '&items_per_page=15';
      if(isNews){
        url += '&news=true';
      }
      if(categoriesSelected){
        url += '&need_id=' + categoriesSelected.id;
      }
      if(Boolean(nextPageToken)) {
        url += '&pageToken=' + nextPageToken;
      }
      //xhr.open('GET', 'http://localhost:3001/search');
      //console.log(url);
      xhr.open('GET', url);
      xhr.setRequestHeader('Authorization', getToken().token);
      setSearchLoading(true);
      setSearchNoResult(false);

      if(reset) {
        setData([]);
      }

     xhr.setRequestHeader('Domain', id);
      xhr.onload = function() {
      setSearchLoading(false);
        if (xhr.status === 200) {
          const results = JSON.parse(xhr.responseText);
          let updateResults = Boolean(data) && !reset ? [...data, ...results.catalog] : results.catalog;

          setData(updateResults);
          setHasNextPage(results.pageInfo.hasNextPage);
          setNextPageToken(results.pageInfo.nextPageToken);
          if(results.catalog.length === 0 ){
          setSearchNoResult(true)
          }
        }
      };
      xhr.send();
    }

    const handleClickSearch = ( item, type) => {

        setPlaylistHistory(router.location.pathname + router.location.search);
        switch(type?.toLowerCase()) {
          case 'news':
            pushUrl(`${routes.detail.path}/${detailType.NEWS}/${item.itemId}`);
            break;
          case 'training':
          case 'lo':
          case 'learning_object':
            pushUrl(`${routes.detail.path}/${detailType.COURSE}/${item.itemId}`);
            break;
          case 'collection':
          case 'selection': {
            const productId= item.productId;
            const itemId = item.id ? item.id : item.itemId;
            if (item.locked) {
              pushUrl(`${routes.lockedDetails.path}/${itemId}${!!productId ? '/' + productId : ''}`);
            } else  {
                pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${itemId}`);
            }
            break;
          }
          case 'live_event': {
              const itemId = item.id ? item.id : item.itemId;
              const productId= item.productItemId;
              if (item.locked) {
                pushUrl(`${routes.eventLockedDetail.path}/${itemId}/${productId}`)
              } else {
                pushUrl(`${routes.event.path}/${itemId}`);
              }
              break;
            }
          default:
        }
      };
    const getItemType = ( type) => {
        switch(type?.toLowerCase()) {
          case 'news':
            return 'NEWS';
            break;
          case 'training':
            return 'TRAINING';
            break;
          case 'lo':
          case 'learning_object':
            return 'LEARNING OBJECT';
            break;
          case 'collection':
          case 'selection': {
            return 'PLAYLIST';
            break;
          }
          case 'live_event':
            return 'EVENTO LIVE';
            break;
          default:
        }
      };



  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <IconBack
              className="icon-arrow-right"
              onClick={() => pushUrl(routes.homepage.path)}
            />
              <Title>
                <ProDisplay type="pageTitle">
                   Ricerca
                </ProDisplay>
                <Text>
                <ProDisplay
                  type="tableLabel"
                  configuration={{ color: 'placeholder' }}>
                  I risultati di ricerca
                </ProDisplay>
              </Text>
              </Title>
          </Head>

            <InputsWrapper>
            <CustomInput
              id="search-input"
              type="text"
              placeholder="Chiave di ricerca"
              onChange={val => {
              setSearch(val);
              history.replace({ pathname: location.pathname, search: 'string=' + val})}}
              value={search}
            />
            </InputsWrapper>
            <ProDisplay
              type="pageTitle">
              Includi:
            </ProDisplay>
             <CheckboxWrapper
              onClick={() => setIsNews(!isNews)}
             >
                 <Checkbox
                  checked={isNews}
                  label={'News'}
                  disabled
                  onChange={()=>{}}
                />
            </CheckboxWrapper>

            <ProDisplay type="pageTitle">
               Filtra per:
            </ProDisplay>
            <CheckboxesWrapper>
            {categories &&
              categories.map(cat => (
                 <CheckboxWrapper
                 className="rounded"
                    key={cat.id}
                 onClick={() => {
                 }}
                 >
                 <Checkbox
                  checked={categoriesSelected && categoriesSelected.id === cat.id}
                  label={unescape(cat.title)}
                  onChange={(id)=>{
                   if(categoriesSelected && categoriesSelected.id === cat.id){
                       setCategoriesSelected(null);
                   } else {
                       setCategoriesSelected(cat);
                   }
                  }}
                />
                </CheckboxWrapper>
              ))}
              </CheckboxesWrapper>

            <BtnWrapper>
              <Button
                type="PRIMARY"
                text="Cerca"
                height={35}
                onClick={() => {
                    push({string: search})
                    submitSearch(true);
                }}
              />
            </BtnWrapper>         
            {data &&
              data.map(item => (
                <SearchItem
                    key={item.itemId}
                  onClick={() => handleClickSearch(item, item.itemType)}
              >
                <NewsLabel>
                    <ProText
                      type={TYPES.TEXT.NEWS_CARD_LABEL}
                    >
                        {getItemType(item.itemType)}
                    </ProText>
                </NewsLabel>
                <Title type="title">
                    <ProDisplay type="pageTitle">
                       {item.title}
                    </ProDisplay>
                </Title>
              </SearchItem>
              ))}
              {(search && searchNoResult ) ?
                  <NoResult className="noResult">
                    <ProText configuration={{fontSize: 24}} type="link">Nessun risultato trovato</ProText>
                    </NoResult>
             : null}
            {searchLoading &&
              <SearchLoader>
              <div className="loader">Loading...</div>
              </SearchLoader>}
             {hasNextPage && !searchLoading && <BtnContainer className="show-more">
              <Button
                text="Mostra altri"
                type="PRIMARY"
                height={35}
                fontSize={16}
                onClick = {() => submitSearch()}
              />
            </BtnContainer>}
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

Search.propTypes = {
  pushUrl: PropTypes.func,
  setPlaylistHistory: PropTypes.func,
  router: PropTypes.object,
};

export default connect(
  state => {
    const {
      user: { userId: myUserId }
    } = state.authentication;
    const { id } = state.domain;
    const { categories } = state.playlist;
    const { router } = state;

    return {
      myUserId,
      id,
      categories,
      router
    };
  },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),

    })
)(withMediaQueries(Search));