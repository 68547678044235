import React, { useState } from 'react';
import { getStatusLabelByStatus } from 'redux/reducers/subscriptions';

import { withMediaQueries } from 'hoc/withMediaQueries';
// import { SOURCETYPE } from 'utils/common';

import { Button, CustomInput } from 'ui/atoms';
import { Row, Col } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import {
  TableCardContainer,
  // Icon,
  Text,
  // Delete,
  ButtonWrapperEdit,
  IconAccordion,
  WrapperIcon,
  AccordionWrapper,
  IconEdit,
  StatusLabel,
} from './style';

const TableCardDesktop = ({
  parentKey,

  // state
  changeEmail,
  countBasicPkgs,
  countPremiumPkgs,
  email,
  newEmail,
  emailError,
  offerts,

  // actions
  checkActives,
  handleOnBlur,
  handleOnChangeEmail,
  handleUpdateEmailProspect,
  setChangeEmail,
  
  // HOC, state
  // productNames,
  subscriptionOnEdit,

  // HOC, dispatch
  // openDisabledSubscriptionModal,
  // openConfirmModal,
  setSubscriptionOnEdit,

  // HOC, media queries
  // currentViewport,
  // viewportTypes,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleAccordion = () => {
    setAccordionOpen(prev => !prev);
    if (subscriptionOnEdit) {
      setSubscriptionOnEdit(null);
    }
  };

  return (
    <AccordionWrapper activeTab>
      <TableCardContainer activeTab onClick={handleAccordion}>
        <Row>
        <Col md={3}>
          <Text>
            <ProDisplay>{email}</ProDisplay>
          </Text>
          </Col>
          {/* <Col lg={5} md={5}>
            <Text>
              <ProDisplay>
                {`${ordered(getAllTypes(offerts)).substr(0, 35)}${
                  getAllTypes(offerts).length > 35 ? '...' : ''
                }`}
              </ProDisplay>
            </Text>
          </Col> */}
          <Col md={3}>
            <Text  className='redText'>
              <ProDisplay configuration={{ fontSize: 30 }}>
                {countBasicPkgs > 0 ? countBasicPkgs : '-'}
              </ProDisplay>
            </Text>
          </Col>
          <Col md={3}>
            <Text className='redText'>
              <ProDisplay configuration={{ fontSize: 30 }}>
                {countPremiumPkgs > 0 ? countPremiumPkgs : '-'}
              </ProDisplay>
            </Text>
          </Col>
          <Col md={3}>
            <WrapperIcon>
              <IconAccordion className="icon-btn-arrow" grayBg open={accordionOpen} />
            </WrapperIcon>
          </Col>
        </Row>
      </TableCardContainer>

      {accordionOpen &&
        offerts.map(off => {

          // const is_active = ['active'].includes(off.status?.toLowerCase());
          // const will_be_deleted = ['inactiveready'].includes(off.status?.toLowerCase());
          // const is_inactive = ['inactive'].includes(off.status?.toLowerCase());
          const is_deleted = ['inactive', 'inactiveready'].includes(off.status?.toLowerCase());

          // const allowEdit = off.sourceType !== SOURCETYPE.BANK;

          // const onEdit =
          //   subscriptionOnEdit?.variationId === off.variationId &&
          //   subscriptionOnEdit?.email === email;

          return (
            <TableCardContainer
              grayBg
              key={`${parentKey}-${off.variationId}`}
              className={`${is_deleted ? 'deleted' : ''}`}
            >
              <Row>
              <Col md={3}>
                {(off.prospectId && changeEmail[off.prospectId]) ? (
                  <CustomInput
                    type="text"
                    autocomplete="new-password"
                    placeholder="Email"
                    value={newEmail}
                    onChange={val => handleOnChangeEmail(val)}
                    onBlur={() => handleOnBlur(off)}
                    error={emailError}
                    noMargin={true}
                  />
                ) : null}
                </Col>
                <Col md={3}>
                  {off.packageType === 'base' ?
                    <Text>
                      <ProDisplay
                        type="label"
                        configuration={{ lineHeight: 1.57 }}
                      >
                        {off.title}
                      </ProDisplay>
                    </Text>
                    : ''}
                </Col>
                <Col md={2}>
                  {off.packageType === 'premium' ? (
                    <Text>
                      <ProDisplay
                        type="label"
                        configuration={{ lineHeight: 1.57 }}
                      >
                        {off.title}
                      </ProDisplay>
                    </Text>
                  ) : null}
                </Col>
                {checkActives(off) ? (
                  <>
                    <Col md={2} />
                    <Col md={2}>
                    <StatusLabel className={off.status.toLowerCase()}>
                      <ProDisplay type="label">
                        {getStatusLabelByStatus(off.status.toLowerCase())}
                      </ProDisplay>
                    </StatusLabel>
                  </Col>
                  </>
                ) : (
                  (off.prospectId && changeEmail[off.prospectId]) ? (
                    <Col md={4}>
                      <ButtonWrapperEdit>
                        <Button
                          type="PRIMARY"
                          text="Conferma"
                          height={35}
                          fontSize={16}
                          onClick={() => {
                            handleUpdateEmailProspect(off.prospectId)
                            setChangeEmail(prev => {
                              prev[off.prospectId] = false;
                              return { ...prev };
                            })
                          }}
                          disabled={!!emailError}
                        />
                        <Button
                          type="SECONDARY"
                          text="Annulla"
                          height={35}
                          fontSize={16}
                          onClick={() => setChangeEmail(prev => {
                            prev[off.prospectId] = false;
                            return { ...prev };
                          })}
                          disabled={!!emailError}
                        />
                      </ButtonWrapperEdit>
                    </Col>
                  ) : (
                    <>
                      <Col md={1} />
                      <Col md={1}>
                        <WrapperIcon>
                          <IconEdit
                            className="icon-modifica"
                            onClick={() => {
                              setChangeEmail(prev => {
                                prev[off.prospectId] = true;
                                return { ...prev };
                              })
                            }}
                          />
                        </WrapperIcon>
                      </Col>
                      <Col md={2}>
                        <StatusLabel className={off.status.toLowerCase()}>
                          <ProDisplay type="label">
                            {getStatusLabelByStatus(off.status.toLowerCase())}
                          </ProDisplay>
                        </StatusLabel>
                      </Col>
                    </>
                  )
                )}
                {/* <Col md={3}>
                  {allowEdit && onEdit && (is_active || will_be_deleted) && (
                    <Text>
                      {!will_be_deleted && (
                        <Delete
                          onClick={() => {
                            openConfirmModal({
                              isFingerLearning: off.chargeType.toLowerCase() !== 'recurring',
                            });
                          }}
                        >
                          <ProDisplay
                            type="deleteBtn"
                            configuration={
                              viewportTypes[currentViewport] < viewportTypes.mediaIsDesktop
                                ? { fontSize: '10', lineHeight: 1.07 }
                                : null
                            }
                          >
                            {off.chargeType.toLowerCase() === 'recurring'
                              ? 'Elimina abbonamento'
                              : 'Elimina pacchetto'}
                          </ProDisplay>
                        </Delete>
                      )}
                      {off.chargeType.toLowerCase() === 'recurring' && off.renewal && (
                        <Delete
                          onClick={() => {
                            openDisabledSubscriptionModal(off);
                          }}
                        >
                          <ProDisplay
                            type="deleteBtn"
                            configuration={
                              viewportTypes[currentViewport] < viewportTypes.mediaIsDesktop
                                ? { fontSize: '10', lineHeight: 1.07 }
                                : null
                            }
                          >
                            Disabilita rinnovo
                          </ProDisplay>
                        </Delete>
                      )}
                    </Text>
                  )}
                  {allowEdit && !onEdit && is_active ? (
                    <Icon
                      className="icon-modifica"
                      onClick={() => handleEditSubscription({ email, ...off })}
                    />
                  )  : null}
                </Col> */}
              </Row>
            </TableCardContainer>
          );
        })}
    </AccordionWrapper>
  );
};

export default withMediaQueries(TableCardDesktop);
