import PropTypes from 'prop-types';
import { CustomInput } from 'ui/atoms';
import { MODAL_OPEN } from 'redux/actions';
import ShareModal from "ui/components/Modals/ShareModal";
import {connect} from 'react-redux';
import React, {useState} from 'react';

import { Btn, ShareIcon, ShareIconWrapper, BtnWrapper, SubmitShare, SubmitShareWrapper, CustomInputWrapper } from './style';

const Share = ({
  text,
  openModal,
  eventId,
  eventType,
  eventTitle,
  eventDescription,
  eventImage
}) => {

  const [commentToShare, setCommentToShare] = useState('');

  const shareRequest = {
    itemId: eventId,
    objectType: eventType?.toLowerCase(),
    sharedMessage: commentToShare,
    title: eventTitle,
    description: eventDescription,
    imageUrl: eventImage
  }

  function handleClick() {
    const shareButton = document.querySelector('.share-button');
    const btn = shareButton.querySelector('button');
    const btnSubmit = shareButton.querySelector('.share-submit-wrapper');
    btnSubmit.classList.toggle('visible');
    btn.classList.toggle('open');
  }

  function setComment(value) {
    setCommentToShare(value)
  }

  return (
		<>
    <BtnWrapper className='share-button'>
      <Btn>
        <ShareIconWrapper className='share-icon-wrapper'         
          onClick={() => {
            handleClick()
          }}>
          <ShareIcon className='share-icon'>
        </ShareIcon>
        </ShareIconWrapper>
        <CustomInputWrapper className='share-text'>
          <CustomInput
            placeholderFocusNone={true}
            id="share-input"
            type="text"
            placeholder={text}
            onChange={value => setComment(value)}
          />
        </CustomInputWrapper>
      </Btn>
      <SubmitShareWrapper className="share-submit-wrapper" onClick={()=> openModal(ShareModal.id)}>
        <SubmitShare className="share-submit"></SubmitShare>
      </SubmitShareWrapper>
    </BtnWrapper>
    <ShareModal shareRequest={shareRequest}></ShareModal>
  </>
);
}

Share.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  openModal: PropTypes.func,
};

export default 
	connect(
		state => {
			return state
		},
		dispatch => ({
			openModal: id => dispatch({ type: MODAL_OPEN, id }),
		})
)(Share);
