import { createAsyncActionType } from './index';

export const SIGNUP_FIRST_STEP = createAsyncActionType(
  'signup',
  'SIGNUP_FIRST_STEP'
);
export const SIGNUP_NEXT_STEP = createAsyncActionType(
  'signup',
  'SIGNUP_NEXT_STEP'
);
export const SIGNUP_CREATE_CART = createAsyncActionType(
  'signup',
  'SIGNUP_CREATE_CART'
);
export const SIGNUP_UPDATE_CART = '@@signup/SIGNUP_UPDATE_CART';
export const SIGNUP_GET_LATEST_CART = createAsyncActionType(
  'signup',
  'SIGNUP_GET_LATEST_CART'
);
export const SIGNUP_SET_MANAGER_INFO = createAsyncActionType(
  'signup',
  'SIGNUP_SET_MANAGER_INFO'
);
export const SIGNUP_SET_MANAGER_PAYMENT_INFO = createAsyncActionType(
  'signup',
  'SIGNUP_SET_MANAGER_PAYMENT_INFO'
);

export const SIGNUP_ORDER_PREVIEW_ORDER_ZUORA = createAsyncActionType('signup', 'SIGNUP_ORDER_PREVIEW_ORDER_ZUORA');
export const SIGNUP_ORDER_DRAFT_SUPER_MANAGER = createAsyncActionType('signup', 'SIGNUP_ORDER_DRAFT_SUPER_MANAGER');
export const SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA = createAsyncActionType('signup', 'SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA');
export const SIGNUP_SET_DRAFT_ORDER_ZUORA = '@@signup/SIGNUP_SET_DRAFT_ORDER_ZUORA'
export const SIGNUP_SUBMIT_ORDER_ZUORA = createAsyncActionType('signup', 'SIGNUP_SUBMIT_ORDER_ZUORA');

export const SIGNUP_UPDATE_PRODUCTS_CART =
  '@@signup/SIGNUP_UPDATE_PRODUCTS_CART';
export const NEW_USER_UPDATE_PRODUCTS_CART =
  '@@signup/NEW_USER_UPDATE_PRODUCTS_CART';
export const SIGNUP_UPDATE_ACCOUNT = '@@signup/SIGNUP_UPDATE_ACCOUNT';
export const SIGNUP_EMAIL_SET = '@@signup/SIGNUP_EMAIL_SET';
export const SIGNUP_UPDATE_BILLING = '@@signup/SIGNUP_UPDATE_BILLING';
export const AGREEMENTS_CREATE = '@@signup/AGREEMENTS_CREATE';
export const AGREEMENTS_UPDATE = '@@signup/AGREEMENTS_UPDATE';
export const USERTYPE_UPDATE = '@@signup/USERTYPE_UPDATE';
export const CHECK_AGREEMENTS_MODAL = '@@signup/CHECK_AGREEMENTS_MODAL';

export const SIGNUP_PRODUCT_DETAILS = '@@signup/SIGNUP_PRODUCT_DETAILS';
export const SIGNUP_PRODUCTS = '@@signup/SIGNUP_PRODUCTS';
export const SIGNUP_SET_HEADER_VISIBLE = '@@signup/SIGNUP_SET_HEADER_VISIBLE';

export const SIGNUP_DISCOUNT = '@@signup/SIGNUP_SET_DISCOUNT';

export const SET_NEED_TO_REOPEN_AGREEMENTS =
  '@@signup/SET_NEED_TO_REOPEN_AGREEMENTS';
export const AGREEMENTS_GET = createAsyncActionType('signup', 'AGREEMENTS_GET');

export const SECTOR_GET = createAsyncActionType('signup', 'SECTOR_GET');

export const SIGNUP_SET_DISCOUNT = createAsyncActionType(
  'signup',
  'SIGNUP_SET_DISCOUNT'
);

export const SET_COMPATIBILITY = '@@signup/SET_COMPATIBILITY';

export const REMOVE_PROMO = createAsyncActionType('signup', 'REMOVE_PROMO');

export const FLOW_TYPE_SET = '@@signup/FLOW_TYPE_SET';

export const FIRST_PASSWORD_SET = '@@signup/FIRST_PASSWORD_SET';

export const SIGNUP_CONFIRM_USER = createAsyncActionType(
  'signup',
  'SIGNUP_CONFIRM_USER'
);

export const SIGNUP_RESEND_CODE = createAsyncActionType(
  'signup',
  'SIGNUP_RESEND_CODE'
);

export const SIGNUP_SET_OTPCODE = '@@signup/SIGNUP_SET_OTPCODE';

export const SIGNUP_STEP_RESET = '@@signup/SIGNUP_STEP_RESET';

export const SIGNUP_SET_PAYMENT_METHOD_ID =
  '@@signup/SIGNUP_SET_PAYMENT_METHOD_ID';

export const SIGNUP_CART_CONFIRM = createAsyncActionType(
  'signup',
  'SIGNUP_CART_CONFIRM'
);
export const SIGNUP_SET_SUPER_MANAGER_INFO = createAsyncActionType(
  'signup',
  'SIGNUP_SET_SUPER_MANAGER_INFO'
);

export const SIGNUP_RESET_CART = '@@signup/SIGNUP_RESET_CART';
