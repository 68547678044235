import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SubscriptionsInterface } from 'interface/subscriptions';
import {
  Content,
  Labels,
} from 'pages/MySubscriptions/sections/Subscriptions/ActiveSubscriptions/style';
import { Col, Row } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { connect } from 'react-redux';
import EmptyCard from './EmptyCard';

export const mappingVariations = variat => ({
  label: variat.productName || variat.subscriptionNumber,
  value: variat.variationId,
  packageType: variat.packageType,
  productId: variat.productId
});

export const removeDuplicates = (array, key) => [...new Map(array.map(x => [key(x), x])).values()];

const NewSubscriptions = ({
  handleOnChangeEmail,
  getFilteredArray,
  variations,
  subscriptions,
  calendars,
  handleOnChangeVariation,
  handleSelectCalendar,
  all_prospect,
  assoc_subscriptions,
}) => {
  const [options, setOptions] = useState([]);
  const [parsedProspects, setParsedProspects] = useState([]);

  useEffect(() => {
    setParsedProspects(
      Object.values(all_prospect || {})
        .reduce((arr, val) => [...arr, ...(val || [])], [])
        .map(pr => ({
          ...pr,
          offerts: pr.offerts.map(off => {
            const sub = assoc_subscriptions?.orders.find(
              s => s.variationId.toString() === off.variationId.toString()
            );
            return {
              variationId: sub?.variationId || '',
              status: off.status,
            };
          }),
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_prospect, assoc_subscriptions]);

  useEffect(() => {
    const newOptions = variations.map(mappingVariations);
    setOptions(removeDuplicates(newOptions, it => it.value));
  }, [variations]);

  return (
    <Content>
      <Labels>
        <Row>
          <Col lg={4} md={4}>
            <ProDisplay type="tableLabel">Utenti</ProDisplay>
          </Col>
          <Col lg={4} md={4} className="d-none d-md-block">
            <ProDisplay type="tableLabel">Pacchetti</ProDisplay>
          </Col>
          <Col lg={4} md={4} className="d-none d-md-block">
            <ProDisplay type="tableLabel">Calendario eventi live</ProDisplay>
          </Col>
        </Row>
      </Labels>
      {getFilteredArray(subscriptions || []).map((item, index) => (
        <EmptyCard
          key={`subscription-${item.subscriptionId}${index}`}
          allSubscriptions={subscriptions}
          subscription={item}
          email={item.email}
          handleOnChangeVariation={handleOnChangeVariation}
          handleOnChangeEmail={handleOnChangeEmail}
          handleSelectCalendar={handleSelectCalendar}
          options={options}
          calendars={calendars}
          parsedProspects={parsedProspects}
        />
      ))}
      {/* {assoc_subscriptions?.nextpagepresent && (
        <LoadMore>
          <ProDisplay type="deleted" configuration={{ fontWeight: 600, fontSize: 16 }} onClick={() => getAssocSubscriptions(true)}>Mostra altro</ProDisplay>
        </LoadMore>
      )} */}
    </Content>
  );
};

NewSubscriptions.propTypes = {
  subscriptions: SubscriptionsInterface,
  handleOnChangeEmail: PropTypes.func,

  getFilteredArray: PropTypes.func,
  handleOnChangeVariation: PropTypes.func,

  variations: PropTypes.array,

  //HOC state
  all_prospect: PropTypes.object,
  assoc_subscriptions: PropTypes.object,
};

export default connect(
  state => ({
    all_prospect: state.subscriptions.all_prospect,
    assoc_subscriptions: state.subscriptions.assoc_subscriptions,
  })
)(NewSubscriptions);
