import {
    shouldUseMock,
    getAllNewsFeedMock,
    getCommentsByNewsFeedMock,
    getNewsfeedUserlikeMock,
    getNewsfeedUserdislikeMock,
    addCommentsToNewsFeedMock,
    updateNewsFeedCommentMock,
    deleteNewsFeedMock,
    deleteNewsFeedCommentMock
  } from './mock';
  import {
    apiCall,
    baseUrl,
    extendUrl,
    newsFeedPrefix
  } from './config';
  
  export default {
    getAllNewsFeed: ({ userId, itemsPerPage, nextPage }) => {
      if (shouldUseMock()) {
        return getAllNewsFeedMock();
      }
      
      let paginationParams = '';
      paginationParams += itemsPerPage ? `&items=${itemsPerPage}` : '';
      paginationParams += nextPage ? `&next_page=${nextPage}` : '';

      return apiCall(
        `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeeds?${paginationParams}`,
        'GET'
      );
    },

    getCommentsByNewsFeed: ({ userId, newsfeedId, itemsPerPage, backOfficeId }) => {
      if (shouldUseMock()) {
        return getCommentsByNewsFeedMock(newsfeedId);
      }
      return apiCall(
        `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeed/comments?newsfeed_id=${newsfeedId}&items=${itemsPerPage}${Boolean(backOfficeId) ? '&backofficeId=' + backOfficeId : ''}`,
        'GET'
      );
    },

    getNewsfeedUserlike: ({ likeStatus, newsfeedId, userId }) => {
      if (shouldUseMock()) {
        return getNewsfeedUserlikeMock();
      }
      return apiCall(
        `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeed/feedback?newsfeed_id=${newsfeedId}&feedback=${likeStatus}`,
        'POST'
      );
    },

    addCommentsToNewsFeed: (payload, userId) => {
      if (shouldUseMock()) {
        return addCommentsToNewsFeedMock();
      }
      return apiCall(
        `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeed/comment`,
        'POST',
        payload
      );
    },

    updateNewsFeedComment: (payload, userId) => {
      if (shouldUseMock()) {
        return updateNewsFeedCommentMock();
      }
      return apiCall(
        `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeed/comment?app_name=S4B`,
        'PUT',
        payload
      );
    },

    deleteNewsFeed: (newsfeedId, userId, backOfficeId) => {
      if (shouldUseMock()) {
        return deleteNewsFeedMock();
      }

      return apiCall(
        `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeed?newsfeed_id=${newsfeedId}`,
        'DELETE',
         {
            headers: { 'Content-Type': 'application/json' },
            data: {}
          }
      );
    },

    deleteNewsFeedComment: (commentId, userId, backOfficeId) => {
      if (shouldUseMock()) {
        return deleteNewsFeedCommentMock();
      }

      return apiCall(
        `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeed/comment?comment_id=${commentId}`,
        'DELETE',
         {
            headers: { 'Content-Type': 'application/json' },
            data: {}
          }
      );
    },
  };