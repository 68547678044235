import styled from 'styled-components/macro';
import { FooterHeight, NavBarHeight } from 'const';

export const AccessContainer = styled.form`
  width: 50%;

  fieldset {
    border: none;
    padding: 0;
  }

  @media ${props => props.theme.device.tablet} {
    margin-left: auto;
    margin-right: 10%;
    width: 60%;
  }

  @media ${props => props.theme.device.mobile} {
    width: 80%;
    height: 100%;
    margin: 0 10% 0 auto;
    position: relative;
  }
`;

export const CloseWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.darker};
  height: 100%;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Close = styled.span`
  font-size: x-large;
  color: ${props => props.theme.colors.white};
`;

export const Body = styled.div`
  height: calc(100vh - ${NavBarHeight}px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    height: calc(100vh - ${NavBarHeight}px - ${FooterHeight}px);
  }

  .sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
`;

export const TitleWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 42px;

  @media ${props => props.theme.device.desktop} { padding-bottom: 34px; }
  @media ${props => props.theme.device.laptop} { padding-bottom: 32px; }
  @media ${props => props.theme.device.tablet} {
    width: 100%;
    height: 33%;
    align-items: center;
    padding-bottom: 0;
  }
`;

export const Title = styled.div`
  padding-bottom: 6px;
  color: ${props => props.theme.colors.primary.base};
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  max-width: 100%;
`;

export const Link = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.colors.mineShaft};
  text-align: right;

  &:hover {
    color: ${props => props.theme.colors.primary.base};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const Image = styled.img`
  max-height: 100%

  @media ${props => props.theme.device.tablet} {
    position: absolute;
    bottom: 0;
    height: 100%;
    max-width: none;
    right: 0;
  }

  @media ${props => props.theme.device.mobile} {
    position: absolute;
    bottom: 0;
    height: 100%;
    max-width: none;
    right: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 40%;
  height: calc(100vh - ${NavBarHeight}px);
  display:flex;
  align-items: center;


  @media ${props => props.theme.device.tablet} {
    position: relative;
    max-height: 100%;
  }

  @media ${props => props.theme.device.mobile} {
    position: relative;
    max-height: 100%;
    width: 10%;
  }

`;

export const Foot = styled.div`
  display: flex;
  padding-top: 16px;
  color: ${props => props.theme.colors.mineShaft};
  border-top: solid 1px ${props => props.theme.colors.filterColor};
`;

export const SignUp = styled.span`
  padding-left: 8px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};

  &:hover {
    color: ${props => props.theme.colors.primary.darker};
  }
`;

export const ButtonWrapper = styled.div`
  padding-bottom: 38px;

  button {
    margin: auto;
  }

  @media ${props => props.theme.device.desktop} { padding-bottom: 30px; }
  @media ${props => props.theme.device.laptop} { padding-bottom: 38px; }
  @media ${props => props.theme.device.tablet} { padding-bottom: 40px; }
  @media ${props => props.theme.device.tablet} { padding-bottom: 36px; }

`;

export const InputsWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 44px;

  @media ${props => props.theme.device.desktop} {
    padding-bottom: 35px;
  }
  @media ${props => props.theme.device.tablet} {
    width: 100%;
    height: 33%;
    padding-bottom: 100px;
  }
`;

export const FooterWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${props => props.theme.device.desktop} {
    padding-bottom: 35px;
  }
  @media ${props => props.theme.device.tablet} {
    width: 100%;
    height: 33%;
    padding-bottom: 0;
  }
`;