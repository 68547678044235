import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Col, Container, Row } from 'ui/gridSystem';

import { Button, CustomInput } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import validator from 'utils/regex-validator';
import routes from 'routes';
import { COMPLETE_MANAGER, stepsOnboarding } from 'redux/actions/onBoarding';
import { Head, HeadWrapper, Logo } from 'pages/LandingPage/style';
import { getToken } from 'utils/token';
import { channel } from 'api/config';
//import { AUTHENTICATION_SIGNOUT } from 'redux/actions/authentication';
import { AgreementCheck } from 'ui/components';
import {
  ChangePasswordContainer,
  Title,
  Body,
  ButtonWrapper,
  InputWrapper,
} from './style';

const newField = () => ({
  value: undefined,
  valid: true,
});

const initialForm = {
  phone: newField(),
  fiscalCode: newField(),
  userJob: newField(),
};

const CompleteManager = ({
  mediaIsPhone,
  completeManager,
  pushUrl,
  stepOnBoarding,
  domain,
  agreements,
  agreementsModalChecked,
}) => {
  const [form, setForm] = useState(initialForm);

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const handleOnBlur = (type, field, callback) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });

    if (callback) callback();
  };

  const buttonDisabled =
    !Object.values(form).reduce((acc, field) => acc && field.value && field.value.length > 0, true) ||
    typeof form.fiscalCode.valid === 'string' ? !!form.fiscalCode.valid : !form.fiscalCode.valid;

  const handleCompleteManager = () => {
    const store = getToken();

    completeManager({
      refreshToken: store.refreshtoken,
      accessToken: store.accesstoken,
      phoneNumber: form.phone.value,
      fiscalCode: form.fiscalCode.value,
      userJob: form.userJob.value,
      channel,
      persDataAcceptance: (Object.values(agreements.user_clauses) || []).map(
        clause => ({
          authorize: clause.authorize,
          type: clause.type,
          mandatory: clause.mandatory,
        })
      ),
    });
  };

  useEffect(() => {
    if (stepOnBoarding === stepsOnboarding.STEP3) {
      pushUrl(routes.drawProfile.path);
    }
  }, [stepOnBoarding, pushUrl]);

  const formConfirmed = () => {
    let valid = true;
    if (agreements?.user_clauses) {
      (Object.keys(agreements.user_clauses) || []).forEach(key => (valid = valid && agreements.user_clauses[key]?.valid));
    } else {
      valid = false;
    }
    return valid && agreementsModalChecked;
  };

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo img={domain?.brand?.logo_white} className="no-pointer" />
          </Head>
        </Container>
      </HeadWrapper>
      <Container>
        <Row>
          <ChangePasswordContainer>
            <Col lg={6} md={6} sm={12}>
              <Body>
                <Title>
                  <ProDisplay
                    type="title"
                    configuration={mediaIsPhone ? { fontSize: '20' } : null}
                  >
                    Manca poco, inserisci gli ultimi dati
                  </ProDisplay>
                </Title>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Telefono *"
                    value={form.phone.value}
                    onChange={val => handleOnChange('phone', val)}
                    onBlur={() => handleOnBlur('string', 'phone')}
                    error={
                      typeof form.phone.valid === 'string'
                        ? form.phone.valid
                        : !form.phone.valid
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Codice Fiscale *"
                    value={form.fiscalCode.value}
                    forceUppercase
                    onChange={val =>
                      handleOnChange('fiscalCode', val.toUpperCase())
                    }
                    onBlur={() => handleOnBlur('fiscalCode', 'fiscalCode')}
                    error={
                      typeof form.fiscalCode.valid === 'string'
                        ? form.fiscalCode.valid
                        : !form.fiscalCode.valid
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Ruolo in azienda *"
                    value={form.userJob.value}
                    onChange={val => handleOnChange('userJob', val)}
                    onBlur={() => handleOnBlur('string', 'userJob')}
                    error={typeof form.userJob.valid === 'string'
                      ? form.userJob.valid
                      : !form.userJob.valid
                    }
                  />
                </InputWrapper>
                <Row>
                  <AgreementCheck
                    validator={formConfirmed}
                    isSimpleUser
                    isCompleteManager
                  />
                </Row>
                <ButtonWrapper>
                  <Button
                    type={Button.TYPE.PRIMARY}
                    text="Accedi"
                    disabled={buttonDisabled || !formConfirmed()}
                    onClick={handleCompleteManager}
                  />
                </ButtonWrapper>
              </Body>
            </Col>
          </ChangePasswordContainer>
        </Row>
      </Container>
    </>
  );
};

CompleteManager.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  // HOC (store)
  stepOnBoarding: PropTypes.string,
  domain: PropTypes.object,
  agreements: PropTypes.object,
  agreementsModalChecked: PropTypes.bool,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  completeManager: PropTypes.func.isRequired,
};

export default connect(
  ({
    onBoarding: { stepOnBoarding },
    domain,
    signup: { agreements, agreementsModalChecked },
  }) => ({
    stepOnBoarding,
    domain,
    agreements,
    agreementsModalChecked,
  }),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    completeManager: payload =>
      dispatch({ type: COMPLETE_MANAGER._REQUEST, payload }),
  })
)(withMediaQueries(CompleteManager));
