import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SET_SUBSCRIPTION_ON_EDIT,
  // SET_PREMIUM_SUBSCRIPTIONS,
  EMAIL_SUBSCRIPTION_UPDATE,
} from 'redux/actions/subscriptions';
import { MODAL_OPEN } from 'redux/actions';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { ActiveSubscriptionInterface } from 'interface/subscriptions';
import validator from 'utils/regex-validator';

import {
  ConfirmModal,
  DisableSubscriptionModal,
  TableCardDesktop,
  TableCardMobile
} from 'ui/components';
// import PlaylistPremiumModal from 'ui/components/Modals/PlaylistPremiumModal';

const TableCard = ({
  tableKey,

  // component params
  isProspectValid,
  subscription,

  // HOC state
  // productNames,
  subscriptionOnEdit,

  // HOC dispatch
  openConfirmModal,
  openDisabledSubscriptionModal,
  setSubscriptionOnEdit,
  updateEmailSubscription,

  // HOC media queries
  currentViewport,
  viewportTypes,
}) => {
  const { email, offerts } = subscription;
  const [changeEmail, setChangeEmail] = useState(
    offerts.map((acc, offert) => {
      acc[offert.prospectId] = false;
      return acc;
    }, {})
  );
  const [newEmail, setNewEmail] = useState(email);
  const [emailError, setEmailError] = useState(false);
    
  /* NOTE: to try in case of synchronizatio issues */
  // const [changeEmail, setChangeEmail] = useState({});
  // useEffect(() => {
  //   const newChangeEmail = subscription.offerts?.map((acc, offert) => {
  //     acc[offert.prospectId] = false;
  //     return acc;
  //   }, {})
  //   setChangeEmail(newChangeEmail);
  // }, [subscription, changeEmail, setChangeEmail])

  const handleOnChangeEmail = value => {
    setNewEmail(value);
  };

  const handleOnBlur = ({ subscriptionId, variationId }) => {
    const isValidEmail = validator.email(newEmail);
    if (!isValidEmail) return setEmailError('Inserisci un indirizzo email valido');
    const isValidProspect = isProspectValid(newEmail, subscriptionId, variationId);
    if (!isValidProspect) return setEmailError('Questa email è già stata associata al pacchetto');
    return setEmailError(false);
  };

  // const ordered = offs => {
  //   const orderedOffs = offs.split(', ');
  //   orderedOffs.sort();
  //   const baseIndex = orderedOffs.findIndex(o => o.toLowerCase() === 'base');
  //   if (baseIndex > 0) {
  //     orderedOffs.splice(baseIndex, 1);
  //     orderedOffs.unshift('Base');
  //   }
  //   return orderedOffs.join(', ');
  // };

  // const getAllTypes = offs => (offs.length
  //   ? offs
  //     .reduce((acc, item) => {
  //       const variation = (productNames || []).filter((product) => product.subscriptionId === item.subscriptionId);
  //       return variation[0] ? [...acc, variation[0].productName] : [...acc];
  //     }, [])
  //     .join(', ')
  //   : '');

  // const handleEditSubscription = offert => {
  //   setSubscriptionOnEdit({ email, ...offert });
  // };

  const checkActives = (offert) => (
    offert.status?.toLowerCase() !== 'not-registered'
    && offert.status?.toLowerCase() !== 'payment_pending'
  );

  const handleUpdateEmailProspect = (prospectId) => {
    if (newEmail !== email) {
      updateEmailSubscription({
        email,
        newEmail,
        prospectId,
      });
    }
  };

  const getPackageCount = (offs, type = 'base') => (
    offs.filter(offert => {
      return offert.packageType.toLowerCase() === type
    }).length
  );

  const countBasicPkgs = getPackageCount(offerts, 'base');
  const countPremiumPkgs = getPackageCount(offerts, 'premium');

  /*const getPackageName = (offs, type = 'base') => (
    offs.filter(offert => {
      return offert.packageType.toLowerCase() === type
    }).name
  );*/

  return (
    (viewportTypes[currentViewport] > viewportTypes.mediaIsTablet) ? (
      <TableCardDesktop
        parentKey={tableKey}
        changeEmail={changeEmail}
        countBasicPkgs={countBasicPkgs}
        countPremiumPkgs={countPremiumPkgs}
        email={email}
        newEmail={newEmail}
        emailError={emailError}
        offerts={offerts}
        checkActives={checkActives}
        handleOnBlur={handleOnBlur}
        handleOnChangeEmail={handleOnChangeEmail}
        handleUpdateEmailProspect={handleUpdateEmailProspect}
        setChangeEmail={setChangeEmail}
        subscriptionOnEdit={subscriptionOnEdit}
        openDisabledSubscriptionModal={openDisabledSubscriptionModal}
        openConfirmModal={openConfirmModal}
        setSubscriptionOnEdit={setSubscriptionOnEdit}
      />
    ) : (
      <TableCardMobile
        parentKey={tableKey}
        changeEmail={changeEmail}
        countBasicPkgs={countBasicPkgs}
        countPremiumPkgs={countPremiumPkgs}
        email={email}
        newEmail={newEmail}
        emailError={emailError}
        offerts={offerts}
        checkActives={checkActives}
        handleOnBlur={handleOnBlur}
        handleOnChangeEmail={handleOnChangeEmail}
        handleUpdateEmailProspect={handleUpdateEmailProspect}
        setChangeEmail={setChangeEmail}
        subscriptionOnEdit={subscriptionOnEdit}
        openDisabledSubscriptionModal={openDisabledSubscriptionModal}
        openConfirmModal={openConfirmModal}
        setSubscriptionOnEdit={setSubscriptionOnEdit}
      />
    )
  );
};

TableCard.propTypes = {
  isProspectValid: PropTypes.func,
  subscription: ActiveSubscriptionInterface,

  //HOC (connect, state)
  // productNames: PropTypes.array,
  subscriptionOnEdit: ActiveSubscriptionInterface,

  //HOC (connect, dispatch)
  openConfirmModal: PropTypes.func.isRequired,
  openDisabledSubscriptionModal: PropTypes.func.isRequired,
  // setPremiumSubscription: PropTypes.func.isRequired,
  setSubscriptionOnEdit: PropTypes.func.isRequired,
  updateEmailSubscription: PropTypes.func.isRequired,

  // HOC (withMediaQueries)
  currentViewport: PropTypes.string.isRequired,
  viewportTypes: PropTypes.object.isRequired,
};

export default withMediaQueries(
  connect(
    state => {
      const {
        subscriptionOnEdit,
        // productNames
      } = state.subscriptions;
      return {
        subscriptionOnEdit,
        // productNames,
      };
    },
    dispatch => ({
      setSubscriptionOnEdit: (subscription = null) =>
        dispatch({ type: SET_SUBSCRIPTION_ON_EDIT, subscription }),
      // setPremiumSubscription: subscription =>
      //   dispatch({ type: SET_PREMIUM_SUBSCRIPTIONS, subscription }),
      openConfirmModal: (payload = {}) =>
        dispatch({ type: MODAL_OPEN, id: ConfirmModal.id, payload }),
      openDisabledSubscriptionModal: (payload = {}) =>
        dispatch({
          type: MODAL_OPEN,
          id: DisableSubscriptionModal.id,
          payload,
        }),
      // openPlaylistPremiumModal: (payload = {}) =>
      //   dispatch({ type: MODAL_OPEN, id: PlaylistPremiumModal.id, payload }),
      updateEmailSubscription: payload =>
        dispatch({ type: EMAIL_SUBSCRIPTION_UPDATE._REQUEST, payload }),
    })
  )(TableCard)
);
