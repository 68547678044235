import { takeLatest, put, call, select } from 'redux-saga/effects';
import SuccessModal from "../../ui/components/Modals/SuccessModal";

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import { GET_ALL_NEWS_FEED,
  GET_COMMENTS_BY_NEWS_FEED,
  GET_NEWS_FEED_USERLIKE,
  ADD_COMMENT_TO_NEWS_FEED,
  UPDATE_NEWS_FEED_COMMENT,
  DELETE_NEWS_FEED,
  DELETE_NEWS_FEED_COMMENT } from 'redux/actions/allNewsFeed';

import allNewsFeed from 'api/allNewsFeed';

import ModalErrors from 'ui/components/Modals/ModalErrors';

function* getAllNewsFeed() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {nextPage, previousPage} = yield select(
      state => state.allNewsFeed.feeds
    )
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
      const {
        data: { newsfeeds, nextpage },
        status
      } = yield call(allNewsFeed.getAllNewsFeed, { userId, nextPage, itemsPerPage: 25 });
      if (status === 200) {
        yield put({ type: GET_ALL_NEWS_FEED._SUCCESS, newsfeeds, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    });
    console.log('sagas > allNewsFeed > getAllNewsFeed', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getAllNewsFeedWatch() {
  yield takeLatest(GET_ALL_NEWS_FEED._REQUEST, getAllNewsFeed);
}

function* getCommentsByNewsfeed({newsfeedId}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { backOfficeId } = yield select(
      state => state.authentication.user.userDomainAttributes
    );
    const {
      data: { comments },
      status
    } = yield call(allNewsFeed.getCommentsByNewsFeed, { userId, newsfeedId, itemsPerPage: 10, backOfficeId });
    if (status === 200) {
      yield put({ type: GET_COMMENTS_BY_NEWS_FEED._SUCCESS, comments });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    });
    console.log('sagas > commentsByNewsfeed > getCommentsByNewsfeed', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCommentsByNewsfeedWatch() {
  yield takeLatest(GET_COMMENTS_BY_NEWS_FEED._REQUEST, getCommentsByNewsfeed);
}

function* getNewsfeedUserlike({likeStatus, newsfeedId}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      status
    } = yield call(allNewsFeed.getNewsfeedUserlike, { userId, likeStatus, newsfeedId, itemsPerPage: 10 });
    if (status === 200) {
      yield put({ type: GET_NEWS_FEED_USERLIKE._SUCCESS, newsfeedId, likeStatus });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    });
    console.log('sagas > newsfeedUserlike > getNewsfeedUserlike', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewsfeedUserlikeWatch() {
  yield takeLatest(GET_NEWS_FEED_USERLIKE._REQUEST, getNewsfeedUserlike);
}

function* addCommentsToNewsFeed({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );

    const { data: {comment}, status } = yield call(allNewsFeed.addCommentsToNewsFeed, payload, userId);
    if (status?.toString() === '200') {
      yield put({
        type: ADD_COMMENT_TO_NEWS_FEED._SUCCESS, comment
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > allNewsFeeds > addCommentsToNewsFeed', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addCommentsToNewsFeedWatch() {
  yield takeLatest(ADD_COMMENT_TO_NEWS_FEED._REQUEST, addCommentsToNewsFeed);
}

function* updateNewsFeedComment({payload}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { data: {comment} } = yield call(allNewsFeed.updateNewsFeedComment, payload, userId);
    yield put({
      type: UPDATE_NEWS_FEED_COMMENT._SUCCESS, updateComment: comment
    });
  } catch (err) {
    console.log('sagas > allNewsFeeds > updateNewsFeedComment', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* updateNewsFeedCommentWatch() {
  yield takeLatest(UPDATE_NEWS_FEED_COMMENT._REQUEST, updateNewsFeedComment);
}

function* deleteNewsFeed({newsfeedId}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: userId } = {} } = yield select(
      state => state.authentication
    );
    const { backOfficeId } = yield select(
      state => state.authentication.user.userDomainAttributes
    );
    const { status, data } = yield call(allNewsFeed.deleteNewsFeed, newsfeedId, userId, backOfficeId);

    if (status === 200 || data.resultcode.toString() === '200') {
      yield put({
        type: MODAL_OPEN,
        id: SuccessModal.id,
        payload: {
          title: 'Feed rimosso',
          description:
            'Il feed é stato rimosso correttamente',
        },
      });
      yield put({
        type: DELETE_NEWS_FEED._SUCCESS,
        newsfeedId
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    });
    console.log('sagas > allNewsFeeds > deleteNewsFeed', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* deleteNewsFeedWatch() {
  yield takeLatest(DELETE_NEWS_FEED._REQUEST, deleteNewsFeed);
}

function* deleteNewsFeedComment({commentId}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: userId } = {} } = yield select(
      state => state.authentication
    );
    const { backOfficeId } = yield select(
      state => state.authentication.user.userDomainAttributes
    );
    
    const { status, data } = yield call(allNewsFeed.deleteNewsFeedComment, commentId, userId, backOfficeId);

    if (status === 200 || data.resultcode.toString() === '200') {
      yield put({
        type: MODAL_OPEN,
        id: SuccessModal.id,
        payload: {
          title: 'Commento rimosso',
          description:
            'Il commento é stato rimosso correttamente',
        },
      });
      yield put({
        type: DELETE_NEWS_FEED_COMMENT._SUCCESS,
        commentId
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    });
    console.log('sagas > allNewsFeeds > deleteNewsFeedComment', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* deleteNewsFeedCommentWatch() {
  yield takeLatest(DELETE_NEWS_FEED_COMMENT._REQUEST, deleteNewsFeedComment);
}

export default [
    getAllNewsFeedWatch(),
    getCommentsByNewsfeedWatch(),
    getNewsfeedUserlikeWatch(),
    addCommentsToNewsFeedWatch(),
    updateNewsFeedCommentWatch(),
    deleteNewsFeedWatch(),
    deleteNewsFeedCommentWatch()
];
