import { createAsyncActionType } from './index';

export const SET_SUBSCRIPTION_ON_EDIT =
  '@@subscription/SET_SUBSCRIPTION_ON_EDIT';
export const SET_SELECTED_SUBSCRIPTION =
  '@@subscription/SET_SELECTED_SUBSCRIPTION';
export const SET_SUBSCRIPTIONS_FILTER =
  '@@subscription/SET_SUBSCRIPTIONS_FILTER';

export const SET_DRAFT_SUBSCRIPTIONS_LOADED =
  '@@subscription/SET_DRAFT_SUBSCRIPTIONS_LOADED';

export const SET_PREMIUM_SUBSCRIPTIONS_FILTER =
  '@@premiumPurchase/SET_PREMIUM_SUBSCRIPTIONS_FILTER';
export const SET_PREMIUM_SUBSCRIPTIONS =
  '@@premiumPurchase/SET_PREMIUM_SUBSCRIPTIONS';
export const PREMIUM_PURCHASE_SUBMIT = createAsyncActionType(
  'premiumPurchase',
  'PREMIUM_PURCHASE_SUBMIT'
);

export const SET_NEW_USERS_SUBSCRIPTIONS = createAsyncActionType(
  'subscription',
  'SET_NEW_USERS_SUBSCRIPTIONS'
);
export const GET_TOTAL_AMOUNT_ANNUAL_PAYMENT = createAsyncActionType(
  'subscription',
  'GET_TOTAL_AMOUNT_ANNUAL_PAYMENT'
);

export const GET_SUBSCRIPTION_PRODUCT_NAMES = createAsyncActionType(
  'subscription',
  'GET_SUBSCRIPTION_PRODUCT_NAMES'
);

export const ACTIVE_SUBSCRIPTION_GET = createAsyncActionType(
  'subscription',
  'ACTIVE_SUBSCRIPTION_GET'
);

export const ASSOC_SUBSCRIPTIONS_GET = createAsyncActionType(
  'subscription',
  'ASSOC_SUBSCRIPTIONS_GET'
);

export const GET_CALENDARS = createAsyncActionType(
  'subscription',
  'GET_CALENDARS'
);

export const PREPARE_CREATE_PROSPECT_PREMIUM = createAsyncActionType(
  'subscription',
  'PREPARE_CREATE_PROSPECT_PREMIUM'
);

export const EMAIL_SUBSCRIPTION_UPDATE = createAsyncActionType(
  'subscription',
  'EMAIL_SUBSCRIPTION_UPDATE'
);

export const EMAIL_SUBSCRIPTION_DELETE = createAsyncActionType(
  'subscription',
  'EMAIL_SUBSCRIPTION_DELETE'
);

export const EMAIL_SUBSCRIPTION_DISABLE = createAsyncActionType(
  'subscription',
  'EMAIL_SUBSCRIPTION_DISABLED'
);

export const RECOMMENDED_SUBSCRIPTION_GET = createAsyncActionType(
  'subscription',
  'RECOMMENDED_SUBSCRIPTION_GET'
);

export const TEAMS_INTERESTS_GET = createAsyncActionType(
  'subscription',
  'TEAMS_INTERESTS_GET'
);

export const CATALOG_PRODUCT_GET = createAsyncActionType(
  'subscription',
  'CATALOG_PRODUCT_GET'
);

export const CREATE_PROSPECT = createAsyncActionType(
  'subscription',
  'CREATE_PROSPECT'
);

export const ALL_PROSPECT_GET = createAsyncActionType(
  'subscription',
  'ALL_PROSPECT_GET'
);

export const GO_TO_EDIT_SUBSCRIPTION = '@@subscription/GO_TO_EDIT_SUBSCRIPTION';
export const SET_EMAILS_PREMIUM_SUBSCIRPTION = '@@subscription/SET_EMAILS_PREMIUM_SUBSCIRPTION';

export const CONTENT_OVERVIEW_MOST_VIEWED_GET =
  '@@subscription/CONTENT_OVERVIEW_MOST_VIEWED_GET';
export const CONTENT_OVERVIEW_MOST_POPULAR_GET =
  '@@subscription/CONTENT_OVERVIEW_MOST_POPULAR_GET';
export const CONTENT_OVERVIEW_SET = '@@subscription/CONTENT_OVERVIEW_SET';

export const COMPACT_LIST = createAsyncActionType(
  'subscription',
  'COMPACT_LIST'
);

export const GET_CATEGORIES = createAsyncActionType(
  'subscription',
  'GET_CATEGORIES'
);

export const GET_FILTERED_CATALOG = createAsyncActionType(
  'subscription',
  'GET_FILTERED_CATALOG'
);

export const RESET_SUBSCRIPTIONS_INITIALSTATE =
  '@@subscription/RESET_SUBSCRIPTIONS_INITIALSTATE';

export const RESET_SUBSCRIPTIONS_ON_PAYMENT =
  '@@subscriptions/RESET_SUBSCRIPTIONS_ON_PAYMENT';

export const EVENT_LOCKED_SUBSCRIBE = createAsyncActionType('subscription', 'EVENT_LOCKED_SUBSCRIBE');