import React, { useEffect, useState } from 'react';
import { Container } from 'ui/gridSystem';
import MainContainer from '../style';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Head, IconBack, Title } from '../ViewAll/style';
import { push } from 'redux-first-history';
import routes from 'routes';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import PlaceholderImg from 'ui/assets/img/S4C_THUMBNAIL.png';
import { CustomInput } from 'ui/atoms';
import DeleteNewsFeedModal from "ui/components/Modals/ConfirmDeleteNewsFeedModal";
import { MODAL_OPEN } from 'redux/actions';
import { Button } from 'ui/atoms';
import {
  PostContainerHead,
  CardContainer,
  CommentCard,
  PostContainer,
  ImageCard,
  ActionsContainer,
  CommentsArea,
  NoItems,
  IconEdit,
  IconDelete,
  HtmlWrapper,
  ShowMoreComments,
  BtnContainer
} from './style';
import { GET_ALL_NEWS_FEED,
  GET_COMMENTS_BY_NEWS_FEED,
  RESET_COMMENTS_NEWS_FEED,
  GET_NEWS_FEED_USERLIKE,
  ADD_COMMENT_TO_NEWS_FEED,
  UPDATE_NEWS_FEED_COMMENT,
  DELETE_NEWS_FEED,
  DELETE_NEWS_FEED_COMMENT} from 'redux/actions/allNewsFeed';
  import { SET_PLAYLIST_HISTORY} from 'redux/actions/playlist';
import { concatStringToDate } from 'utils/date-format';
import { detailType } from 'utils/common';
import app from 'api/app';

const CommentsBoard = ({
  pushUrl,
  feeds,
  recentComments,
  comments,
  myLikesCounter,
  myComments,
  user,
  getAllNewsFeed,
  getCommentsByNewsfeed,
  getNewsfeedUserlike,
  addCommentsToNewsFeed,
  updateNewsFeedComment,
  resetFeeds,
  deleteNewsFeed,
  deleteNewsFeedComment,
  openModal,
  feedsNext,
  setPlaylistHistory,
  router
}) => {

  const [allnewsFeeds, setFeeds] = useState([]);
  const [commentsByNewsFeeds, setComments] = useState([]);
  const [myLikesCounterState, setMyLikesCounter] = useState([]);
  const [myCommentslist, setMyComments] = useState([]);
  const [feedId, setFeedId] = useState(null);
  const [commentId, setCommentId] = useState(null);
  const [nextVisible, setNextVisible] = useState(null);
  const [commentsCounterList, setCounterCommentslist] = useState([]);
  const [recentCommentsList, setRecentCommentsList] = useState([]);
  const [commentsToSubmitList, setCommentsToSubmit] = useState();
  const [commentsToUpdateList, setCommentsToUpdate] = useState();
  const [imagesList, setImagesList] = useState([]);

  window.alert = function(){}
  
  function handleSetCommentsToSubmit(newValue, id) {
    let commentsToSubmitListFiltered = commentsToSubmitList.filter(item => item.id !== id);
    const newValues = [...commentsToSubmitListFiltered, {id: id, value: newValue}];

    setCommentsToSubmit(newValues)
  }

  function handleSetCommentsToUpdate(newValue, id) {
    let commentsToUpdateListFiltered = commentsToUpdateList.filter(item => item.id !== id);
    const newValues = [...commentsToUpdateListFiltered, {id: id, value: newValue}];

    setCommentsToUpdate(newValues)
  }

  useEffect(() => {
    getAllNewsFeed();
  }, [getAllNewsFeed]);

   //image decoded logic
  useEffect(() => {
    let imagesArray = [];

    feeds.forEach(feed => {
      if(Boolean(feed.imageUrl) && Boolean(feed.objectType === 'no_content')) {
        try {
          app
            .getPicture(feed.imageUrl)
            .then(response => {
              const imageDecoded = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ''
                )
              );
              imagesArray = [...imagesArray, {
                image: `data:${response.headers[
                    'content-type'
                  ].toLowerCase()};base64,${imageDecoded}`,
                id: feed.newsfeedId
              }]
  
              setImagesList(imagesArray);
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log('app.getPicture', err);
            });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      }
    })
  }, [feeds]);

  useEffect(() => {
    setFeeds(feeds);
    setRecentCommentsList(recentComments);

    const recentCommentsIds = recentComments.flat().map(recentComment => recentComment.commentId);
    const filteredComments = comments.filter(comment => !recentCommentsIds.includes(comment.commentId));

    setComments(filteredComments);

    const allComments = [...recentComments, ...filteredComments, ...myComments];
    const allMyComments = allComments.filter(comment => comment.myComment === true);

    let myCommentsToUpdate = [];

    allMyComments.forEach(comment => {
      myCommentsToUpdate = [...myCommentsToUpdate, {
        id: comment.commentId,
        value: ''
      }];

      setCommentsToUpdate(myCommentsToUpdate);
    });

    let commentsCounter = [];
    let initialCommentsState = [];

    feeds.forEach(feed => {
      const showMoreButtonClicked = document.querySelector(`.show-comments.item-${feed.newsfeedId}.clicked`);
      const iconButtonDeleteClicked = document.querySelector(`.icon-icona-elimina.item-${feed.newsfeedId}.clicked`);

      initialCommentsState = [...initialCommentsState, {id: feed.newsfeedId, value: ''}];
      setCommentsToSubmit(initialCommentsState);

      commentsCounter = [
        ...commentsCounter,
        {
          id: feed.newsfeedId,

          counter: !comments.filter(comment => comment.newsfeedId === feed.newsfeedId).length && !!showMoreButtonClicked ?
            filteredComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length + myComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length +
              recentComments.filter(recentComment => recentComment.newsfeedId === feed.newsfeedId).length :
          
            !comments.filter(comment => comment.newsfeedId === feed.newsfeedId).length && recentComments.filter(recentComment => recentComment.newsfeedId === feed.newsfeedId).length === 2 && feed.commentsCounter > 2 ?
            feed.commentsCounter + myComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length :

            !comments.filter(comment => comment.newsfeedId === feed.newsfeedId).length && recentComments.filter(recentComment => recentComment.newsfeedId === feed.newsfeedId).length === 1 &&  feed.commentsCounter > 2 && !!iconButtonDeleteClicked ?
              feed.commentsCounter - 1 + myComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length :

            !comments.filter(comment => comment.newsfeedId === feed.newsfeedId).length && recentComments.filter(recentComment => recentComment.newsfeedId === feed.newsfeedId).length === 0 && feed.commentsCounter > 2 && !showMoreButtonClicked && !!iconButtonDeleteClicked?
            feed.commentsCounter - 2 + myComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length :

            filteredComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length + myComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length +
              recentComments.filter(recentComment => recentComment.newsfeedId === feed.newsfeedId).length
        }
      ];

      if (!!filteredComments.filter(comment => comment.newsfeedId === feed.newsfeedId).length && !!showMoreButtonClicked) {
        showMoreButtonClicked.classList.add('d-none');
      }

      setCounterCommentslist(commentsCounter);
    });
  }, [feeds, comments, recentComments, myComments]);

  useEffect(() => {
    setNextVisible(feedsNext);
  }, [feedsNext]);

  useEffect(() => {
    setMyComments(myComments);
  }, [myComments]);

  useEffect(() => {
    setMyLikesCounter(myLikesCounter);
  }, [myLikesCounter]);

  useEffect(() => {
    return () => {
      resetFeeds();
     };
   }, []);

  /*function getPostTime(sharedDate) {
    const now = new Date();
    const formattedFeedDate = new Date(sharedDate);
    const hoursDiff = Math.abs(now.getTime() - formattedFeedDate.getTime()) / 3600000;

    if (hoursDiff < 1) {
      return 'Poco fa';
    } else if (hoursDiff >= 1 && hoursDiff <= 24) {
      return '1 giorno fa';
    } return `${concatStringToDate(formattedFeedDate.getDate())} - ${concatStringToDate(formattedFeedDate.getMonth() + 1)} - ${formattedFeedDate.getFullYear()}`
  }*/


  const redirectTo = (typeRed, itemIdRed) => {
    let goToType;
    switch (typeRed) {
      case 'learning_object':
        goToType = 'course';
        break;
      case 'news':
        goToType = 'news';
        break;
      default:
        break;
    }
    return `${routes.detail.path}/${goToType}/${itemIdRed}`;
  };

  function getCommentTime(commentDate) {
    const now = new Date();
    const formattedCommentDate = new Date(commentDate);
    const hoursDiff = Math.round(Math.abs(now.getTime() - formattedCommentDate.getTime()) / 3600000);

    if(hoursDiff === 24) {
      return '1 giorno';
    } else if (hoursDiff === 0) {
      return 'Poco fa'
    } else if(hoursDiff < 24) {
      return `${Math.round(hoursDiff)}h`;
    } else if(hoursDiff > 24 && hoursDiff < 178) {
      return `${Math.round(hoursDiff/24)} giorni`;
    } else if (hoursDiff === 178) {
      return '1 settimana';
    } else if(hoursDiff > 178 && hoursDiff < 730) {
      return `${Math.round(hoursDiff/178)} settimane`;
    } else if(hoursDiff === 730) {
      return '1 mese';
    } else if(hoursDiff > 730 && hoursDiff < 860) {
      return `${Math.round(hoursDiff/730)} mesi`;
    } else if(hoursDiff === 860) {
      return '1 anno';
    } else if(hoursDiff > 730 && hoursDiff < 8760) {
      return `${Math.round(hoursDiff/8760)} anni`;
    } return `${concatStringToDate(formattedCommentDate.getDate())} - ${concatStringToDate(formattedCommentDate.getMonth() + 1)} - ${formattedCommentDate.getFullYear()}`
  }

  /*function handleClickComment(itemId) {
    const myCommentElement = document.querySelector(`.my-comment.item-${itemId}`);
    myCommentElement.classList.toggle('unvisible');

    setCommentToSubmit('');
  }*/

  function handleClickShowMoreComments(newsfeedId) {
    const showMoreButton = document.querySelector(`.show-comments.item-${newsfeedId}`);
    showMoreButton.classList.add('clicked');

    if (allnewsFeeds.filter(item => item.newsfeedId === newsfeedId)[0]?.commentsCounter > 2) {
      getComments(newsfeedId);
    }
  }

  function handleClickLike(newsfeedId) {
    const likeButton = document.querySelector(`.circle.like.item-${newsfeedId}`);
    likeButton.classList.toggle('liked');
    const likedButtonActive = document.querySelector(`.circle.like.item-${newsfeedId}.liked`);
    let like = !!likedButtonActive ? true : false;
    getNewsfeedUserlike(like, newsfeedId);
  }

  function submitComment(feedItem) {
    const payload = {
      body: commentsToSubmitList.find(comment => comment.id === feedItem.newsfeedId).value,
      title: `${user.firstname} ${user.lastname}` ,
      newsfeedId: feedItem.newsfeedId
    }

    addCommentsToNewsFeed(payload);

    let commentsToSubmitListCleared = commentsToSubmitList.filter(item => item.id !== feedItem.newsfeedId);
    const newValues = [...commentsToSubmitListCleared, {id: commentsToSubmitList.find(comment => comment.id === feedItem.newsfeedId).id, value: ''}];

    setCommentsToSubmit(newValues);

    /*const myCommentElement = document.querySelector(`.my-comment.item-${feedItem.itemId}`);
    myCommentElement.classList.add('unvisible');*/
  }

  function updateComment(commentItem) {
    if (!!commentsToUpdateList.find(comment => comment.id === commentItem.commentId).value) {
      const payload = {
        body: commentsToUpdateList.find(comment => comment.id === commentItem.commentId).value,
        title: `${user.firstname} ${user.lastname}`,
        idComment: commentItem.commentId
      }

      updateNewsFeedComment(payload);
      updateAvailable(commentItem.commentId);
    }
  }

  function updateAvailable(commentId) {
    const updateCommentStatic = document.querySelector(`.comment.item-${commentId}`);
    const updateCommentInput = document.querySelector(`.edit-comment-wrapper.item-${commentId}`);
    const edit =  document.querySelector(`.icon-modifica.item-${commentId}`);
    const editCancel =  document.querySelector(`.edit-comment-label.cancel.item-${commentId}`);
    const editShare =  document.querySelector(`.edit.share-comment-wrapper.item-${commentId}`);

    updateCommentStatic.classList.toggle('d-none');
    updateCommentInput.classList.toggle('d-none');
    editShare.classList.toggle('d-none');
    edit.classList.toggle('d-none');
    editCancel.classList.toggle('d-none');
  }

  function deleteElement(feedItem, commentItem, feed) {
    if (!!feed) {
      const iconButtonDelete = document.querySelector(`.icon-icona-elimina.item-${feed.newsfeedId}`);
      iconButtonDelete.classList.add('clicked');
    }

    setFeedId(feedItem);
    setCommentId(commentItem)
    openModal(DeleteNewsFeedModal.id);
  }

  function getComments(newsfeedId) {
    if (!commentsByNewsFeeds.filter(comment => comment.newsfeedId === newsfeedId).length) {
      getCommentsByNewsfeed(newsfeedId);
    }
  }

  const isAdmin = user?.userDomainAttributes?.admin || user?.userDomainAttributes?.backOffice; //true only for test

  const handleClickPost = ( item, type) => {

    setPlaylistHistory(router.location.pathname + router.location.search);
    switch(type.toLowerCase()) {
      case 'news':
        pushUrl(`${routes.detail.path}/${detailType.NEWS}/${item.itemId}`);
        break;
      case 'training':
      case 'lo':
      case 'learning_object':
        pushUrl(`${routes.detail.path}/${detailType.COURSE}/${item.itemId}`);
        break;
      case 'collection':
      case 'selection': {
        const productId= item.productId;
        const itemId = item.id ? item.id : item.itemId;
        if (item.locked) {
          pushUrl(`${routes.lockedDetails.path}/${itemId}${!!productId ? '/' + productId : ''}`);
        } else  {
            pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${itemId}`);
        }
        break;
      }
      case 'live_event': {
          const itemId = item.id ? item.id : item.itemId;
          const productId= item.productItemId;
          if (item.locked) {
            pushUrl(`${routes.eventLockedDetail.path}/${itemId}/${productId}`)
          } else {
            pushUrl(`${routes.event.path}/${itemId}`);
          }
          break;
        }
      default:
    }
  };
  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <IconBack
              className="icon-arrow-right"
              onClick={() => pushUrl(routes.homepage.path)}
            />
            <Title>
              <ProDisplay type="pageTitle">
                Indietro
              </ProDisplay>
            </Title>
          </Head>
          <ProDisplay className="title"
            type="5"
            configuration={{
              fontSize: 20,
              fontWeight: 700,
            }}>
            Bacheca
					</ProDisplay>
          {Boolean(allnewsFeeds) && allnewsFeeds.length ? allnewsFeeds.map(feedItem => (<PostContainer>
            <PostContainerHead>
              <div className='avatar'>
                <ProDisplay className="initials"
                  type="5"
                  configuration={{
                    fontSize: 25,
                    fontWeight: 400
                  }}>
                    {feedItem.sharedInfo.firstName.charAt(0) + feedItem.sharedInfo.lastName.charAt(0)}
                  </ProDisplay>
                </div>
                <div className='head-text-wrapper'>
                  <div className='head-description'>
                    <ProDisplay className="name"
                        type="5"
                        configuration={{
                          fontSize: 20,
                          fontWeight: 700,
                        }}>
                        {feedItem.sharedInfo.firstName + ' ' + feedItem.sharedInfo.lastName}
                    </ProDisplay>
                    <ProDisplay className="description"
                        type="5"
                        configuration={{
                          fontSize: 20,
                          fontWeight: 400,
                        }}>
                        ha condiviso {feedItem.title}
                    </ProDisplay>
                  </div>
                  <div className='head-comment'>
                    {feedItem.sharedInfo.sharedMessage &&
                        <ProDisplay className="comment"
                            type="5"
                            configuration={{
                              fontSize: 20,
                              fontWeight: 500,
                            }}>
                            "{feedItem.sharedInfo.sharedMessage}"
                        </ProDisplay>
                    }
                    <div className='comment-time-wrapper d-flex align-items-center'>
                      <ProDisplay className="comment-time"
                          type="5"
                          configuration={{
                            fontSize: 15,
                            fontWeight: 500,
                          }}>
                          {getCommentTime(feedItem.sharedInfo.sharedDateTS)}
                      </ProDisplay>
                      {!!isAdmin && <div className='remove-feed-wrapper d-flex justify-content-end'>
                        <IconDelete className="icon-icona-elimina" onClick={() => deleteElement(feedItem.newsfeedId, null)}/>
                      </div>}
                    </div>
                  </div>
                </div>
            </PostContainerHead>
              <CardContainer>
                <ImageCard bg={feedItem.objectType === 'no_content' ? imagesList?.find(image => image?.id === feedItem.newsfeedId) ? imagesList?.find(image => image.id === feedItem.newsfeedId)?.image : PlaceholderImg : feedItem.imageUrl ? feedItem.imageUrl : PlaceholderImg}></ImageCard>
                <CommentCard className='comments-card'>
                  <ProDisplay className="card-title"
                    onClick={() => {
                      if(feedItem.objectType !== 'no_content'){
                        handleClickPost(feedItem, feedItem.objectType)
                      }
                      }}
                    type="5"
                    configuration={{
                      fontSize: 30,
                      fontWeight: 600,
                      cursor: feedItem.objectType !== 'no_content' ? 'pointer' : 'default'
                    }}>
                    {feedItem.title}
                  </ProDisplay>
                  <ProDisplay className="card-subtitle"
                    type="5"
                    configuration={{
                      fontSize: 17,
                      fontWeight: 400,
                    }}>
                    <HtmlWrapper
                        dangerouslySetInnerHTML={{ __html: feedItem.description }}
                    />
                  </ProDisplay>
                  <ActionsContainer>
                    <div className='actions'>
                      <div className="action-item">
                        <div className={`circle like item-${feedItem.newsfeedId} ${feedItem.feedback.currentUserVote === 1 ? 'liked' : ''}`} onClick={() => handleClickLike(feedItem.newsfeedId, )}>
                          <div className="image-wrapper">
                            <div className="image"></div>
                          </div>
                        </div>
                        <div className='label'>
                          <ProDisplay className="like"
                            type="5"
                            configuration={{
                              fontSize: 12,
                              fontWeight: 700,
                            }}>
                            Mi piace
                          </ProDisplay>
                        </div>
                      </div>
                    </div>
                    <div className='actions-details'>
                      <div className="comments-shares">
                        <ProDisplay id={feedItem.newsfeedId} className="comments"
                            type="5"
                            configuration={{
                              fontSize: 12,
                              fontWeight: 700,
                            }}>
                        {commentsCounterList.filter(item => item.id === feedItem.newsfeedId)[0]?.counter} Commenti
                        </ProDisplay>
                        <ProDisplay className="shares"
                            type="5"
                            configuration={{
                              fontSize: 12,
                              fontWeight: 700,
                            }}>
                          {feedItem.sharedCounter} Condivisioni
                        </ProDisplay>
                      </div>
                      <div className="likes">
                        <div className="like">
                          <div className="image"></div>
                          <ProDisplay className="counter"
                            type="5"
                            configuration={{
                              fontSize: 12,
                              fontWeight: 700,
                            }}>
                            { !!myLikesCounterState.length ? feedItem.feedback.positive + (myLikesCounterState?.filter(like => like.newsfeedId === feedItem.newsfeedId)[0]?.likeStatus === true ? 1 : 0) :
                              feedItem.feedback.positive}
                          </ProDisplay>
                        </div>
                      </div>
                    </div>
                  </ActionsContainer>
                  <CommentsArea>
                    <div className={`my-comment item-${feedItem.itemId}`}>
                      <div className="avatar">
                        <ProDisplay className="initials"
                          type="5"
                          configuration={{
                            fontSize: 25,
                            fontWeight: 400
                          }}>
                         {user.firstname.charAt(0) + user.lastname.charAt(0)}
                          </ProDisplay>
                      </div>
                      <div className="input-wrapper">
                        <CustomInput
                          placeholderFocusNone={true}
                          id={`comment-input-${feedItem.newsfeedId}`}
                          type="text"
                          placeholder='Commenta...'
                          onChange={value => handleSetCommentsToSubmit(value, feedItem.newsfeedId)}
                          value={commentsToSubmitList.find(comment => comment.id === feedItem.newsfeedId).value}
                        />
                        <div className="share-comment-wrapper" onClick={()=>submitComment(feedItem)}>
                          <div className='share-comment'></div>
                        </div>
                      </div>
                    </div>
                    <div className="my-comments">
                      {myCommentslist?.filter(item => item.newsfeedId === feedItem.newsfeedId)?.map(comment => (<div className="comment-item">
                        <ProDisplay className="name"
                          type="5"
                          configuration={{
                            fontSize: 15,
                            fontWeight: 600
                          }}>
                            {comment.firstName} {comment.lastName}
                        </ProDisplay>
                        <div className="comment-container">
                          <div className="avatar">
                            <ProDisplay className="initials"
                              type="5"
                              configuration={{
                                fontSize: 25,
                                fontWeight: 400
                              }}>
                                {comment.firstName.charAt(0) + comment.lastName.charAt(0)}
                            </ProDisplay>
                          </div>
                          <div className="comment-wrapper">
                            <ProDisplay className={`comment item-${comment.commentId}`}
                                type="5"
                                configuration={{
                                  fontSize: 15,
                                  fontWeight: 400
                                }}>
                                  {comment.body}
                            </ProDisplay>
                            <div className={`edit-comment-wrapper item-${comment.commentId} d-none`}>
                              <CustomInput className='edit-comment-input'
                                placeholderFocusNone={true}
                                id="update-comment-input"
                                type="text"
                                placeholder={comment.body}
                                onChange={value => handleSetCommentsToUpdate(value, comment.commentId)}
                                value={comment.body}
                              />
                            </div>
                          </div>
                          <div className={`edit share-comment-wrapper d-none item-${comment.commentId}`} onClick={()=>updateComment(comment)}>
                            <div className='share-comment'></div>
                          </div>
                          <div className='edit-comment' onClick={() => updateAvailable(comment.commentId)}>
                            <IconEdit
                              className={`icon-modifica item-${comment.commentId}`}
                            />
                            <ProDisplay className={`edit-comment-label cancel item-${comment.commentId} d-none`}
                                type="5"
                                configuration={{
                                  fontSize: 11,
                                  fontWeight: 500
                                }}>
                                  Annulla modifica
                            </ProDisplay>
                          </div>
                          {(!!isAdmin || !!comment.myComment) && <div className='remove-comment-wrapper d-flex justify-content-end'>
                            <IconDelete className={`icon-icona-elimina item-${feedItem.newsfeedId}`} onClick={() => deleteElement(null, comment.commentId, feedItem)}/>
                          </div>}
                        </div>
                        <div className="comments-actions">
                          {/*<ProDisplay className="like"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                Mi piace
                          </ProDisplay>
                          <ProDisplay className="comment"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                Rispondi
                            </ProDisplay>*/}
                          <ProDisplay className="time"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                {getCommentTime(comment.creationDateTS)}
                          </ProDisplay>
                        </div>
                      </div>))}
                    </div>
                    <div className="last-comments">
                      {recentCommentsList?.filter(item => item?.newsfeedId === feedItem.newsfeedId)?.map(comment => (<div className="comment-item">
                        <ProDisplay className="name"
                          type="5"
                          configuration={{
                            fontSize: 15,
                            fontWeight: 600
                          }}>
                            {comment.firstName} {comment.lastName}
                        </ProDisplay>
                        <div className="comment-container">
                          <div className="avatar">
                            <ProDisplay className="initials"
                              type="5"
                              configuration={{
                                fontSize: 25,
                                fontWeight: 400
                              }}>
                                {comment.firstName.charAt(0) + comment.lastName.charAt(0)}
                            </ProDisplay>
                          </div>
                          <div className="comment-wrapper">
                            <ProDisplay className={`comment item-${comment.commentId}`}
                                type="5"
                                configuration={{
                                  fontSize: 15,
                                  fontWeight: 400
                                }}>
                                  {comment.body}
                            </ProDisplay>
                            <div className={`edit-comment-wrapper item-${comment.commentId} d-none`}>
                              <CustomInput className='edit-comment-input'
                                placeholderFocusNone={true}
                                id="update-comment-input"
                                type="text"
                                placeholder={comment.body}
                                onChange={value => handleSetCommentsToUpdate(value, comment.commentId)}
                                value={comment.body}
                              />
                            </div>
                          </div>
                          <div className={`edit share-comment-wrapper d-none item-${comment.commentId}`} onClick={()=>updateComment(comment)}>
                            <div className='share-comment'></div>
                          </div>
                          {!!comment.myComment && <div className='edit-comment' onClick={() => updateAvailable(comment.commentId)}>
                            <IconEdit
                              className={`icon-modifica item-${comment.commentId}`}
                            />
                            <ProDisplay className={`edit-comment-label cancel item-${comment.commentId} d-none`}
                                type="5"
                                configuration={{
                                  fontSize: 11,
                                  fontWeight: 500
                                }}>
                                  Annulla modifica
                            </ProDisplay>
                          </div>}
                          <div className={`edit share-comment-wrapper d-none item-${comment.commentId}`} onClick={()=>updateComment(comment)}>
                            <div className='share-comment'></div>
                          </div>
                          {(!!isAdmin || !!comment.myComment) && <div className='remove-comment-wrapper d-flex justify-content-end'>
                            <IconDelete className={`icon-icona-elimina item-${feedItem.newsfeedId}`} onClick={() => deleteElement(null, comment.commentId, feedItem)}/>
                          </div>}
                        </div>
                        <div className="comments-actions">
                          {/*<ProDisplay className="like"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                Mi piace
                          </ProDisplay>
                          <ProDisplay className="comment"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                Rispondi
                            </ProDisplay>*/}
                          <ProDisplay className="time"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                {getCommentTime(comment.creationDateTS)}
                          </ProDisplay>
                        </div>
                      </div>))}
                    </div>
                    {feedItem.commentsCounter > 2 && <div className="others">
                      {commentsByNewsFeeds?.filter(item => item?.newsfeedId === feedItem.newsfeedId)?.map(comment => (<div className="comment-item">
                        <ProDisplay className="name"
                          type="5"
                          configuration={{
                            fontSize: 15,
                            fontWeight: 600
                          }}>
                            {comment.firstName} {comment.lastName}
                        </ProDisplay>
                        <div className="comment-container">
                          <div className="avatar">
                            <ProDisplay className="initials"
                              type="5"
                              configuration={{
                                fontSize: 25,
                                fontWeight: 400
                              }}>
                                {comment.firstName.charAt(0) + comment.lastName.charAt(0)}
                            </ProDisplay>
                          </div>
                          <div className="comment-wrapper">
                            <ProDisplay className={`comment item-${comment.commentId}`}
                                type="5"
                                configuration={{
                                  fontSize: 15,
                                  fontWeight: 400
                                }}>
                                  {comment.body}
                            </ProDisplay>
                            <div className={`edit-comment-wrapper item-${comment.commentId} d-none`}>
                              <CustomInput className='edit-comment-input'
                                placeholderFocusNone={true}
                                id="update-comment-input"
                                type="text"
                                placeholder={comment.body}
                                onChange={value => handleSetCommentsToUpdate(value, comment.commentId)}
                                value={comment.body}
                              />
                            </div>
                          </div>
                          <div className={`edit share-comment-wrapper d-none item-${comment.commentId}`} onClick={()=>updateComment(comment)}>
                            <div className='share-comment'></div>
                          </div>
                          {!!comment.myComment && <div className='edit-comment' onClick={() => updateAvailable(comment.commentId)}>
                            <IconEdit
                              className={`icon-modifica item-${comment.commentId}`}
                            />
                            <ProDisplay className={`edit-comment-label cancel item-${comment.commentId} d-none`}
                                type="5"
                                configuration={{
                                  fontSize: 11,
                                  fontWeight: 500
                                }}>
                                  Annulla modifica
                            </ProDisplay>
                          </div>}
                          <div className={`edit share-comment-wrapper d-none item-${comment.commentId}`} onClick={()=>updateComment(comment)}>
                            <div className='share-comment'></div>
                          </div>
                          {(!!isAdmin || !!comment.myComment) && <div className='remove-comment-wrapper d-flex justify-content-end'>
                            <IconDelete className={`icon-icona-elimina item-${feedItem.newsfeedId}`}  onClick={() => deleteElement(null, comment.commentId, feedItem)}/>
                          </div>}
                        </div>
                        <div className="comments-actions">
                          {/*<ProDisplay className="like"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                Mi piace
                          </ProDisplay>
                          <ProDisplay className="comment"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                Rispondi
                            </ProDisplay>*/}
                          <ProDisplay className="time"
                              type="5"
                              configuration={{
                                fontSize: 15,
                                fontWeight: 400
                              }}>
                                {getCommentTime(comment.creationDateTS)}
                          </ProDisplay>
                        </div>
                      </div>))}
                    </div>}
                  </CommentsArea>
                  {feedItem.commentsCounter > 2 &&
                   <ShowMoreComments>
                    <BtnContainer className={`show-comments item-${feedItem.newsfeedId}`}>
                      <Button
                        text="Mostra tutti i commenti"
                        type="PRIMARY"
                        height={35}
                        fontSize={16}
                        onClick={() => handleClickShowMoreComments(feedItem.newsfeedId)}
                      />
                    </BtnContainer>
                  </ShowMoreComments>}
                </CommentCard>
              </CardContainer>
              <DeleteNewsFeedModal submit={() => Boolean(feedId) ? deleteNewsFeed(feedId) :  deleteNewsFeedComment(commentId)}></DeleteNewsFeedModal>
          </PostContainer>)) :
          <NoItems>
            <ProDisplay className="no-items"
              type="5"
              configuration={{
                fontSize: 15,
                fontWeight: 400
              }}>
                Non ci sono elementi da mostrare
            </ProDisplay>
          </NoItems>}
          {nextVisible && <BtnContainer className="show-posts">
              <Button
                text="Mostra altri"
                type="PRIMARY"
                height={35}
                fontSize={16}
                onClick={() => getAllNewsFeed()}
              />
            </BtnContainer>}
        </Container>
      </MainContainer>
    </>
  );
};

CommentsBoard.propTypes = {
  feeds: PropTypes.array,
  myComments: PropTypes.array,
  recentComments: PropTypes.array,
  comments: PropTypes.array,
  myLikesCounter: PropTypes.array,
  getAllNewsFeed: PropTypes.func,
  getCommentsByNewsfeed: PropTypes.func,
  pushUrl: PropTypes.func,
  getNewsfeedUserlike: PropTypes.func,
  resetFeeds: PropTypes.func,
  addCommentsToNewsFeed: PropTypes.func,
  updateNewsFeedComment: PropTypes.func,
  deleteNewsFeed: PropTypes.func,
  deleteNewsFeedComment: PropTypes.func,
  user: PropTypes.object,
  feedsNext: PropTypes.bool,
  setPlaylistHistory: PropTypes.func,
  router: PropTypes.object,
};

CommentsBoard.defaultProps = {
	feeds: [],
  comments: [],
  myLikesCounter: [],
  myComments: []
};

export default withMediaQueries(
  connect(
    state => {
      const { comments, myLikesCounter, myComments, recentComments } = state.allNewsFeed;
      const { newsfeeds: feeds} = state.allNewsFeed.feeds;
      const feedsNext = Boolean(state.allNewsFeed.feeds.nextPage)
      const { user } = state.authentication;
      const { router } = state;

      return {
        feeds,
        recentComments,
        comments,
        myLikesCounter,
        myComments,
        user,
        feedsNext,
        router
      };
    },
    dispatch => ({
      resetFeeds: () => dispatch({type: RESET_COMMENTS_NEWS_FEED}),
      pushUrl: url => [dispatch(push(url))],
      getAllNewsFeed: () => dispatch({type: GET_ALL_NEWS_FEED._REQUEST}),
      getCommentsByNewsfeed: newsfeedId => dispatch({type: GET_COMMENTS_BY_NEWS_FEED._REQUEST, newsfeedId}),
      getNewsfeedUserlike: (likeStatus, newsfeedId) => dispatch({type: GET_NEWS_FEED_USERLIKE._REQUEST, likeStatus, newsfeedId}),
      addCommentsToNewsFeed: payload => dispatch({type: ADD_COMMENT_TO_NEWS_FEED._REQUEST, payload}),
      updateNewsFeedComment: payload => dispatch({type: UPDATE_NEWS_FEED_COMMENT._REQUEST, payload}),
      deleteNewsFeed: newsfeedId => dispatch({type: DELETE_NEWS_FEED._REQUEST, newsfeedId}),
      deleteNewsFeedComment: commentId => dispatch({type: DELETE_NEWS_FEED_COMMENT._REQUEST, commentId}),
      openModal: id => dispatch({ type: MODAL_OPEN, id }),
      setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),
    })
  )(CommentsBoard)
);

