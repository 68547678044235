const FontBase = 16;
const NavBarHeight = 60;
const NavBarHeightTablet = 67;
const FooterHeight = 148;
const MenuHeight = 77;
const MenuHeightLaptop = 86;
const MenuHeightTablet = 134;

export {
  FontBase,
  NavBarHeight,
  NavBarHeightTablet,
  FooterHeight,
  MenuHeight,
  MenuHeightLaptop,
  MenuHeightTablet,
};

export const CookiePolicyURL =
  'https://www.digited.it/cookie-policy';
export const PrivacyPolicyURL =
  'https://www.digited.it/privacy-policy';

export const Digited = 'https://www.digited.it';

export const CollectionPrice = '2.99';

export const MODES = {
  MANAGER: 'MANAGER',
  USER: 'USER',
};

export const PlaylistTypes = {
  SELECTIONS: 'selections',
  COLLECTIONS: 'collections',
  RECOMMENDED_COLLECTIONS: 'recommended-collections',
  EVENTS: 'events',
  CATEGORIES: 'categories'
};
