import { takeLatest, put, call, select, delay } from 'redux-saga/effects';

import { push } from 'redux-first-history';
import {
  AUTH_LOGIN,
  LOADER_CLOSE,
  LOADER_OPEN,
  MODAL_OPEN,
} from 'redux/actions';

import {
  SIGNUP_NEXT_STEP,
  SIGNUP_PRODUCTS,
  SIGNUP_CREATE_CART,
  SIGNUP_SET_MANAGER_INFO,
  SIGNUP_SET_MANAGER_PAYMENT_INFO,
  SIGNUP_SET_HEADER_VISIBLE,
  SECTOR_GET,
  SIGNUP_GET_LATEST_CART,
  SIGNUP_SET_DISCOUNT,
  SIGNUP_FIRST_STEP,
  SIGNUP_CONFIRM_USER,
  SIGNUP_RESEND_CODE,
  SIGNUP_CART_CONFIRM,
  SIGNUP_SET_SUPER_MANAGER_INFO,
  REMOVE_PROMO,
  SIGNUP_RESET_CART,
  SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA,
  SIGNUP_ORDER_PREVIEW_ORDER_ZUORA,
  SIGNUP_SUBMIT_ORDER_ZUORA,
  SIGNUP_ORDER_DRAFT_SUPER_MANAGER, SIGNUP_SET_PAYMENT_METHOD_ID,
} from 'redux/actions/signup';
import { Steps } from 'redux/reducers/signup';
import signup from 'api/signup';
import subscriptions from 'api/subscriptions';
import routes from 'routes';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  AUTHENTICATION_SIGNIN,
  AUTHENTICATION_SIGNOUT, RESET_DATA_TREATMENT,
} from 'redux/actions/authentication';
import { getToken, setToken } from 'utils/token';
import authentication from 'api/authentication';
import { channel } from 'api/config';
import { PROFILE_DATA_ACCOUNT_GET, PROFILE_DATA_COMPANY_GET, PROFILE_GET_DATA_PRIVACY } from 'redux/actions/profile';
import { GET_LANDINGPAGE_PRODUCTS } from 'redux/actions/landingPage';

import ResendOTPModal from 'ui/components/Modals/ResendOTPModal';
import PremiumPurchaseModal from 'ui/components/Modals/PremiumPurchaseModal';
import {
  CREATE_PROSPECT, PREPARE_CREATE_PROSPECT_PREMIUM,
  SET_PREMIUM_SUBSCRIPTIONS,
  RESET_SUBSCRIPTIONS_ON_PAYMENT,
} from 'redux/actions/subscriptions';
import profile from 'api/profile';
import { BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL, BILLING_IS_PREMIUM_PURCHASE } from 'redux/actions/billings';
import { parseOrders } from 'utils/orders';
import billings from 'api/billings';
import { createCartPayloadFromDraft, createCartPayloadFromLocalCart } from '../../utils/common';

function* signupLandingContentGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { products = [] } = yield select(state => state.signup);
    if (!products?.length) {
      const {
        data: { data = [] },
      } = yield call(signup.getLandingProducts);
      if (data[0]) {
        const { title, subtitle, products: prodData } = data[0];
        yield put({
          type: GET_LANDINGPAGE_PRODUCTS._SUCCESS,
          response: { title, subtitle },
        });

        yield put({
          type: SIGNUP_PRODUCTS,
          products: prodData.data.map(p => ({
            ...p,
            variations: p.variations.data,
          })),
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > singupLandingContentGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupLandingContentGetWatch() {
  yield takeLatest(GET_LANDINGPAGE_PRODUCTS._REQUEST, signupLandingContentGet);
}

function* retrieveDraftOrderZuora() {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: { zuoraaccountnumber } = {} } = yield select(state => state.profile);
    if (zuoraaccountnumber) {
      const { data } = yield call(signup.retrieveDraftOrderZuora, { id: zuoraaccountnumber });
      yield put({ type: SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA._SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: MODAL_OPEN, id: ModalErrors.id, payload: { errorText: err?.message } });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > retrieveDraftOrderZuora', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* retrieveDraftOrderZuoraWatch() {
  yield takeLatest(SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA._REQUEST, retrieveDraftOrderZuora);
}

function* orderPreviewZuora({ payload: { items, promoCode = undefined, goToStep, isPremium = false } = {} }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: { zuoraaccountnumber } = {} } = yield select(state => state.profile);
    if (zuoraaccountnumber) {
      let body = {
        items,
        accountNumber: zuoraaccountnumber,
      };
      if (promoCode) {
        body = {
          ...body,
          promoCode,
        };
      }
      const { data } = yield call(signup.orderPreviewZuora, body);
      yield put({ type: SIGNUP_ORDER_PREVIEW_ORDER_ZUORA._SUCCESS, data });
      if (goToStep) {
        yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: goToStep });
        yield put(push(Steps[goToStep].path));
      }
      if (isPremium) {
        const { payment_methods } = yield select(state => state.billings);
        const { paymentToken } = yield select(state => state.signup);
        yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 });
        yield put({ type: BILLING_IS_PREMIUM_PURCHASE, payload: true });
        const paymentMethodDefault = payment_methods.filter(item => item.defaultPaymentMethod);
        yield put({ type: SIGNUP_SET_PAYMENT_METHOD_ID, payload: paymentToken || (paymentMethodDefault[0] ? paymentMethodDefault[0].id : payment_methods[0]?.id) });
        yield put(push(routes.billings.path));
      }
    }
  } catch (err) {
    yield put({ type: MODAL_OPEN, id: ModalErrors.id, payload: { errorText: err?.message } });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > orderPreviewZuora', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* orderPreviewZuoraWatch() {
  yield takeLatest(SIGNUP_ORDER_PREVIEW_ORDER_ZUORA._REQUEST, orderPreviewZuora);
}

function* nextStepFn(nextStep) {
  try {
    const { token } = getToken() || {};
    if (nextStep === 1 && token) {
      const { company: { zuoraaccountnumber } = {} } = yield select(state => state.profile);
      const { cart: { products = {} } = {} } = yield select(state => state.signup);
      if (zuoraaccountnumber && Object.keys(products).length) {
        const payload = createCartPayloadFromLocalCart(products);
        yield orderPreviewZuora({ payload: { items: payload, goToStep: 3 } });
      } else {
        yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 });
        yield put(push(Steps[3].path));
      }
    } else {
      yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep });
      yield put(push(Steps[nextStep].path));
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > nextStepFn', err);
  }
}

function* orderDraftSuperManager({ payload, username }) {
  try {
    yield put({ type: LOADER_OPEN });
    if (username) {
      const body = {
        ...payload,
        accountNumber: username,
      };
      yield call(signup.orderDraftSuperManager, body);
      yield put({ type: SIGNUP_ORDER_DRAFT_SUPER_MANAGER._SUCCESS });
      yield put({ type: SIGNUP_SET_HEADER_VISIBLE, response: false });
      yield put({ type: AUTHENTICATION_SIGNOUT._REQUEST, goTo: routes.confirm.path, isSuperManager: true });
    }
  } catch (err) {
    yield put({ type: MODAL_OPEN, id: ModalErrors.id, payload: { errorText: err?.message } });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > orderPreviewZuora', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* orderDraftSuperManagerWatch() {
  yield takeLatest(SIGNUP_ORDER_DRAFT_SUPER_MANAGER._REQUEST, orderDraftSuperManager);
}

function* submitOrderZuora({ paymentMethodId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: { zuoraaccountnumber } = {} } = yield select(state => state.profile);
    const { cart_zuora } = yield select(state => state.signup);
    if (zuoraaccountnumber && cart_zuora?.orderdata.length) {
      const payload = createCartPayloadFromDraft(cart_zuora?.orderdata, cart_zuora?.promocode?.promoCode);
      const body = {
        ...payload,
        paymentMethodId,
        accountNumber: zuoraaccountnumber,
      };
      yield call(signup.submitOrderZuora, body);
      yield put({ type: SIGNUP_SUBMIT_ORDER_ZUORA._SUCCESS });
      yield put({ type: AUTH_LOGIN._SUCCESS }); /* necessary for proper signup of bank users, no checks on current login state (from store.app.isLogged) because it's just dispatching on a boolean store property */
      const { selected_subscription_plan, premium_subscription_emails } = yield select(state => state.subscriptions);
      if (selected_subscription_plan && premium_subscription_emails.length > 0) {
        let attempts = 3;
        let premiumSubscriptions = [];
        while (attempts > 0) {
          yield delay(2000);
          const { data: { subscriptions: orders = [] } = {} } = yield call(subscriptions.getSubcriptionDraft, {});
          premiumSubscriptions = orders.filter(order => Number(order.variationId) === Number(selected_subscription_plan.variation_id));
          if (premiumSubscriptions.length) break;
          attempts -= 1;
        }
        if (premiumSubscriptions.length > 0) yield put({ type: PREPARE_CREATE_PROSPECT_PREMIUM._REQUEST, premiumSubscriptions, premium_subscription_emails });
        else throw new Error('Couldn\'t find premium subscription');
      } else {
        yield put({ type: PROFILE_GET_DATA_PRIVACY._REQUEST });
        yield put({ type: RESET_DATA_TREATMENT });
        yield put({ type: RESET_SUBSCRIPTIONS_ON_PAYMENT });
        yield put({ type: SIGNUP_SET_HEADER_VISIBLE, response: false });
        yield put(push(routes.confirm.path));
      }
    }
  } catch (err) {
    yield put({ type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: "C'é stato un problema nella conferma del pagamento",
        errorTitle: 'Attenzione',
      } });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > submitOrderZuora', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* submitOrderZuoraWatch() {
  yield takeLatest(SIGNUP_SUBMIT_ORDER_ZUORA._REQUEST, submitOrderZuora);
}

function* signupSetDiscount({ code }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { promoCodes } = yield select(state => state.signup);
    if (!promoCodes.includes(code)) {
      const { data, status } = yield call(signup.setDiscount, code);

      if (status.toString() === '200') {
        yield put({ type: SIGNUP_SET_DISCOUNT._SUCCESS, data, code });
      }
      yield put({ type: SIGNUP_CREATE_CART._REQUEST });
    } else {
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: {
          errorText: 'Il coupon inserito è giá presente',
          errorTitle: 'Attenzione',
        },
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: 'Il coupon inserito non è valido.' },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > signupGetDiscount', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupGetDiscountWatch() {
  yield takeLatest(SIGNUP_SET_DISCOUNT._REQUEST, signupSetDiscount);
}

function* removeDiscount({ code }) {
  try {
    yield put({ type: LOADER_OPEN });
    yield put({ type: REMOVE_PROMO._SUCCESS, code });
    yield put({ type: SIGNUP_CREATE_CART._REQUEST });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > removeDiscount', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* removeDiscountWatch() {
  yield takeLatest(REMOVE_PROMO._REQUEST, removeDiscount);
}

function* signupManagerPaymentInformation({ data }) {
  try {
    yield put({ type: LOADER_OPEN });

    const { user: { userId, sendDataTreatment } = {} } = yield select(
      state => state.authentication
    );
    const {
      agreements: { user_clauses },
    } = yield select(state => state.signup);
    const {
      privacy: { organizationType },
    } = yield select(state => state.profile);

    let res;
    if (sendDataTreatment) {
      res = yield call(signup.signupSuperManagerPaymentInfo, {
        ...data,
        persDataAcceptance: (Object.keys(user_clauses) || []).map(key => {
          const { valid, ...accept } = user_clauses[key];
          return accept;
        }),
        userId,
        organizationType,
      });
    } else {
      res = yield call(signup.signupManagerPaymentInfo, {
        ...data,
        userId,
        organizationType,
      });
    }

    const {
      status,
      data: { organizationId, organizationid, paymentid: paymentMethodId } = {},
    } = res;
    if (status?.toString() === '200') {
      yield put({ type: SIGNUP_SET_MANAGER_PAYMENT_INFO._SUCCESS, response: { organizationId: organizationId || organizationid, paymentMethodId } });
      yield put({ type: PROFILE_DATA_COMPANY_GET._REQUEST, orderPreview: true });
    }
  } catch (err) {
    if (err?.response?.data?.errorManagement === 'Organization already exists') {
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: { errorText: 'Azienda già registrata su Skills4Capital' },
      });
      // eslint-disable-next-line no-console
      console.log('sagas > signup > signupManagerPaymentInformation', err);
    } else {
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: { errorText: err?.message },
      });
      // eslint-disable-next-line no-console
      console.log('sagas > signup > signupManagerPaymentInformation', err);
    }
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupManagerPaymentInformationWatch() {
  yield takeLatest(
    SIGNUP_SET_MANAGER_PAYMENT_INFO._REQUEST,
    signupManagerPaymentInformation
  );
}

function* signupStep3() {
  try {
    yield put({ type: LOADER_OPEN });
    const { account, otpcode } = yield select(state => state.signup);
    yield put({ type: SIGNUP_CONFIRM_USER._REQUEST });
    const confirmUserRes = yield call(signup.confirmUser, {
      otp: otpcode,
      username: account.email,
    });
    if (confirmUserRes) {
      yield put({ type: SIGNUP_CONFIRM_USER._SUCCESS });
      const res = yield call(authentication.authenticationSignin, {
        username: account.email,
        passwordUser: account.pwd,
        channel,
      });

      const {
        data: {
          username,
          givenname,
          familyname,
          email,
          role,
          pictureid,
          organizationid,
          prospectid,
          token,
          accesstoken,
          refreshtoken,
          expiresin,
          clientenabled,
          domainlist,
          senddatatreatment,
        } = {},
      } = res;
      if (token) {
        const { rememberMe } = yield select(state => state.landingPage);
        setToken(
          {
            token,
            accesstoken,
            refreshtoken,
            expiresin,
            userid: username,
          },
          !!rememberMe
        );

        yield put({
          type: AUTHENTICATION_SIGNIN._SUCCESS,
          user: {
            userId: username,
            firstname: givenname,
            lastname: familyname,
            email,
            role,
            pictureId: pictureid,
            organizationId: organizationid,
            prospectId: prospectid,
            clientEnabled: clientenabled,
            domainList: domainlist,
            sendDataTreatment: senddatatreatment,
          },
        });

        yield put({ type: AUTH_LOGIN._SUCCESS });

        if (!clientenabled) {
          const { id } = yield select(state => state.domain);
          const { userId } = yield select(state => state.authentication.user);
          const store = getToken();

          const {
            data: {
              token: tokenFromMdw,
              accesstoken: accesstokenFromMdw,
              refreshtoken: refreshtokenFromMdw,
              clientenabled: clientenabledFromMdw,
              expiresin: expiresinFromMdw,
            },
          } = yield call(authentication.getDomain, {
            userId,
            refreshToken: store.refreshtoken,
            accessToken: store.accesstoken,
            channel,
            domainId: id,
          });
          setToken(
            {
              token: tokenFromMdw,
              accesstoken: accesstokenFromMdw,
              refreshtoken: refreshtokenFromMdw,
              expiresin: expiresinFromMdw,
              userid: userId,
            },
            !!rememberMe
          );

          yield put({
            type: PROFILE_DATA_ACCOUNT_GET._SUCCESS,
            response: { clientEnabled: clientenabledFromMdw },
          });
        }
        const { cart: { products = {} } = {} } = yield select(state => state.signup);
        products && Object.keys(products).length > 0 ? yield nextStepFn(3) : yield nextStepFn(0);
      }
    }
  } catch (err) {
    yield put({ type: SIGNUP_CONFIRM_USER._ERROR });
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: 'Codice OTP non valido' },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > signupStep2', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupStep4(data) {
  try {
    yield put({ type: LOADER_OPEN });
    yield signupManagerPaymentInformation({ data });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > signupStep4', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupNextStep({ nextStep, data }) {
  try {
    yield put({ type: LOADER_OPEN });

    switch (nextStep) {
      case 3:
        yield signupStep3(data);
        break;
      case 4:
        yield signupStep4(data);
        break;

      default:
        yield nextStepFn(nextStep);
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > signupNextStep', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupNextStepWatch() {
  yield takeLatest(SIGNUP_NEXT_STEP._REQUEST, signupNextStep);
}

function* signupCreateCart({ goToStep = undefined, toConfirm = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { cart: { products: cart_products }, promoCodes, paymentToken } = yield select(state => state.signup);

    const { payment_methods } = yield select(state => state.billings);

    const cartPrepared = {
      product_variations: [],
    };

    if (paymentToken) {
      if (paymentToken === 'confirmed') {
        const payment = payment_methods[payment_methods.length - 1];
        if (payment) {
          cartPrepared.payment_method_uuid = payment.uuid;
        }
      } else {
        cartPrepared.payment_method_uuid = paymentToken;
      }
    }
    if (promoCodes.length > 0) cartPrepared.coupon_codes = promoCodes;

    Object.keys(cart_products).forEach(item => {
      if (cart_products[item].num > 0) {
        const newCartProducts = {
          sku: cart_products[item].sku,
          qty: cart_products[item].num,
        };
        cartPrepared.product_variations.push(newCartProducts);
      }
    });
    if (cartPrepared.product_variations.length) {
      const { user: { userId } = {} } = yield select(
        state => state.authentication
      );
      const { data, status } = yield call(signup.signupCreateCart, {
        cartPrepared,
        userId,
      });
      if (
        status?.toString() === '201'
        || status?.toString() === '202'
        || status?.toString() === '200'
      ) {
        if (data.error) {
          console.log('Errore nella creazione del carrello:', data.error);
          yield put({
            type: MODAL_OPEN,
            id: ModalErrors.id,
            payload: {
              errorText: 'Errore nella creazione del carrello',
              errorTitle: 'Attenzione',
            },
          });
        } else {
          const postProducts = {};
          (data?.products || []).forEach(p => {
            postProducts[p.sku] = {
              title: p.label,
              num: p.qty,
              unit_price: p.unit_price,
              product_price: p.price,
              ...p,
            };
          });
          const cartParsed = { ...data, products: postProducts };
          yield put({
            type: SIGNUP_CREATE_CART._SUCCESS,
            cart: cartParsed,
          });
          if (data.order_nid) {
            yield put({
              type: BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL._REQUEST,
              orderId: data.order_nid,
            });
          }

          if (goToStep) yield nextStepFn(goToStep);
          if (toConfirm) yield put({ type: SIGNUP_CART_CONFIRM._REQUEST });
        }
      }
    } else if (goToStep) {
      yield nextStepFn(0);
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore nella creazione del carrello',
        errorTitle: 'Attenzione',
      },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > signupCreateCart', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupCreateCartWatch() {
  yield takeLatest(SIGNUP_CREATE_CART._REQUEST, signupCreateCart);
}

function* signupLatestCart() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const response = yield call(billings.getResourceCommerceOrder, {
      userId,
      state: 'draft',
    });

    const { data: { data: orders = [] } = {} } = response;

    const draftOrder = orders[orders.length - 1];

    if (draftOrder) {
      const parsedProducts = {};
      const parsedDraftOrder = parseOrders([draftOrder])[0];
      parsedDraftOrder.order_items.forEach(p => {
        parsedProducts[p.sku] = p;
      });

      yield put({
        type: SIGNUP_GET_LATEST_CART._SUCCESS,
        cart: {
          order_id: parsedDraftOrder.id,
          products: parsedProducts,
          total_price: parsedDraftOrder.total_price_number,
          subtotal_price: parsedDraftOrder.subtotal_price_number,
          tax_amount: parsedDraftOrder.tax_amount,
          tax_percentage: parsedDraftOrder.tax_amount,
        },
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > signupLatestCart', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupLatestCartWatch() {
  yield takeLatest(SIGNUP_GET_LATEST_CART._REQUEST, signupLatestCart);
}

function* signupManagerInformation() {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      account: { firstName, lastName, pwd: passwordUser, email } = {},
      agreements: { user_clauses, acceptedClauses, userType },
    } = yield select(state => state.signup);
    const { id: domainId } = yield select(state => state.domain);

    const body = {
      firstName,
      lastName,
      passwordUser,
      email,
      domainId,
      organizationType: userType,
      persDataAcceptance: (Object.keys(user_clauses) || [])
        .filter(key => acceptedClauses.includes(key))
        .map(key => {
          const { valid, ...accept } = user_clauses[key];
          return accept;
        }),
    };
    const res = yield call(signup.signupManagerInfo, body);
    const { data: { status = '' } = {}, code } = res;
    if (status?.toString() === '300' || code?.toString() === '300') {
      yield put({ type: SIGNUP_NEXT_STEP._REQUEST, nextStep: 2 });

      yield put(push(routes.confirmOtp.path));
    }
  } catch (err) {
    if (err?.message?.indexOf('300') > -1) {
      yield put({ type: SIGNUP_NEXT_STEP._REQUEST, nextStep: 2 });

      yield put(push(routes.confirmOtp.path));
    } else {
      const payload = {};
      if (err?.message?.indexOf('400') > -1) {
        payload.errorTitle = 'Attenzione, mail già esistente.';
        payload.errorText = 'Inserisci una mail valida per poter proseguire con la registrazione';
      }
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload,
      });
    }

    // eslint-disable-next-line no-console
    //console.log('sagas > signup > signupManagerInfo', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupManagerInformationWatch() {
  yield takeLatest(SIGNUP_SET_MANAGER_INFO._REQUEST, signupManagerInformation);
}

function* sectorGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { sector } = yield select(state => state.signup);
    if (!sector.length) {
      const { data = [] } = yield call(signup.getSector);
      yield put({ type: SECTOR_GET._SUCCESS, data });
    } else {
      yield put({ type: SECTOR_GET._SUCCESS, data: sector });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > sectorGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* sectorGetWatch() {
  yield takeLatest(SECTOR_GET._REQUEST, sectorGet);
}

function* restartSignupFlow() {
  try {
    yield put({ type: LOADER_OPEN });
    yield put(push(Steps[0].path));
    yield put({
      type: SIGNUP_FIRST_STEP._SUCCESS,
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > restartSignupFlow', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* restartSignupFlowWatch() {
  yield takeLatest(SIGNUP_FIRST_STEP._REQUEST, restartSignupFlow);
}

function* resendConfirmCode() {
  try {
    yield put({ type: LOADER_OPEN });
    const { account } = yield select(state => state.signup);
    const { id: domainId } = yield select(state => state.domain);

    if (!account.email) {
      const { userid } = getToken();
      const {
        data: { email },
        status,
      } = yield call(profile.getProfileDataAccount, userid);
      if (status?.toString() === '200') {
        yield put({ type: PROFILE_DATA_ACCOUNT_GET._SUCCESS });
        account.email = email;
      }
    }

    const res = yield call(signup.resendConfimCode, {
      username: account.email,
      domainId,
    });
    if (res.code === 200 || res.status?.toString() === '200') {
      yield put({ type: SIGNUP_RESEND_CODE._SUCCESS });
      yield put({ type: MODAL_OPEN, id: ResendOTPModal.id });
    } else {
      yield put({ type: SIGNUP_RESEND_CODE._ERROR });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: 'Codice OTP non valido' },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > signupConfirmUser', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* resendConfirmCodeWatch() {
  yield takeLatest(SIGNUP_RESEND_CODE._REQUEST, resendConfirmCode);
}

function* cartConfirm() {
  try {
    yield put({ type: LOADER_OPEN });
    const { cart } = yield select(state => state.signup);
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );

    const res = yield call(signup.signupConfirmCart, {
      uuid: cart.order_uuid,
      userId,
    });
    if (res.status?.toString() === '202' || res.status?.toString() === '200') {
      yield put({ type: SIGNUP_CART_CONFIRM._SUCCESS });

      const { premiumSubscriptions } = yield select(
        state => state.subscriptions
      );
      const { isPremiumPurchase } = yield select(state => state.billings);
      if (isPremiumPurchase) {
        if (premiumSubscriptions?.length) {
          const {
            cart: { order_nid },
          } = yield select(state => state.signup);
          const resCommerceOrder = yield call(
            billings.getResourceCommerceOrder,
            {
              orderId: order_nid,
            }
          );

          const {
            data: { data },
          } = resCommerceOrder;

          const orders = parseOrders(data);

          const body = premiumSubscriptions.map(ps => ({
            ...ps,
            variations: ps.variations.map(psv => {
              const orderItem = orders[0].order_items.find(
                oi => oi.variation_id.toString() === psv.variationId.toString()
              );
              return {
                ...psv,
                subscriptionId: orderItem.order_item_id,
                startDate: orderItem?.installments
                  ? orderItem?.installments.find(dd => dd.is_first === 1)
                    .period_start
                  : '',
                endDate: orderItem?.installments
                  ? orderItem?.installments.find(dd => dd.is_last === 1)
                    .period_end
                  : '',
              };
            }),
          }));
          yield put({ type: SET_PREMIUM_SUBSCRIPTIONS, subscriptions: [] });
          yield put({
            type: CREATE_PROSPECT._REQUEST,
            payload: { body },
          });
          yield put({
            type: MODAL_OPEN,
            id: PremiumPurchaseModal.id,
          });
        } else {
          yield put({ type: SIGNUP_SET_HEADER_VISIBLE, response: false });
          yield put(push(routes.confirm.path));
        }
      } else {
        yield put({ type: SIGNUP_RESET_CART });
      }
    } else {
      yield put({ type: SIGNUP_CART_CONFIRM._ERROR });
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: {
          errorText: "C'é stato un problema nella conferma del pagamento",
          errorTitle: 'Attenzione',
        },
      });
    }
  } catch (err) {
    yield put({ type: SIGNUP_CART_CONFIRM._ERROR });
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: "C'é stato un problema nella conferma del pagamento",
        errorTitle: 'Attenzione',
      },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > cartConfirm', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* cartConfirmWatch() {
  yield takeLatest(SIGNUP_CART_CONFIRM._REQUEST, cartConfirm);
}

function* superManagerCreate() {
  try {
    yield put({ type: LOADER_OPEN });

    const { account: { firstName, lastName, section, superManagerCode, email } } = yield select(state => state.signup);
    const { id: domainId } = yield select(state => state.domain);

    const { data } = yield call(signup.signupSuperManagerInfo, {
      firstName,
      lastName,
      section,
      superManagerCode,
      email,
      domainId,
    });

    if (data) {
      const {
        username,
        token,
        accesstoken,
        refreshtoken,
        expiresin,
      } = data;

      setToken({ token, accesstoken, refreshtoken, expiresin, userid: username }, false, true);
      yield put({ type: SIGNUP_SET_SUPER_MANAGER_INFO._SUCCESS, user: data });
      const { cart: { products: cart_products } } = yield select(state => state.signup);
      const payload = createCartPayloadFromLocalCart(cart_products);
      yield put({ type: SIGNUP_ORDER_DRAFT_SUPER_MANAGER._REQUEST, payload: { items: payload }, username });
    }
  } catch (err) {
    yield put({ type: SIGNUP_SET_SUPER_MANAGER_INFO._ERROR });
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText:
          'la mail inserita risulta essere già in uso, inserirne una valida',
        errorTitle: 'Mail già esistente',
      },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > signup > superManagerCreate', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* superManagerCreateWatch() {
  yield takeLatest(SIGNUP_SET_SUPER_MANAGER_INFO._REQUEST, superManagerCreate);
}

export default [
  signupLandingContentGetWatch(),
  signupNextStepWatch(),
  signupCreateCartWatch(),
  signupLatestCartWatch(),
  signupManagerInformationWatch(),
  signupManagerPaymentInformationWatch(),
  sectorGetWatch(),
  restartSignupFlowWatch(),
  signupGetDiscountWatch(),
  removeDiscountWatch(),
  resendConfirmCodeWatch(),
  cartConfirmWatch(),
  superManagerCreateWatch(),
  orderPreviewZuoraWatch(),
  orderDraftSuperManagerWatch(),
  retrieveDraftOrderZuoraWatch(),
  submitOrderZuoraWatch(),
];
