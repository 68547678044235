import styled from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-width: calc(100vw - 9px);
  overflow-x: hidden;
  overflow-y: auto;
  ${customScrollbar()}
`;

export const Head = styled.div`
  width: 420px;
  max-width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionContainer = styled.div`
  margin-bottom: 20px;
`;

export const Texts = styled.div``;

export const Title = styled.div`
  padding-bottom: 8px;
`;

export const Subtitle = styled.div``;

export const Icon = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const BarContainer = styled.div`
  width: 420px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 030px;

  > div:first-child {
    width: 90%;
  }

  @media ${props => props.theme.device.mobile} {
    > div:first-child {
      width: 80%;
    }
  }
`;

export const Form = styled.div`
  width: 420px;
  max-width: 100%;
  min-height: 280px;
`;

export const KnobContainer = styled.div`
  width: 100%;
  height: 48px;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

export const Knob = styled.div`
  width: calc(
    (100% - ((${props => props.maxValue} - 1) * 8px)) /
      ${props => props.maxValue}
  );
  overflow: hidden;
  height: 0;
  padding-top: calc(
    (100% - ((${props => props.maxValue} - 1) * 8px)) /
      ${props => props.maxValue} - 2px
  );
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.primary.base};
  cursor: pointer;

  &.selected {
    background-color: ${props => props.theme.colors.primary.base};
  }
`;

export const Spacer = styled.div`
  width: 8px;
`;

export const Labels = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const TextArea = styled.input`
  width: 100%;
  height: 113px;
  margin: 10px 48px 0 0;
  padding: 16px 10px 73px 20px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white};
`;

export const Bottom = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
`;

export const BtnContainer = styled.div`
  width: 120px;
  button{
    max-width: 70px;
  }
`;

export const LikeWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconLike = styled.div`
  font-size: 60px;
  padding-right: 37px;
  color: ${props => props.theme.colors.lightSilver};
  cursor: pointer;

  &.active {
    color: ${props => props.theme.colors.primary.base};
  }
`;

export const IconDislike = styled(IconLike)`
  border-right: 1px solid ${props => props.theme.colors.lightSilver};
  transform: rotate(180deg);
`;
export const CheckboxWrapper = styled.div`
  margin-top: 15px;
`;

export const CommentWrapper = styled.div`
  margin-top: 15px;
`;
