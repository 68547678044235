import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Row, Col } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import { SubTitle } from 'pages/Signup/steps/UserAccount/style';
import {
  BtnPromoContainer,
  PromoCodeContainer
} from 'pages/Signup/steps/Billings/style';
import { Button, CustomInput } from 'ui/atoms';
import {
  SIGNUP_ORDER_PREVIEW_ORDER_ZUORA
} from 'redux/actions/signup';
import { priceTotalFormat } from 'utils/price-format';
import {
  ResumeContainer,
  Headings,
  QuantityHeading,
  ResumeDataContainer,
  TotalContainer,
  IvaContainer,
  ResumeFooter,
  ResumePrice,
  ResumeOffer,
  ResumeTipology,
  ResumeQuantity,
  ResumeCost,
  DiscountWrapper,
  DiscountContainer,
  Icon,
  CodeWrapper,
  InputWrapper,
  ColDiscount
} from './style';
import { createCartPayloadFromDraft } from '../../../utils/common';

const SummaryTable = ({
  cart_zuora,
  mediaIsPhone,
  showDiscountCode,
  setDiscount,
  removePromo,
  simpler
}) => {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [orderdata, setOrderDataZuora] = useState([]);
  const [promocode, setPromoCodeZuora] = useState({});

  useEffect(() => {
    setOrderDataZuora(cart_zuora?.orderdata || []);
    setPromoCodeZuora(cart_zuora?.promocode || {});
  }, [cart_zuora]);

  useEffect(() => {
    setList(orderdata[0]?.orderSubscriptions || []);
    setTotal(orderdata[0]?.priceWithoutTax || 0);
  }, [orderdata]);

  const handleDiscount = () => {
    const payload = createCartPayloadFromDraft(cart_zuora?.orderdata, promoCode);
    setDiscount(payload);
  };

  const handleRemoveDiscount = () => {
    const payload = createCartPayloadFromDraft(cart_zuora?.orderdata);
    removePromo(payload);
  };

  // const getDiscountLabel = () => {
  //   const discount = list.reduce((acc, val) => {
  //     if(val.discount){
  //       return acc + val.discount.amountPerSubscription;
  //     }
  //     return acc;
  //   }, 0);
  //
  //   return `${priceFormat(parseFloat(discount).toFixed(2))}€`;
  // };

  return (
    <>
      {showDiscountCode && (
        <DiscountWrapper>
          <SubTitle>
            <ProText type="description" configuration={{ fontWeight: 600 }}>
              Hai un codice sconto?
            </ProText>
          </SubTitle>
          <PromoCodeContainer>
            <Row>
              <Col md={6} sm={12}>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Codice Sconto"
                    value={promoCode}
                    disabled={Object.keys(promocode).length > 0}
                    onChange={val => setPromoCode(val)}
                  />
                </InputWrapper>
              </Col>
              <Col md={6} sm={12}>
                <BtnPromoContainer>
                  <Button
                    text="Inserisci"
                    type={Button.TYPE.TERTIARY}
                    onClick={handleDiscount}
                  />
                </BtnPromoContainer>
              </Col>
            </Row>
          </PromoCodeContainer>
        </DiscountWrapper>
      )}

      <SubTitle>
        <ProText type="description" configuration={{ fontWeight: 600 }}>
          Riepilogo
        </ProText>
      </SubTitle>

      <ResumeContainer>
        <Headings>
          <Row>
            <Col md={4} sm={4}>
              <ProText type="resumeTime">Offerta formativa</ProText>
            </Col>
            {!mediaIsPhone && (
              <Col md={3} sm={3}>
                <ProText type="resumeTime">Tipologia</ProText>
              </Col>
            )}

            <Col md={2} sm={4}>
              <QuantityHeading>
                <ProText type="resumeTime"> Quantità</ProText>
              </QuantityHeading>
            </Col>
            <Col md={3} sm={4}>
              <QuantityHeading
                style={!mediaIsPhone ? { textAlign: 'center' } : null}
              >
                <ProText
                  type="resumeTime"
                  style={!mediaIsPhone ? { textAlign: 'center' } : null}
                >
                  {' '}
                  Costo
                </ProText>
              </QuantityHeading>
            </Col>
          </Row>
        </Headings>

        <ResumeDataContainer>
          {list.map(
            (data, index) =>
              data.quantity > 0 && (
                <Row key={`${data.productName}-${index}`}>
                  <Col md={4} sm={12}>
                    <ResumeOffer>
                      <ProText type="resumeLabel">{data.productName}</ProText>
                    </ResumeOffer>
                  </Col>
                  {mediaIsPhone && <Col sm={4} />}
                  {!mediaIsPhone && (
                    <Col md={3} sm={3}>
                      <ResumeTipology>
                        <ProText type="resumeLabel">
                          {data?.productChargeName}
                        </ProText>
                      </ResumeTipology>
                    </Col>
                  )}

                  <Col md={2} sm={4}>
                    <ResumeQuantity>
                      <ProText type="resumeLabel">{data?.quantity}</ProText>
                    </ResumeQuantity>
                  </Col>
                  <Col md={3} sm={4}>
                    <ResumeCost isDiscounted={data?.discount && data?.discount.amountWithoutTaxPerSubscription > 0}>
                      <ProText type="resumeLabel">
                        {priceTotalFormat(data?.priceWithoutDiscount?.amountWithoutTaxPerSubscription || 0)} €
                      </ProText>
                      {data?.discount && data?.discount.amountWithoutTaxPerSubscription > 0 && (
                      <ProText type="resumeLabel">{priceTotalFormat(data?.adjustedPrice?.amountWithoutTaxPerSubscription || 0)} €</ProText>)}
                    </ResumeCost>
                  </Col>
                </Row>
              )
          )}
        </ResumeDataContainer>

        {!simpler && (
          <>
            <TotalContainer>
              <Row>
                <Col md={10} sm={8}>
                  <ProText
                    type="resumeLabel"
                    configuration={{ fontWeight: 'bold' }}
                  >
                    Tot. Offerta formativa
                  </ProText>
                </Col>
                <Col md={2} sm={4}>
                  <ResumePrice>
                    <ProText
                      type="resumeLabel"
                      configuration={{ fontWeight: 'bold' }}
                    >
                      {priceTotalFormat(parseFloat(total))}€
                    </ProText>
                  </ResumePrice>
                </Col>
              </Row>
            </TotalContainer>

            {Object.keys(promocode).length > 0
            && (
            <DiscountContainer>
              <ColDiscount className="label">
                <ProText
                  type="resumeLabel"
                  configuration={{ color: 'mineShaft' }}
                  className="space"
                >
                  Codice sconto:{' '}
                </ProText>
              </ColDiscount>
              <ColDiscount>
                <CodeWrapper>
                  <ProText
                    type="resumeLabel"
                    configuration={{
                      color: 'mineShaft',
                      fontWeight: 'bold'
                    }}
                  >
                    {promocode.promoCode}
                  </ProText>
                  {/*{Boolean(discounts[promoCode]) && (*/}
                  {/*  <TooltipWrapper>*/}
                  {/*    <Tooltip*/}
                  {/*      text={*/}
                  {/*        discounts[promoCode]?.description ||*/}
                  {/*        discounts[promoCode]?.name ||*/}
                  {/*        ''*/}
                  {/*      }*/}
                  {/*    >*/}
                  {/*      <IconInfo className="icon-icona-info-active"/>*/}
                  {/*    </Tooltip>*/}
                  {/*  </TooltipWrapper>*/}
                  {/*)}*/}
                  <Icon
                    className="icon-close-x"
                    onClick={handleRemoveDiscount}
                  />
                </CodeWrapper>
              </ColDiscount>
              {/*<ColDiscount className="resume">*/}
              {/*  <ResumeCost>*/}
              {/*    <ProText*/}
              {/*      type="resumeLabel"*/}
              {/*      configuration={{ color: 'primary', colorType: 'base' }}*/}
              {/*    >*/}
              {/*      {getDiscountLabel()}*/}
              {/*    </ProText>*/}
              {/*  </ResumeCost>*/}
              {/*</ColDiscount>*/}
              {!promocode.validPromoCode && (
                <ColDiscount className="error-msg">
                  <ProText
                    type="resumeLabel"
                    configuration={{ color: 'redError' }}
                  >
                    Questo coupon non è cumulabile.
                  </ProText>
                </ColDiscount>
              )}
            </DiscountContainer>
            )}
            <IvaContainer>
              <Row>
                <Col md={10} sm={8}>
                  <ProText type="resumeLabel">{`IVA ${orderdata[0]?.taxPercentage} %`}</ProText>
                </Col>
                <Col md={2} sm={4}>
                  <ResumePrice>
                    <ProText type="resumeLabel">
                      {priceTotalFormat(parseFloat(orderdata[0]?.taxAmount || 0))} €
                    </ProText>
                  </ResumePrice>
                </Col>
              </Row>
            </IvaContainer>
          </>
        )}

        <ResumeFooter>
          <Row>
            <Col md={10} sm={8}>
              <ProText
                type="section"
                configuration={{ textTransform: 'uppercase' }}
              >
                Totale
              </ProText>
            </Col>
            <Col md={2} sm={4}>
              <ResumePrice>
                <ProText type="section">
                  {orderdata[0] ? priceTotalFormat(parseFloat(orderdata[0]?.totalPrice)) : 0}€
                </ProText>
              </ResumePrice>
            </Col>
          </Row>
        </ResumeFooter>
      </ResumeContainer>
    </>
  );
};

SummaryTable.propTypes = {
  showDiscountCode: PropTypes.bool,
  //HOC (connect, state)
  cart_zuora: PropTypes.object,

  //HOC (connect, dispatch)
  mediaIsPhone: PropTypes.bool.isRequired,
  setDiscount: PropTypes.func,
  removePromo: PropTypes.func,
  simpler: PropTypes.bool
};

export default withMediaQueries(
  connect(
    state => {
      const { cart_zuora } = state.signup;
      return { cart_zuora };
    },
    dispatch => ({
      setDiscount: payload => dispatch({ type: SIGNUP_ORDER_PREVIEW_ORDER_ZUORA._REQUEST, payload }),
      removePromo: payload => dispatch({ type: SIGNUP_ORDER_PREVIEW_ORDER_ZUORA._REQUEST, payload })
    })
  )(SummaryTable)
);
