import {
  getPlaylistMock,
  getPlaylistsMock,
  getCourseMock,
  shouldUseMock,
  getRelatedMock,
  addSurveyMock,
  getQuestionsSurveyMock,
  addReviewMock,
  setRecommendedMock,
  getKeepWatchingMock,
  getPackagesMock,
  getPackageMock,
  getEventsMock,
  getAllPlannedEventsMock,
  getEventMock,
  getSelectionsMock,
  getCollectionsMock,
  getEventSessionsMock,
  getEventLockedMock,
  shareCommentMock,
  categoriesMock,
  categoriesPillsMock,
  categoryPlaylistMock
} from './mock';
import {
  apiCall,
  baseUrl,
  extendUrl,
  catalogPrefix,
  assetPrefix,
  calendarprefix,
  newsFeedPrefix
} from './config';

export default {
  pathSelection: userId => {
    if (shouldUseMock()) {
      return getPlaylistsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}pathselection?userId=${userId}`,
      'GET'
    );
  },
  getPlaylist: ({ id, userId, locked }) => {
    if (shouldUseMock()) {
      return getPlaylistMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/detail?itemId=${id}&locked=${locked}&htmlEnabled=true`,
      'GET'
    );
  },
  getCourse: ({ itemId, userId }) => {
    if (shouldUseMock()) {
      return getCourseMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/detail/course?itemId=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getRelated: ({ userId, needId, itemId }) => {
    if (shouldUseMock()) {
      return getRelatedMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}relatedlos?needId=${needId}&excludeLo=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getQuestions: surveyId => {
    if (shouldUseMock()) {
      return getQuestionsSurveyMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}getsurvey/${surveyId}?htmlEnabled=true`,
      'GET'
    );
  },
  addSurvey: (playlistId, surveyId, body) => {
    if (shouldUseMock()) {
      return addSurveyMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}addsurvey/${playlistId}/${surveyId}`,
      'POST',
      body
    );
  },
  addReview: body => {
    if (shouldUseMock()) {
      return addReviewMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}review`,
      'POST',
      body
    );
  },
  setRecommended: body => {
    if (shouldUseMock()) {
      return setRecommendedMock();
    }
    return apiCall(
      `${assetPrefix}${baseUrl}${extendUrl}reccomendation`,
      'POST',
      body
    );
  },
  getKeepWatching: ({ userId }) => {
    if (shouldUseMock()) {
      return getKeepWatchingMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}lo/getallinprogress?htmlEnabled=true`,
      'GET'
    );
  },
  getPackages: () => {
    if (shouldUseMock()) {
      return getPackagesMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}packages`,
      'GET'
    );
  },
  getPackage: ({ itemId, userId }) => {
    if (shouldUseMock()) {
      return getPackageMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}packages/${itemId}`,
      'GET'
    );
  },
  getEventsByDate: ({ startDate, endDate }) => {
    if (shouldUseMock()) {
      return getEventsMock();
    }
    return apiCall(
      /* date format: YYYY-MM-DD */
      `${catalogPrefix}${baseUrl}${extendUrl}live_event/planned?start_date=${startDate}&end_date=${endDate}&htmlEnabled=true`,
      'GET'
    );
  },
  getAllPlannedEvents: ({ userId, startDate }) => {
    if (shouldUseMock()) {
      return getAllPlannedEventsMock();
    }
    return apiCall(
      /* date format: YYYY-MM-DD */
      `${catalogPrefix}${baseUrl}${extendUrl}live_event/planned/byStartDate?start_date=${startDate}&htmlEnabled=true`,
      'GET'
    );
  },
  getEventsLocked: ({ userId, startDate }) => {
    if (shouldUseMock()) {
      return getEventsMock();
    }
    return apiCall(
      /* date format: YYYY-MM-DD */
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/learning?filterBy=CALENDAR_CHOICE&filterValue=finger`,
      'GET'
    );
  },
  getCategories: ({ userId, filterId }) => {
    if (shouldUseMock()) {
      return categoriesMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/learning?filterBy=findByNeedId&filterValue=${filterId}`,
      'GET'
    );
  },
  getCategoriesPills: () => {
    if (shouldUseMock()) {
      return categoriesPillsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}needs`,
      'GET'
    );
  },
  getCategoryPlaylist: ({ userId, filterId }) => {
    if (shouldUseMock()) {
      return categoryPlaylistMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/learning?filterBy=NEED&filterValue=${filterId}`,
      'GET'
    );
  },
  getEvent: ({ userId, itemId }) => {
    if (shouldUseMock()) {
      return getEventMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}live_event?itemId=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getEventLocked: ({ itemId, productId }) => {
    if (shouldUseMock()) {
      return getEventLockedMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}finger_learning?&item_id=${itemId}&product_id=${productId}`,
      'GET'
    );
  },
  getEventSessions: ({ itemId, itemsPerPage }) => {
    if (shouldUseMock()) {
      return getEventSessionsMock();
    }
    return apiCall(
      `${calendarprefix}${baseUrl}${extendUrl}booking/check/liveEvent/${itemId}?items=${itemsPerPage}`,
      'GET'
    );
  },
  getSelections: ({ itemsPerPage, nextPage, userId, appName }) => {
    if (shouldUseMock()) {
      return getSelectionsMock();
    }
    let paginationParams = '';
    paginationParams += itemsPerPage ? `&items=${itemsPerPage}` : '';
    paginationParams += nextPage ? `&next_page=${nextPage}` : '';
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}user_selections?${paginationParams}`,
      'GET'
    );
  },
  getCollections: ({
    assetFilter, // ALL, BY_JOB (suggested), CROSS_CONTENT (formazione trasversale)
    itemsPerPage,
    nextPage,
    userId
  }) => {
    if (shouldUseMock()) {
      return getCollectionsMock(assetFilter);
    }
    let paginationParams = '';
    paginationParams += itemsPerPage ? `&items=${itemsPerPage}` : '';
    paginationParams += nextPage ? `&next_page=${nextPage}` : '';
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}user_collections?asset_filter=${assetFilter}${paginationParams}`,
      'GET'
    );
  },

  shareComment: body => {
    if (shouldUseMock()) {
      return shareCommentMock();
    }
    return apiCall(
      `${newsFeedPrefix}${baseUrl}${extendUrl}newsfeed/share`,
      'POST',
      body
    );
  },
};
