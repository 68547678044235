import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
  max-width: 177px;
  min-width: 177px;
  display: flex;
  align-items: center;
  margin: 30px 0 30px auto;

  @media ${props => props.theme.device.tablet} {
    max-width: 233px;
  }
  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
    margin-bottom: 15px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const PasswordLegend = styled.div`
  margin: 8px 0 0px;
  color: ${props =>
    (props.error ? props.theme.colors.redError : props.theme.colors.placeholder)};
`;

export const SubTitle = styled.div`
  margin: 31px 0 23px;

  &.no-margin-bottom {
    margin-bottom: 0;
  }
`;

export const TitleBackWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleContainer = styled.div`
  margin-bottom: 19px;
`;

export const Title = styled.div`
  margin-top: 60px;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.primary.base};

  @media ${props => props.theme.device.mobile} {
    margin-top: 32px;
  }
`;
