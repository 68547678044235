/* eslint-disable */
const CF_CONVERSION_CHARS = {
  EVEN: {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9
  },
  ODD: {
    A: 1,
    B: 0,
    C: 5,
    D: 7,
    E: 9,
    F: 13,
    G: 15,
    H: 17,
    I: 19,
    J: 21,
    K: 2,
    L: 4,
    M: 18,
    N: 20,
    O: 11,
    P: 3,
    Q: 6,
    R: 8,
    S: 12,
    T: 14,
    U: 16,
    V: 10,
    W: 22,
    X: 25,
    Y: 24,
    Z: 23,
    0: 1,
    1: 0,
    2: 5,
    3: 7,
    4: 9,
    5: 13,
    6: 15,
    7: 17,
    8: 19,
    9: 21
  }
};

const CF_CONTROL_CHAR = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
  19: 'T',
  20: 'U',
  21: 'V',
  22: 'W',
  23: 'X',
  24: 'Y',
  25: 'Z'
};

const validator = {
  email: (data = '') =>
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi.test(
      data
    ),
  number: (data = '') => /^\d+$/g.test(data),
  string: (data = '') =>
    /^[a-zA-Z0-9!@#$£€%^&*()_+\-=\[\]{};':"\\|,.<>°§\/?èéòàìùÀÉÈÌÒÙç ]{1,}$/.test(
      data
    ),
  null: (data = '') => {
    return data === 'null';
  },
  address: (data = '') =>
    /^[a-zA-Z0-9_\/.,èéòàìù'](?:[a-zA-Z0-9_\/.,'\sèéòàìù\-]+)?$/gi.test(data),
  alphaNumeric: (data = '') => /^[a-z A-Z 0-9_.-]*$/gi.test(data),
  atecoCode: (data = '') => /^[0-9]{2,6}$/gm.test(data),
  atecoDescription: (data = '') =>
    /^[a-zA-Z0-9!@#$£€%^&*()_+\-=\[\]{};':"\\|,.<>°§\/?èéòàìùÀÉÈÌÒÙç ]{1,}$/gm.test(
      data
    ),
  fiscalCode: (data = '') => {
    let valid = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/i.test(data);

    if (valid) {
      let controlValue = data
        .split('')
        .slice(0, data.length - 1)
        .reduce((acc, c, i) => {
          return (acc +=
            CF_CONVERSION_CHARS[(i + 1) % 2 === 0 ? 'EVEN' : 'ODD'][
              c.toUpperCase()
            ]);
        }, 0);
      controlValue = controlValue % 26;

      valid =
        valid &&
        CF_CONTROL_CHAR[controlValue] === data[data.length - 1].toUpperCase();
    }

    return valid;
  },
  piva: (data = '') => {
    const ord = string => {
      let str = string + '';
      let code = str.charCodeAt(0);
      if (code >= 0xd800 && code <= 0xdbff) {
        let hi = code;
        if (str.length === 1) {
          return code;
        }
        let low = str.charCodeAt(1);
        return (hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000;
      }
      if (code >= 0xdc00 && code <= 0xdfff) {
        return code;
      }
      return code;
    };

    if (data.length < 11) {
      return false;
    }

    if (data.length > 11) {
      return 'La lunghezza della partita IVA non è corretta: la partita IVA dovrebbe essere lunga esattamente 11 caratteri.';
    }

    if (data.length > 15) {
      return false;
    }

    if (!/^[0-9]{11}$/.test(data)) {
      return 'La partita IVA contiene dei caratteri non ammessi: la partita IVA dovrebbe contenere solo cifre.';
    }

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
      s += ord(data[i]) - ord('0');
    }

    for (let i = 1; i <= 9; i += 2) {
      let c = 2 * (ord(data[i]) - ord('0'));
      if (c > 9) {
        c = c - 9;
      }
      s += c;
    }

    if ((10 - (s % 10)) % 10 != ord(data[10]) - ord('0')) {
      return 'La partita IVA non è valida: il codice di controllo non corrisponde.';
    }
    return true;
  },
  postalCode: (data = '') => /^[0-9]{5}$/gm.test(data),
  sdi: (data = '') => /^[a-zA-Z0-9]{6,7}$/gm.test(data),
  password: (data = '') =>
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.+!\-@=$%^&(){}\[\]?"#/\\,><':;|_~`]).{8,99}/gm.test(
      data
    )
};

export default validator;
