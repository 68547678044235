/* INSTRUCTIONS:
 * if you need to create a new color check this https://www.color-name.com/
 * if you need to replace a color create a new one and use the IDE replace
*/
const colors = themeColor => ({
  /* Main */
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',
  logo: '#595959',

  /* Primary */
  primary: {
    base: themeColor,
    darker: '#000000', //shadeColor(themeColor, -40),
    lighter: '#000000', //desaturateColor(shadeColor(themeColor, 40), 50),
    redWave2: '#F04163'
  },

  /* Secondary */
  red: '#B1443D',
  redError: '#DE071C',
  greenDark: '#182A32',
  azureDark: '#0B3A4E',
  mineShaft: '#333333',
  filterColor: '#e8edef',
  placeholder: '#888888',
  links: '#0BA6FF',
  purple: '#5F2ED3',
  lightPurple: '#BB1EEA',
  lotion: '#fbfcfd',
  ming: '#3e6b7e',
  spanishGreen: '#00934A',
  vividGamboge: '#ff9500',
  orangeCrayola: '#fbd66a',
  softGreen: '#99F07E',
  redCrayola: '#F2284F',
  darkLemonLime: '#6dc11a',
  pinkSherbet: '#FA8DA2',
  vividMulberry: '#BB21ea',
  secondaryWhite: '#fefefe',
  moonstone: '#33A7B2',
  bitterLemon: '#C8D300',
  moonstoneBlue: '#6DC4BD',
  limerick: '#B1BB02',

  /* Grey */
  ghostWhite: '#F8F9FA',
  cultured: '#F4F4F4',
  antiFlashWhite: '#F1F1F1',
  platinum: '#E3E3E3',
  lightSilver: '#D6D6D6',
  americanSilver: '#CECECE',
  gray: '#C1BDBD',
  philippineGray: '#8E8E93',
  slateGray: '#708090',
  davysGray: '#5C5C5C',
  darkCharcoal: '#323232',
  brightGray: '#e8e8ea',
  specialGray: '#7A7B7E',
  gainGray: '#ddd',
  elevenGray: '#aaa',
  oldSilver: '#838383',
  cultured2: '#f6f6f6',
  cultured3: '#f6faf4',
  brightGray2: '#eee',
  nickel: '#727272',
  darkCharcoal2: '#303030',
  shareButton: '#EAEAEA',
  shareButtonFont: '#787878',
  lightGrey: '#878787',
  commentCardGrey: '#ECECEC',

  linearGradient: 'linear-gradient( 258deg, #e9dc9f, #90e4c7)', 

  /* Shadow */
  borderShadow: '0 0 10px 0 rgba(0,0,0,0.01), 0 5px 20px 0 rgba(0,0,0,0.08)',

  /* Icon */
  lightGreen: '#6fd22e',
});

export default colors;
