import React from "react";
import PropTypes from 'prop-types';
import ProText from "ui/typography/proText";
import { Section } from 'ui/components/Homepage/Section/style';
import {
  Banner,
  BannerLogo,
  BannerText,
} from "./style";

const SectionBanner = ({
  bannerImage,
  bannerLink,
  bannerLogo,
  bannerText,
  knowBefore
}) => {
  return (
    <Section>
      <a href={bannerLink} target="_blank" rel="noopener noreferrer">
        <Banner bg={bannerImage} height={330} className={knowBefore ? 'know-before' : ''}>
        {knowBefore && <BannerLogo src={bannerLogo} alt='banner-logo' className='know-before'/> }
          <BannerText className={knowBefore ? 'know-before' : ''}>
            <ProText
              configuration={{
                fontSize: knowBefore ? 50 : 56,
                fontWeight: knowBefore ? 600 : 700,
                color: knowBefore ? 'black' : 'white',
                lineHeight: '1.2'
              }}
              ellipsis={2}
            >
              {bannerText}
            </ProText>
          </BannerText>
          {!knowBefore && <BannerLogo src={bannerLogo} alt='banner-logo'/>}
        </Banner>
      </a>
    </Section>
  );
};

SectionBanner.propTypes = {
  bannerImage: PropTypes.string,
  bannerText: PropTypes.string,
};

export default SectionBanner;