import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'ui/gridSystem';
import DetailSection from 'pages/OnBoarding/DrawProfile/DetailSection';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { GET_PLAYLIST } from 'redux/actions/playlist';
import routes from 'routes';
import { push } from 'redux-first-history';
import RecommendModal from 'ui/components/Modals/RecommendModal';
import { MODAL_OPEN } from 'redux/actions';
import MainContainer from 'pages/style';
import { Footer } from 'ui/components';
import { getPreviousPath } from 'utils/router';

const LockedDetail = ({ getPlaylist, item, pushUrl, openModal, domainId, router }) => {
  const { id, productId } = useParams();
  const [payloadState, setPayload] = useState()

  useEffect(() => {
    if (Boolean(item)) {
      const payload = {
        productId: productId,
        type: item.type?.toLowerCase(),
        domainId: domainId.toString()
      }
  
      setPayload(payload)
    }

  }, [item])

  useEffect(() => {
    if (id) getPlaylist(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <MainContainer>
        <Container>
          <DetailSection
            item={item}
            onClose={() => pushUrl(getPreviousPath(router))}
            onAdd={
              item.recommended
                ? null
                : () => openModal(RecommendModal.id, { collectionId: id })
            }
            isLocked
          />
        </Container>
      </MainContainer>
      <RecommendModal payload={payloadState}/>
      <Footer />
    </>
  );
};

LockedDetail.propTypes = {
  item: PropTypes.object,
  pushUrl: PropTypes.func,
  getPlaylist: PropTypes.func,
  openModal: PropTypes.func,
  domainId: PropTypes.number,
  router: PropTypes.object

};

export default connect(
  state => {
    const { playlistSelected: item } = state.playlist;
    const { id: domainId } = state.domain;
    const { router } = state;
    return { item, domainId, router };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    openModal: (id, payload) => dispatch({ type: MODAL_OPEN, id, payload }),
    getPlaylist: id =>
      dispatch({
        type: GET_PLAYLIST._REQUEST,
        id,
        locked: true
      })
  })
)(LockedDetail);
