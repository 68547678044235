import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withMediaQueries } from 'hoc/withMediaQueries';

import { Container } from 'ui/gridSystem';

import { MODAL_OPEN } from 'redux/actions';

import { ProfilePicture, Footer } from 'ui/components';
import MainContainer from 'pages/style';
import ProDisplay from 'ui/typography/proDisplay';
import {
  Followers,
  FollowersContainer,
  Following,
  Head,
  IconEdit,
  Name,
  Number,
  ProfileInfo,
  ProgressCardsWrapper,
  ProgressesContainer,
  ProgressesTitle,
  NameAndPrivacy,
  Link,
} from 'pages/Profile/style';
import ProText from 'ui/typography/proText';
import ProfileProgressCard from 'ui/components/ProfileProgressCard';
import { GET_FOLLOW_COUNT } from 'redux/actions/social';
import EditProfileModal from 'ui/components/Modals/EditProfileModal';
import FollowerModal from 'ui/components/Modals/FollowerModal';
import { GET_PATH } from 'redux/actions/playlist';
import EditPrivacyModal from 'ui/components/Modals/EditPrivacyModal';
import { PROFILE_GET_DATA_PRIVACY } from 'redux/actions/profile';

const UserProfile = ({
  profile,
  getFollowCount,
  followCount,
  openModal,
  getPath,
  pathProgress,
  mediaIsPhone,
  getPrivacyData,
}) => {
  useEffect(() => {
    getFollowCount();
    getPath();
    getPrivacyData();
  }, [getFollowCount, getPath, getPrivacyData]);

  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <ProfileInfo>
              <ProfilePicture profile={profile} pictureChange />
              {mediaIsPhone ? (
                <NameAndPrivacy>
                  <Name>
                    <ProText type="name">
                      {profile.firstname} {profile.lastname}
                    </ProText>
                  </Name>

                </NameAndPrivacy>
              ) : (
                <NameAndPrivacy>
                  <Name>
                    <ProText type="name">
                      {profile.firstname} {profile.lastname}
                    </ProText>
                  </Name>
                  <IconEdit
                    className="icon-modifica"
                    onClick={() => openModal(EditProfileModal.id)}
                  />
                </NameAndPrivacy>
              )}
            </ProfileInfo>
            {mediaIsPhone && (
              <IconEdit
                className="icon-modifica"
                onClick={() => openModal(EditProfileModal.id)}
              />
            )}
          </Head>
          <ProgressesContainer>
            <ProgressesTitle>
              <ProDisplay
                type="menutitle"
                configuration={{
                  fontSize: '20',
                  color: 'darkCharcoal',
                }}
              >
                I Tuoi Progressi
              </ProDisplay>
            </ProgressesTitle>
            {pathProgress && (
              <ProgressCardsWrapper>
                <ProfileProgressCard
                  data={pathProgress?.collection}
                  title="Il tuo piano​"
                />
                {pathProgress?.selection?.items?.length > 0 ? (
                  <ProfileProgressCard
                    data={pathProgress?.selection}
                    title="Formazione Obbligatoria​"
                    bgColorLighter
                    isSelection
                  />
                ) : null}
              </ProgressCardsWrapper>
            )}
          </ProgressesContainer>
        </Container>
      </MainContainer>
      <Footer />
      <EditProfileModal />
      <EditPrivacyModal isSimpleUser />
      <FollowerModal />
    </>
  );
};

UserProfile.propTypes = {
  //HOC (connect, state)
  profile: PropTypes.object,
  followCount: PropTypes.object,
  pathProgress: PropTypes.object,

  //HOC (connect, dispatch)
  getFollowCount: PropTypes.func,
  getPath: PropTypes.func,
  openModal: PropTypes.func,
  getPrivacyData: PropTypes.func,
  //HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
};

export default connect(
  state => {
    const { user: profile = {} } = state.authentication;
    const { followCount } = state.social;
    const { playlists: { yourpathprogress: pathProgress } } = state.playlist;
    return { profile, followCount, pathProgress };
  },
  dispatch => ({
    getFollowCount: () => dispatch({ type: GET_FOLLOW_COUNT._REQUEST }),
    getPath: () => dispatch({ type: GET_PATH._REQUEST, forceCall: true }),
    openModal: (id, payload = {}) =>
      dispatch({ type: MODAL_OPEN, id, payload }),
    getPrivacyData: () => dispatch({ type: PROFILE_GET_DATA_PRIVACY._REQUEST }),
  })
)(withMediaQueries(UserProfile));
