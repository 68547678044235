const customCardKnowBe = {
    body: "banner 1",
    completed: false,
    cover: "/bg-knowbe4.jpg",
    title: "Impara a difenderti dagli attacchi informatici!",
    type: "SELECTION",
    noBookmark: true,
    logo: 'logo-knowbe4.png',
    itemId: 'custom-know-be',
    link: 'https://eu.knowbe4.com/learner/index.html#/dashboard'
}

export default customCardKnowBe;
