import styled from 'styled-components/macro';

const SubTitle = styled.div`
  color: ${props => props.theme.colors.mineShaft};
`;

const Description = styled.div``;

const Label = styled.label``;

const Price = styled.div``;

const Span = styled.span``;

const Text = styled.label`
  margin-bottom: 5px;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const Icon = styled.span`
  font-size: 15px;
  color: ${props => props.theme.colors.lightGreen};
  margin-right: 8px;
  margin-top: 2px;
`;

export {
  Description,
  Price,
  Span,
  Label,
  Text,
  TitleContainer,
  SubTitle,
  Icon
};
