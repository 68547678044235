import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ProText from 'ui/typography/proText';
import { CustomInputNumber2, Tooltip } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
// import { useAnalytics } from 'providers/analyticsProvider';
import { priceFormat, calcPeriod } from 'utils/price-format';
// import { mapCartItem } from 'utils/analytics';
import { ProductVariation } from 'interface/signup';
import { connect } from 'react-redux';
import {
  DescriptionContainer,
  QuantityContainer,
  ProductContainer,
  Title,
  Description,
  QuantityLabel,
  PriceContainer,
  Span,
  IVA,
  Question,
  Period,
  PriceRow,
  VatRow,
  IconInfo,
  TextSection,
} from './style';

const currencyCodes = {
  EUR: '€',
};

const Product = ({ product, isChild, onChange, disabled, cart, isCatalog }) => {
  // const { ga4IsInitialized, sendGA4Event } = useAnalytics();

  useEffect(() => {
    if (disabled) {
      if (onChange) onChange(0, product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const handleChange = num => {
    if (onChange) onChange(num, product);
  };

  // const trackingCallback = (action) => {
  //   if (!product || !ga4IsInitialized) return;

  //   const items = Object.values(cart.products).map((item, index) => mapCartItem(item, index));
  //   /*
  //     this operation is necessary because the cart hasn't yet been updated at this point,
  //     careful moving this part of the code to other components
  //   */
  //   const existingItem = items.find(item => item.item_id === product.sku);
  //   if (existingItem) existingItem.quantity += 1;
  //   else items.push(mapCartItem(product));

  //   sendGA4Event({
  //     eventCategory: 'signup',
  //     eventAction: action,
  //     currency: product.price_code,
  //     value: cart.total_price
  //       ? parseFloat(cart.total_price) + parseFloat(product.price_number) /* same as above, necessary when cart hasn't yet been updated*/
  //       : parseFloat(product.price_number),
  //     items,
  //   });
  // };

  return (
    <ProductContainer isChild={isChild}>
      <DescriptionContainer isCatalog={isCatalog}>
        {Boolean(product.question) && (
          <ProText>
            <Question>{product.question}</Question>
          </ProText>
        )}

        <ProText type="title">
          <TextSection>
            <Title>{product.title}</Title>
            {Boolean(product.information) && (
              <Tooltip text={product.information}>
                <IconInfo className="icon-icona-info-active" />
              </Tooltip>
            )}
          </TextSection>
        </ProText>

        <Description>
          {Boolean(product.body) && (
            <ProText type="label" configuration={{ fontWeight: 300 }}>
              <div
                dangerouslySetInnerHTML={{ __html: product.body }}
                style={{ color: '#5c5c5c' }}
              />
            </ProText>
          )}
        </Description>
      </DescriptionContainer>
      <PriceContainer>
        <PriceRow>
          <ProDisplay type="productPrice">
            {Math.trunc(priceFormat(product?.price_number))}{' '}
          </ProDisplay>
          <Span>
            <ProDisplay type="productPriceCurrency">
              {currencyCodes[product?.price_code]
                ? currencyCodes[product?.price_code]
                : product?.price_code}
            </ProDisplay>
          </Span>
          {Boolean(product.cards) && Boolean(calcPeriod(product.cards)) && (
            <ProDisplay>
              <Period>{` / ${calcPeriod(product.cards)}`}</Period>
            </ProDisplay>
          )}
        </PriceRow>
        <VatRow>
          <IVA>
            <ProDisplay type="IVA">(IVA esclusa)</ProDisplay>
          </IVA>
        </VatRow>
      </PriceContainer>
      {!isCatalog && (
        <QuantityContainer>
          <ProDisplay>
            <QuantityLabel>
              Seleziona il numero di utenze da attivare
            </QuantityLabel>
          </ProDisplay>

          <CustomInputNumber2
            value={
              cart?.products[
                Object.keys(cart?.products).find(k => k === product.sku)
              ]?.num || undefined
            }
            disabled={disabled}
            onChange={handleChange}
            // trackingCallback={trackingCallback}
          />
        </QuantityContainer>
      )}
    </ProductContainer>
  );
};

Product.propTypes = {
  product: ProductVariation,
  isChild: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  cart: PropTypes.object,
  isCatalog: PropTypes.bool,
};

export default connect(({ signup: { cart } }) => ({ cart }))(Product);
