import styled, { css } from 'styled-components/macro';

export const Placeholder = styled.span`
  color: ${props => props.theme.colors.placeholder};
  position: absolute;
  left: 25px;
  transition: 125ms ease-in;
  pointer-events: none;

  @media ${props => props.theme.device.desktop} { left: 20px; }
  @media ${props => props.theme.device.tablet} { left: 25px; }
  @media ${props => props.theme.device.mobile} { left: 20px; }

  > div {
    display: inline-block;
    width: auto;
    transition: font-size 250ms ease-in;
  }
`;

export const Error = styled.span`
  color: ${props => props.theme.colors.redError};
  display: block;
  margin: 8px 0 0px;
`;

export const Valid = styled.span`
  display: block;
  margin: 8px 0 0px;
`;

export const Input = styled.input`
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  width: 100%;
  padding: 20px 0 0 25px;
  transition: all 125ms ease-in;

  @media ${props => props.theme.device.desktop} { padding: 16px 0 0 20px; }
  @media ${props => props.theme.device.tablet} { padding: 16px 0 0 25px; }
  @media ${props => props.theme.device.mobile} { padding: 16px 0 0 20px; }

  text-transform: ${props => (props.forceUppercase ? 'uppercase' : 'none')};

  ${props =>
    props.disabled &&
    css`
      background-color: rgba(232, 232, 234, 0.2);
    `};

  &:focus {
    outline: none;
    appearance: none;
    border-color: ${props => props.theme.colors.azureDark};
    outline: 0;
    box-shadow: 0 0 0 0.17647rem
      ${props => props.theme.mixin.rgba(props.theme.colors.azureDark, 0.2)};
  }

  &::-moz-placeholder {
    line-height: 33px;
  }
`;

export const MarginWrapper = styled.div`
  margin-bottom: 20px;

  @media ${props => props.theme.device.desktop} { margin-bottom: 16px; }
  @media ${props => props.theme.device.tablet} { margin-bottom: 35px; }
  @media ${props => props.theme.device.mobile} { margin-bottom: 16px; }
`

export const InputContainer = styled.div`
  position: relative;
  height: 57px;
  &, input { font-size: 16px; }
  @media ${props => props.theme.device.desktop} { height: 57px; &, input { font-size: 16px; } }
  @media ${props => props.theme.device.tablet} { height: 65px; &, input { font-size: 20px; } }
  @media ${props => props.theme.device.mobile} { height: 55px; &, input { font-size: 16px; } }

  span{
    font-size: 14px;
    font-family: 'Arial';
    font-weight: 400;
  }

  &.smallHeight{
    height: 35px;
  }
  ${Placeholder} {
    ${props =>
      props.placeholderFocusNone && (props.focus || props.value) ? 
      css`
        display: none;
      ` :
      !(props.focus || props.value || props.forceFocus)
        ? css`
          top: 50%;
          transform: translate(0, -50%);
        `
        : css`
          top: 6px;
          span { font-size: 16px; }

          @media ${props => props.theme.device.desktop} { top: 5px; span { font-size: 13px; } }
          @media ${props => props.theme.device.tablet} { top: 5px; span { font-size: 15px; } }
          @media ${props => props.theme.device.mobile} { top: 5px; span { font-size: 13px; } }
    `
    };
  }

  ${props =>
    props.error &&
    css`
      ${Input} {
        border-color: ${!props.focus && props.theme.colors.redError};
        color: ${!props.focus && props.theme.colors.redError};
      }
    `};

  .StripeElement {
    border-radius: 4px;
    border: solid 1px ${props => props.theme.colors.lightSilver};
    background-color: ${props => props.theme.colors.white};
    height: 100%;
    width: 100%;
    padding: 24px 0 0 20px;
    transition: all 125ms ease-in;

    &--invalid {
      border-color: ${props => !props.focus && props.theme.colors.redError};
    }

    &--focus {
      outline: none;
      appearance: none;
      border-color: ${props => props.theme.colors.azureDark};
      outline: 0;
      box-shadow: 0 0 0 0.17647rem
        ${props => props.theme.mixin.rgba(props.theme.colors.azureDark, 0.2)};
    }
  }
`;

export const SpanIcon = styled.span`
  position: absolute;
  font-size: x-large;
  color: ${props => props.theme.colors.ghostWhite};
  top: 14px;
  right: 35px;
  cursor: pointer;
`;
