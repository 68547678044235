import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  SET_SUBSCRIPTIONS_FILTER,
  CREATE_PROSPECT,
  GET_CALENDARS,
} from 'redux/actions/subscriptions';

import { withMediaQueries } from 'hoc/withMediaQueries';

import { AgreementCheck, ConfirmModal } from 'ui/components';
import { Col, Container } from 'ui/gridSystem';
import { Button } from 'ui/atoms';
import validator from 'utils/regex-validator';
import SubscriptionModal from 'ui/components/Modals/SubscriptionModal';
import ProDisplay from 'ui/typography/proDisplay';
import { MODAL_OPEN } from 'redux/actions';
import LoadListModal from 'ui/components/Modals/LoadListModal';
import NewSubscriptions from 'ui/components/NewSubscriptions';
import ProText from 'ui/typography/proText';
import { getCookie, setCookie } from 'utils/cookies';
import MainContainer from '../../../../style';
import {
  ActiveSubscriptionsContainer,
  Head,
  ButtonWrapper,
  // AddWrapper,
  // MenuLabel,
  Load,
  Link,
  Banner,
  IconBanner,
  Triangle,
  // IconNotes,
  NewSubscriptionsCTA,
} from '../ActiveSubscriptions/style';
import { PlaceholderList } from '../style';

const cookiePair = 's4c_banner-visible=false';

const EditSubscriptions = ({
  assoc_subscriptions,
  calendars,
  getCalendars,
  createProspect,
  openModal,
  load_list,
  callbackProspect,
  isSpinning,
  agreements,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [bannerVisible, setBannerVisible] = useState(!getCookie(cookiePair));
  const [variations, setVariations] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [maxLength, setMaxLength] = useState();
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [shouldCheckAgreements, setShouldCheckAgreements] = useState(false);
  const [unassignedSubscriptions, setUnassignedSubscriptions] = useState([]);
  const [filteredAssocSubscriptions, setFilteredAssocSubscriptions] = useState();

  useEffect(() => {
    /* fixes issues with data duplication in redux flow, BE CAREFUL USING assoc_subscriptions FROM THE GLOBAL STORE!! */
    const newAssocSubscriptions = { ...assoc_subscriptions };
    const subscriptionIds = new Set();
    newAssocSubscriptions.orders = newAssocSubscriptions.orders?.filter(order => {
      if (subscriptionIds.has(order.subscriptionId)) return false;
      subscriptionIds.add(order.subscriptionId);
      return true;
    }) ?? newAssocSubscriptions.orders;
    setFilteredAssocSubscriptions(newAssocSubscriptions);
  }, [assoc_subscriptions]);

  useEffect(() => {
    if (!unassignedSubscriptions.length) setUnassignedSubscriptions(filteredAssocSubscriptions?.orders || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAssocSubscriptions]);

  useEffect(() => {
    if (filteredAssocSubscriptions?.orders?.length && !variations?.length && !subscriptions.length) {
      const initialVariation = (filteredAssocSubscriptions?.orders || []).reduce((acc, val) => {
        const arrayVariation = [];
        for (let i = 0; i < val.quantity; i += 1) {
          arrayVariation.push({ ...val, id: `${val.variation_id}-${i}` });
        }
        return [...acc, ...arrayVariation];
      }, []);
      setMaxLength(initialVariation.length || 0);
      setVariations(initialVariation);
    } else if (filteredAssocSubscriptions?.orders?.length && unassignedSubscriptions.length && subscriptions.length) {
      const additionalVariation = (filteredAssocSubscriptions?.orders || []).splice(0, unassignedSubscriptions.length).reduce((acc, val) => {
        const arrayVariation = [];
        for (let i = 0; i < val.quantity; i += 1) {
          arrayVariation.push({ ...val, id: `${val.variation_id}-${i}` });
        }
        return [...acc, ...arrayVariation];
      }, []);
      const additionalSubscription = additionalVariation.map((variation, index) => ({
        id: `subscription-${index + subscriptions.length}`,
        email: undefined,
        variations: [],
        error: undefined,
      }));
      setMaxLength(maxLength + additionalVariation.length);
      setVariations([...variations, ...additionalVariation]);
      setSubscriptions([...subscriptions, ...additionalSubscription]);
      setUnassignedSubscriptions(filteredAssocSubscriptions?.orders || []);
    } else {
      setMaxLength(0);
      setVariations([]);
      setSubscriptions([]);
      setUnassignedSubscriptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAssocSubscriptions]);

  useEffect(() => {
    if (variations && variations.length && !subscriptions?.length) {
      const initialSubscriptions = variations.map((variation, index) => ({
        id: `subscription-${index}`,
        email: undefined,
        variations: [],
        error: undefined,
      }));
      setSubscriptions(initialSubscriptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variations]);

  const checkEmail = (subscriptionsToCheck) => {
    // const existingEmail = [];
    subscriptionsToCheck.forEach(subscription => {
      if (subscription.email && subscription.email.length) {
        // check email valid
        const valid = validator.email(subscription.email);
        // setSub with new email value || error
        subscription.error = !valid
          ? "L'email inserita non è valida."
          : undefined;

        // existingEmail.push(subscription.email);
      }
    });

    return subscriptionsToCheck;
  };

  const handleOnChangeEmail = (subscriptionId, newValue) => {
    const newSubscriptions = [...subscriptions];

    newSubscriptions.forEach(subscription => {
      if (subscription.id === subscriptionId) {
        subscription.email = newValue;
        // TODO: only reset if new email is empty ot equal to email already on different row
        //subscription.variations = [];
      }
    });

    const newCheckedSubscriptions = checkEmail(newSubscriptions);
    setSubscriptions(newCheckedSubscriptions);
  };


  const handleOnChangeVariation = (
    subscriptionId,
    oldVariations = [],
    newVariations = []
  ) => {
    // Call to API calendars for premium packages and update calendars dropdown
    if(newVariations.packageType.toLowerCase() === 'premium'){
      getCalendars(newVariations.productId);
    }
    // TODO: handle calendars
    const checkedNewVariations = newVariations || [];
    const newSubscriptions = [...subscriptions];
    const newUnassignedVariations = [...variations, ...oldVariations];
    const deltaVariations = checkedNewVariations.length - oldVariations.length;

    //Assign variation to subscription
    const assignedVariations = [];
    const assignedVariationIndex = newUnassignedVariations.findIndex(
      v => v.variationId === checkedNewVariations.value
    );

    assignedVariations.push(
      ...newUnassignedVariations.splice(assignedVariationIndex, 1)
    );

    newSubscriptions.forEach(subscription => {
      if (subscription.id === subscriptionId) { subscription.variations = assignedVariations; }
    });

    // delete subs
    // if I had no variation checked, deltaVariations must be >= 2
    if (oldVariations.length === 0 && deltaVariations > 1) {
      for (let i = 2; i <= deltaVariations; i++) {
        const lastEmptyIndex = newSubscriptions
          .reverse()
          .findIndex(s => s.variations.length === 0);
        newSubscriptions.splice(lastEmptyIndex, 1);
        newSubscriptions.reverse();
      }
    }

    // if I had at least 1 variation already checked, deltaVariations must be >= 1
    if (oldVariations.length > 0 && deltaVariations >= 1) {
      for (let i = 1; i <= deltaVariations; i++) {
        const lastEmptyIndex = newSubscriptions
          .reverse()
          .findIndex(s => s.variations.length === 0);
        newSubscriptions.splice(lastEmptyIndex, 1);
        newSubscriptions.reverse();
      }
    }

    // add subs:
    // if I uncheck all the variations, deltaVariations must be <= -2 because I still have one empty subscription
    if (deltaVariations < -1 && checkedNewVariations.length === 0) {
      for (let i = deltaVariations; i < -1; i += 1) {
        const lastId = newSubscriptions[newSubscriptions.length - 1].id;
        const lastIndex = parseInt(lastId.split('-')[1]);
        newSubscriptions.push({
          id: `subscription-${lastIndex + 1}`,
          email: undefined,
          variations: [],
        });
      }
    }

    // if I leave at least 1 variation checked, deltaVariations must be <= -1
    if (deltaVariations < 0 && checkedNewVariations.length > 0) {
      for (let i = deltaVariations; i <= -1; i++) {
        const lastId = newSubscriptions[newSubscriptions.length - 1].id;
        const lastIndex = parseInt(lastId.split('-')[1]);
        newSubscriptions.push({
          id: `subscription-${lastIndex + 1}`,
          email: undefined,
          variations: [],
        });
      }
    }

    setSubscriptions(newSubscriptions);
    setVariations(newUnassignedVariations);
  };

  const handleSelectCalendar = (calendar, variation, emailName)=> {
    const newSubscriptions = [...subscriptions]
    newSubscriptions.forEach(sub => {
      if (sub.email !== emailName) return;
      const newVariations = sub.variations.map(subVariation => {
        if(subVariation.productId === variation[0].productId){
          subVariation.calendarId = calendar.calendarId;
          subVariation.startDate = calendar.startDate;
          subVariation.endDate = calendar.endDate;
        }
        return subVariation;
      });
      sub.variations = newVariations;
    });

    setSubscriptions(newSubscriptions)
  }

  useEffect(() => {
    const validFields = subscriptions.every(subscription => {
      const hasEmail = subscription.email && subscription.email.length;
      const hasVariations = subscription.variations && subscription.variations.length > 0;
      const hasCalendars = hasVariations && (
        !subscription.variations.some(variation => (
          variation.packageType === 'premium'
          && (!variation.calendarId && variation.calendarId !== 0)
        ))
      );
      return hasEmail && hasVariations && hasCalendars && !subscription.error;
  });
    setAllFieldsValid(validFields);
  }, [subscriptions]);

  /*** CONFIGURATION NEW USER SUBSCRIPTION END ***/

  /*** ADD NEW USER SUBSCRIPTION END ***/

  const getFilteredArray = array => array;

  const handleOnClick = () => {
    const payload = subscriptions.map(item => ({
      email: item.email,
      variations: item.variations,
    }));
    createProspect(payload);
    if (callbackProspect) callbackProspect();
  };

  /*** LOAD LIST START ***/

  const loadList = () => {
    const payload = {
      title: load_list?.title,
      description: load_list?.description,
      description_two: load_list?.description_two,
    };
    openModal(payload);
  };

  const onLoadList = emails => {
    const newSubscriptions = [...subscriptions];
    newSubscriptions.forEach((subscription, index) => {
      if (!subscription.email) subscription.email = emails[index];
    });

    const newCheckedSubscriptions = checkEmail(newSubscriptions);
    setSubscriptions(newCheckedSubscriptions);
  };

  /*** LOAD LIST END ***/

  const closeBanner = () => {
    setBannerVisible(false);
    setCookie(cookiePair);
  };

  const formConfirmed = () => {
    let valid = true;
    if (agreements?.user_clauses) {
      (Object.keys(agreements.user_clauses) || []).forEach(key => (valid = valid && agreements.user_clauses[key]?.valid));
    } else {
      valid = false;
    }
    return valid;
  };

  useEffect(() => {
    setShouldCheckAgreements(!formConfirmed());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const filteredCalendars = Object.entries(calendars).reduce((acc, [calendarKey, calendarList]) => {
    const foundSubscription = subscriptions.find(subscription =>
      subscription?.variations[0]?.productId === calendarKey
    );
    const newCalendarList = foundSubscription?.variations[0]?.calendarId ? (
      calendarList.map(calendarValue => {
        if (calendarValue.calendarId === foundSubscription?.variations[0]?.calendarId) {
          return { ...calendarValue, availableSeats: Math.max(0, calendarValue.availableSeats - 1)}
        } else {
          return calendarValue
        }
      })
    ) : calendarList;
    acc[calendarKey] = newCalendarList
    return acc;
  }, {});

  return (
    <MainContainer noMenu whiteBg>
      <Container className="p-0">
        {subscriptions.length > 0 ? (
          <ActiveSubscriptionsContainer>
            <Head hasBanner={bannerVisible}>
              <Load onClick={loadList}  className='link-btn'>
                <Link href="#">
                  <ProText type="link">Carica elenco utenti</ProText>
                </Link>
              </Load>
            </Head>
            {bannerVisible && (
              <>
                <Triangle />
                <Banner>
                  <IconBanner className="icon-icona-note" />
                  <ProDisplay type="link" configuration={{ marginLeft: '5px' }}>
                    Hai bisogno di caricare tutti gli utenti in maniera
                    massiva?&nbsp;
                  </ProDisplay>
                  <ProDisplay type="tableLabel">
                    Scopri come caricare l'elenco.
                  </ProDisplay>
                  <IconBanner className="icon-close-x" onClick={closeBanner} />
                </Banner>
              </>
            )}
            <NewSubscriptions
              isConfig
              variations={[...variations]}
              subscriptions={[...subscriptions]}
              calendars={filteredCalendars}
              handleOnChangeEmail={handleOnChangeEmail}
              handleOnChangeVariation={handleOnChangeVariation}
              handleSelectCalendar={handleSelectCalendar}
              getFilteredArray={getFilteredArray}
            />
            <NewSubscriptionsCTA>
              {shouldCheckAgreements && (
                <AgreementCheck validator={formConfirmed} />
              )}
              <Col md={3} sm={12} className={'mt-4'}>
              <ButtonWrapper className='submitSubscription'>
                <Button
                  text="Conferma"
                  type={Button.TYPE.PRIMARY}
                  disabled={!allFieldsValid || !formConfirmed()}
                  onClick={handleOnClick}
                />
              </ButtonWrapper>
              </Col>
            </NewSubscriptionsCTA>
          </ActiveSubscriptionsContainer>
        ) : (
          !isSpinning && (
            <PlaceholderList>
              <ProText>
                Non sono presenti abbonamenti/pacchetti da configurare
              </ProText>
            </PlaceholderList>
          )
        )}
        <SubscriptionModal />
        <ConfirmModal />
        <LoadListModal
          onLoadList={onLoadList}
          emptySubcriptionsLength={maxLength}
        />
      </Container>
    </MainContainer>
  );
};

EditSubscriptions.propTypes = {
  callbackProspect: PropTypes.func,

  //HOC (connect, state)
  assoc_subscriptions: PropTypes.object,
  load_list: PropTypes.object,
  isSpinning: PropTypes.bool,
  agreements: PropTypes.object,

  //HOC (connect, dispatch)
  createProspect: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,

  // HOC withMediaQueries
};

export default connect(
  state => {
    const {
      assoc_subscriptions,
      calendars,
    } = state.subscriptions;
    const { editSubscriptions: isEditSubscriptions } = state.app;
    const { load_list } = state.configuration;

    const {
      loader: { isSpinning },
    } = state.app;

    const { agreements } = state.signup;

    return {
      assoc_subscriptions,
      calendars,
      isEditSubscriptions,
      load_list,
      isSpinning,
      agreements,
    };
  },
  dispatch => ({
    setSubscriptionFilters: filters =>
      dispatch({
        type: SET_SUBSCRIPTIONS_FILTER,
        filters,
      }),
    getCalendars: productId => dispatch({
      type: GET_CALENDARS._REQUEST,
      productId
    }),
    createProspect: (body, cb) =>
      dispatch({ type: CREATE_PROSPECT._REQUEST, payload: { body }, cb }),
    openModal: payload =>
      dispatch({
        type: MODAL_OPEN,
        id: LoadListModal.id,
        payload,
      }),
  })
)(withMediaQueries(EditSubscriptions));
