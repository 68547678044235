import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import routes from 'routes';

import { Footer, Menu } from 'ui/components';

import {
  Subscriptions,
  TeamsInterest,
  Recommended,
  S4BCatalog
} from './sections';

const MySubscriptions = () => {
  const { pathname } = window.location;
  return (
    <>
      <Menu activeMenu={pathname} />
      <Switch>
        <Route
          path={routes.recommended.path}
          exact
          render={() => <Recommended />}
        />
        <Route
          path={routes.teamsInterest.path}
          exact
          render={() => <TeamsInterest />}
        />
        <Route
          path={routes.allSubsriptions.path}
          render={() => <Subscriptions />}
        />
        <Route
          path={routes.s4bCatalog.path}
          exact
          render={() => <S4BCatalog />}
        />
        <Redirect to={routes.allSubsriptions.path} />
      </Switch>
      <Footer />
    </>
  );
};

export default MySubscriptions;
