import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProDisplay from 'ui/typography/proDisplay';
import TYPES from 'ui/typography/pro.types';
import Select from 'ui/atoms/Select';
import {
  InputContainer,
  Input,
  Placeholder,
  SpanIcon,
  Error,
  Valid,
  MarginWrapper,
} from './style';

const CustomInput = ({
  autocomplete,
  children,
  type,
  placeholder,
  hasIcon,
  iconName,
  iconClass,
  onChange,
  error,
  valid,
  value,
  name,
  id,
  options,
  text,
  autoFocus,
  onBlur,
  disabled,
  selectedOptions,
  isEditEmail,
  hasMinLenght,
  sameEmail,
  wrongCode,
  multiple,
  forceUppercase,
  displayError,
  currentViewport,
  className,
  noMargin,
  placeholderFocusNone
}) => {
  const [val, setVal] = useState(value);
  const updateValue = ({ target: { value: newValue } = {} }) => {
    setVal(newValue);
    if (onChange) onChange(newValue);
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  const [focus, setFocus] = useState(false);
  const toggleFocus = () => setFocus(s => !s);

  const handleOnBlur = () => {
    toggleFocus();
    if (onBlur) onBlur();
  };

  const getDefaultError = () => 'Errore: il campo non è valido';
  const getDefaultValid = () => 'Codice sconto inserito correttamente';

  const renderInputByType = () => {
    /* TODO: implement sr-only label inside CustomInput (currently provides id for external labels) */
    switch (type) {
      case 'text':
      case 'number':
      case 'password':
      case 'email':
        return (
          <Input
            autoComplete={autocomplete}
            type={type}
            name={name || placeholder || text || type}
            id={id}
            className={iconClass}
            onChange={updateValue}
            onBlur={handleOnBlur}
            onFocus={toggleFocus}
            value={val || ''}
            autoFocus={autoFocus}
            disabled={disabled}
            forceUppercase={forceUppercase}
          />
        );

      case 'select':
        return (
          <Select
            error={error}
            focus={focus}
            name={name || placeholder || text || type}
            id={id}
            onChange={updateValue}
            onBlur={handleOnBlur}
            onFocus={toggleFocus}
            options={options}
            value={selectedOptions || val}
            disabled={disabled}
            multiple={multiple}
          />
        );

      // cases for Stripe
      default: {
        if (children) {
          return React.cloneElement(children, {
            onBlur: toggleFocus,
            onFocus: toggleFocus,
            autoComplete: autocomplete
          });
        }

        return null;
      }
    }
  };

  const types = ['text', 'number', 'select', 'password', 'email'];
  const forceFocus = !types.includes(type);

  const getCustomInputContent = () => (
    <>
      <InputContainer
        focus={focus}
        value={val}
        error={error}
        forceFocus={forceFocus}
        className={className}
        placeholderFocusNone={placeholderFocusNone}
      >
        {renderInputByType()}
        <Placeholder>
          <ProDisplay
            type={TYPES.DISPLAY.CUSTOM_INPUT_UTILITY}
            currentViewport={currentViewport}
          >
            {placeholder || name || text || type}
          </ProDisplay>
        </Placeholder>
        {hasIcon ? <SpanIcon className={iconName} /> : ''}
      </InputContainer>
      {displayError && error && !focus ? (
        <Error>
          <ProDisplay
            type={TYPES.DISPLAY.CUSTOM_INPUT_UTILITY}
            currentViewport={currentViewport}
          >
            {typeof error === 'string' ? error : getDefaultError()}
            {isEditEmail && ' (è possibile modificare massimo 2 caratteri)'}
            {hasMinLenght && ' (il numero minimo di caratteri è 3)'}
            {sameEmail &&
              ' (non è possibile inserire la stessa email per lo stesso abbonamento)'}
            {wrongCode && ' (codice sconto non valido)'}
          </ProDisplay>
        </Error>
      ) : null}
      {valid && !focus ? (
        <Valid>
          <ProDisplay
            type={TYPES.DISPLAY.CUSTOM_INPUT_UTILITY}
            currentViewport={currentViewport}
          >
            {typeof valid === 'string' ? valid : getDefaultValid()}
          </ProDisplay>
        </Valid>
      ) : null}
    </>
  )

  return (
    noMargin ? (
      <div>
        {getCustomInputContent()}
      </div>
    ) : (
      <MarginWrapper>
        {getCustomInputContent()}
      </MarginWrapper>
    )
  );
};

CustomInput.propTypes = {
  autocomplete: PropTypes.string,
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string,
  iconName: PropTypes.string,
  hasIcon: PropTypes.bool,
  isEditEmail: PropTypes.bool,
  hasMinLenght: PropTypes.bool,
  wrongCode: PropTypes.bool,
  sameEmail: PropTypes.bool,
  selectedOptions: PropTypes.any,
  iconClass: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  valid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  multiple: PropTypes.bool,
  forceUppercase: PropTypes.bool,
  displayError: PropTypes.bool,
  noMargin: PropTypes.bool,
  placeholderFocusNone: PropTypes.bool
};

CustomInput.defaultProps = {
  autocomplete: '',
  displayError: true,
  noMargin: false,
};

export default CustomInput;
