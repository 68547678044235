import styled, { css } from 'styled-components/macro';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //margin: 0 10px;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.white};
  .capitalize {
    text-transform: capitalize;
  }
`;

const centeredImage = css`
  background-position: center;
  background-size: cover !important; // (possible conflict with carousel styles)
  background-repeat: no-repeat;

  background-image: ${props => props.bg
      ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 96.81%), url(${props.bg})`
      : `linear-gradient(45deg, ${props.theme.colors.primary.base} 0%, ${props.theme.colors.ghostWhite} 100%)`
  };
`;

export const NewsCard = styled(Card)`
  width: 200px;
  height: 240px;

  @media ${props => props.theme.device.tablet} {
    width: 170px;
    height: 205px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 160px;
    height: 190px;
  }
`

export const NewsImage = styled.div`
  height: 58%;
  border-radius: 16px 16px 0 0;
  background-image: ${props => props.src
      ? `url(${props.src})`
      : `linear-gradient(135deg, ${props.theme.colors.primary.base} 0%, ${props.theme.colors.ghostWhite} 100%)`
  };
  background-size: cover;
  background-position: center;
`;

export const NewsFooter = styled.div`
  height: 42%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NewsTitle = styled.div`
  padding: 17px 12px 0 12px;

  @media ${props => props.theme.device.tablet} {
    padding: 15px 10px 0 10px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 13px 9px 0 13px;
  }
`;

export const NewsLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px 10px;
  color: ${props => props.theme.colors.moonstone};

  @media ${props => props.theme.device.tablet} {
    padding: 0 9px 9px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 0 8px 8px;
  }
`;

export const CourseCard = styled(Card)`
  width: 320px;
  height: 200px;
  justify-content: flex-end;
  color: ${props => props.theme.colors.white};

  ${centeredImage}

  @media ${props => props.theme.device.tablet} {
    width: 240px;
    height: 150px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 180px;
    height: 110px;
  }
`;

export const CourseTitle = styled.div`
  height: 55px;
  padding: 0 17px 30px;
  word-break: break-word;

  @media ${props => props.theme.device.tablet} {
    height: 40px;
    padding: 0 17px 30px;
  }

  @media ${props => props.theme.device.mobile} {
    height: 30px;
    padding: 0 10px 18px;
  }
`;

export const CourseLabel = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 17px 16px;

  @media ${props => props.theme.device.tablet} {
    height: 23px;
    padding: 0 12px 13px;
  }

  @media ${props => props.theme.device.mobile} {
    height: 17px;
    padding: 0 10px 9px;
  }
`;

const IconPlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const NewsPlayL = styled.div`
  position: absolute;
  text-align: center;
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary.base};

  --play-radius: 20px;
  --play-diameter: calc(var(--play-radius) * 2);

  right: var(--play-radius);
  top: calc(var(--play-radius) * -1 );
  line-height: var(--play-diameter);
  width: var(--play-diameter);
  height: var(--play-diameter);
  font-size: var(--play-diameter);

  @media ${props => props.theme.device.tablet} {
    --play-radius: 19px;
  }

  @media ${props => props.theme.device.tablet} {
    --play-radius: 17px;
  }
`;

export const NewsPlayS = styled(IconPlay)`
  width: 18px;
  height: 18px;
  font-size: 10px;
  margin-right: 10px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.americanSilver};

  @media ${props => props.theme.device.tablet} {
    width: 14px;
    height: 14px;
    font-size: 8px;
    margin-right: 8px;
  }
`;

export const CoursePlay = styled(IconPlay)`
  width: 13px;
  height: 13px;
  font-size: 8px;
  margin-right: 8px;
  color: ${props => props.theme.colors.slateGray};
  background-color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.tablet} {
    width: 10px;
    height: 10px;
    font-size: 6px;
    margin-right: 6px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 8px;
    height: 8px;
    font-size: 5px;
    margin-right: 4px;
  }
`;

export const IconLock = styled.span`
  display: block;
  width: 30px;
  font-size: 30px;
  margin-left: auto;
  color: ${props => props.theme.colors.white};
`;

export const IconBookmark = styled.div`
  display: block;
  width: 30px;
  font-size: 30px;
  margin-left: auto;
  color: ${props => props.theme.colors.white};
`;

export const PackageCard = styled(Card)`
  width: 446px;
  padding: 0 24px;
  //margin: 0 20px;

  @media ${props => props.theme.device.desktop}
  { width: 370px; padding: 0 20px; }
  @media ${props => props.theme.device.laptop}
  { width: 306px; padding: 0 16px; }
  @media ${props => props.theme.device.tablet}
  { width: 220px; padding: 0 14px; }
  @media ${props => props.theme.device.mobile}
  { width: 260px; padding: 0 12px; }
`;

export const PackageHeader = styled.div`
  height: 313px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: ${props => `1px solid ${props.theme.colors.platinum}`};

  @media ${props => props.theme.device.desktop}
  { height: 260px; padding-top: 16px; }
  @media ${props => props.theme.device.laptop}
  { height: 215px; padding-top: 14px; }
  @media ${props => props.theme.device.tablet}
  { height: 148px; padding-top: 12px; }
  @media ${props => props.theme.device.mobile}
  { height: 175px; padding-top: 10px; }

  .icon-container{
    position:relative;
    > span {
      max-width: calc(100% - 70px);
    }
    .premium{
      position: absolute;
      right: 0px;
      font-size: 12px;
      @media ${props => props.theme.device.desktop} { top: 178px; }
      @media ${props => props.theme.device.laptop} { top: 150px;  }
      @media ${props => props.theme.device.tablet} { top: 104px; }
      @media ${props => props.theme.device.mobile} { top: 120px;}
    }
  }
`;

export const PackageImage = styled.img`
  width: 100%;
  height: 190px;
  display: block;
  object-fit: cover;
  margin-bottom: 13px;
  border-radius: 16px;

  @media ${props => props.theme.device.desktop}
  { height: 158px; margin-bottom: 11px; }
  @media ${props => props.theme.device.laptop}
  { height: 130px; margin-bottom: 9px; }
  @media ${props => props.theme.device.tablet}
  { height: 94px; margin-bottom: 6px; }
  @media ${props => props.theme.device.mobile}
  { height: 110px; margin-bottom: 5px; }
`;

export const PackageContents = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray};
`;

export const IconAssets = styled.span`
  font-size: 30px;
  margin-right: 4px;

  @media ${props => props.theme.device.tablet} { font-size: 24px; margin-right: 3px; }
  @media ${props => props.theme.device.tablet} { font-size: 20px; margin-right: 2px; }
  @media ${props => props.theme.device.tablet} { font-size: 18px; margin-right: 1px; }
  @media ${props => props.theme.device.mobile} { font-size: 16px; margin-right: 0; }
`;

export const PackageFooter = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 19px;

  @media ${props => props.theme.device.desktop}
  { height: 66px; padding-bottom: 19px; }
  @media ${props => props.theme.device.laptop}
  { height: 54px; padding-bottom: 17px; }
  @media ${props => props.theme.device.tablet}
  { height: 46px; padding-bottom: 13px; }
  @media ${props => props.theme.device.mobile}
  { height: 54px; padding-bottom: 16px; }

  .bold { font-weight: 700; }
`;

export const PackageProgress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;

  --bar-width: 211px;

  @media ${props => props.theme.device.desktop}
  { margin-top: 4px; --bar-width: 175px }
  @media ${props => props.theme.device.laptop}
  { margin-top: 3px; --bar-width: 145px }
  @media ${props => props.theme.device.tablet}
  { margin-top: 5px; --bar-width: 90px }
  @media ${props => props.theme.device.mobile}
  { margin-top: 4px; --bar-width: 107px }

  .bar {
    height: 8px;
    display: block;
    border-radius: 4px;
  }

  .bar.bg {
    width: var(--bar-width);
    background-color: ${props => props.theme.colors.antiFlashWhite};
  }

  .bar.fg {
    width: ${props => `calc(var(--bar-width) * ${props.progress})`};
    background: ${props => (props.progress < 1)
        ? props.theme.colors.bitterLemon
        : props.theme.colors.bitterLemon
    };
  }
`;

export const EventCard = styled(Card)`
  color: ${props => props.theme.colors.white};

  ${centeredImage}

  ${props => props.homepage
      ? EventCardHome
      : EventCardDetail
  }
  &.link{
    background: linear-gradient(180deg, rgba(255,255,255, 0) 0%, rgba(255,255,255, 0.5) 96.81%), url(${props => props.bg});
  }
`;

const EventCardHome = css`
  width: 270px;
  height: 306px;

  @media ${props => props.theme.device.tablet} {
    width: 220px;
    height: 250px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 170px;
    height: 190px;
  }
`;

const EventCardDetail = css`
  width: 300px;
  height: 340px;

  @media ${props => props.theme.device.desktop} {
    width: 270px;
    height: 306px;
  }

  @media ${props => props.theme.device.laptop} {
    width: 220px;
    height: 250px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 170px;
    height: 190px;
  }
`

export const Logo = styled.img`
    --height: 130px;

    @media ${(props) => props.theme.device.mobile} {
      height: 30px;
    }

  --height: 70px;
  
  /* height: var(--height); */
  width: calc(var(--height) / 3 * 5);

  @media ${(props) => props.theme.device.tablet} {
    height: 50px;
    width: auto;
    margin-right: auto;
  }
`

export const EventHeader = styled.div`
position: relative;
  ${props => props.homepage
      ? EventHeaderHome
      : EventHeaderDetail
  }
`;

const EventHeaderHome = css`
  padding: 18px 13px 0;

  @media ${props => props.theme.device.tablet} {
    padding: 15px 11px 0;
  }

  @media ${props => props.theme.device.mobile} {
    padding: 11px 9px 0;
  }
`;

const EventHeaderDetail = css`
  padding: 20px 15px 0;

  @media ${props => props.theme.device.desktop} {
    padding: 18px 13px 0;
  }

  @media ${props => props.theme.device.laptop} {
    padding: 15px 11px 0;
  }

  @media ${props => props.theme.device.mobile} {
    padding: 11px 9px 0;
  }
`

export const ColorLabel = styled.div`
  display: flex;
  justify-content center;
  align-items: center;
  max-width: ${props => props.large ? '132px' : '19px'};
  height: 19px;
  border-radius: 4px;
  background-color: ${props => props.bgColor};

    // @media ${props => props.theme.device.tablet} { width: 132px; height: 19px; }
    // @media ${props => props.theme.device.mobile} { width: 132px; height: 19px; }
`;

export const EventFooter = styled.div`
  padding: 0 13px 27px;
  --children-margin: 17px;

  @media ${props => props.theme.device.tablet} {
    padding: 0 11px 22px;
    --children-margin: 14px;
  }
  @media ${props => props.theme.device.mobile} {
    padding: 0 8px 17px;
    --children-margin: 11px;
  }

  & > *:not(:last-child) {
    margin-bottom: var(--children-margin);
  }
  
  &.link{
    padding-bottom: 50px;
    img{
      height: 50px;
      width: auto;
      @media ${props => props.theme.device.mobile} {
        height: 30px;
      }
    }
  }
`;

export const ExpirationDate = styled.div`
  position: absolute;
  top: 10px;
  left:10px;
  background #161618;
  height: 20px;
  border-radius: 10px;
  padding: 0 10px;
  display: flex;
  * {
  line-height: 20px;
  display: inline-block;
  font-size:12px;
  }
  .until{
      color: #FF2020;
      margin-right: 5px;
  }
`;
