import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';
import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon
} from './style';

const modalId = 'DeleteNewsFeedModal';

const DeleteNewsFeedModal = ({ onClose, submit }) => {

  const onSubmit = () => {
    onClose();
    submit();
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Title>
                <ProDisplay type="modalTitle">
                  Vuoi davvero cancellare questo elemento?
                </ProDisplay>
              </Title>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button
                    onClick={onClose}
                    type={Button.TYPE.SECONDARY}
                    text="Annulla"
                  />
                </BtnContainer>
                <BtnContainer>
                  <Button
                    onClick={onSubmit}
                    type={Button.TYPE.PRIMARY}
                    text="Cancella"
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

DeleteNewsFeedModal.propTypes = {
  onClick: PropTypes.func,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func
};

DeleteNewsFeedModal.id = modalId;
export default connect(
  state => {
    return state;
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(DeleteNewsFeedModal);