import styled from 'styled-components/macro';
import { FooterHeight } from 'const';

const HeaderHeight = '70px';

export const HeadWrapper = styled.div`
  width: 100%;
  height: ${HeaderHeight};
  background-color: ${props => props.theme.colors.primary.base};
  z-index: 2;
`;

export const Head = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 40px;
  cursor: pointer;
  width: auto;
  min-width: 200px;
  max-width: 260px;
  &.no-pointer {
    cursor: default;
  }
`;

export const MaintenanceContainer = styled.div`
  height: calc(100vh - ${HeaderHeight} - ${FooterHeight}px);
  background-color: ${props => props?.bgColor ? props.bgColor : props.theme.colors.transparent};

  @media ${props => props.theme.device.mobile} {
    min-height: calc(100vh - ${HeaderHeight});
  }
`

export const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
    position: relative;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 3.5rem;
  padding-bottom: 14px;
  word-break: break-word;
  text-align: center;
  color: ${props => props.theme.colors.darkCharcoal};

  @media ${props => props.theme.device.mobile} {
    font-size: 2.5rem;
  }
`;
