import styled,  { css }  from 'styled-components/macro';
import { customScrollbar } from 'utils/common';
import { checkRounded } from 'ui/scss/app.scss';

export const HeaderWrapper = styled.header`
  min-height: 70px;
  background-color: ${props => props.theme.colors.moonstone};

  @media ${props => props.theme.device.mobile} {
    min-height: ${props => (props.showSurvey ? '140px' : '70px')};
  }
`;

export const Header = styled.header`
  min-height: 70px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    min-height: ${props => (props.showSurvey ? '140px' : '70px')};
    flex-direction: column;
  }
`;

export const FirstHalfWrapper = styled.div`
  min-height: 70px;

  @media ${props => props.theme.device.mobile} {
    width: 100vw;
    border-bottom: 1px solid white;
  }
`;

export const FirstHalf = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
`;

export const HeaderIcon = styled.div`
  width: 70px;
  color: ${props => props.theme.colors.white};
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  &.first {
    transform: rotate(180deg);
  }
`;

export const IconInfo = styled.div`
  margin-left: 5px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary.darker};
  border-radius: 100%;
`;

export const Path = styled.span``;

export const Title = styled.div`
  min-height: 70px;
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  padding: 10px 32px;

  @media ${props => props.theme.device.mobile} {
    min-width: calc(100% - 70px);
    padding: 10px 12px;
  }
`;

export const SurveyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  height: 100%;
  min-width: 260px;
  flex-grow: 2;

  &.package{
    justify-content: flex-start;
  }
  @media ${props => props.theme.device.mobile} {
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
    min-height: 70px;
  }
`;

export const ButtonWrapper = styled.div`
  min-width: 95px;
  height: 35px;
  margin-left: 20px;

  @media ${props => props.theme.device.mobile} {
    min-width: unset;
    max-width: 50%
  }
`;

export const SecTitle = styled.div`
  margin-bottom: 16px;
`;

export const Text = styled.div`
  &.flex {
    display: flex;
    align-items: center;
  }
  &.flex-start {
    display: flex;
    justify-content: flex-start;
  }

  @media ${props => props.theme.device.mobile} {
    width: 90%;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px 0;

  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
  }
`;

export const VideoContainer = styled.div`
  &.no-padding {
    padding: 0;
  }
`;
export const CollectionContainer = styled.div`
  max-height: 480px;
  overflow: auto;
  padding-right: 0;
  ${customScrollbar()}
`;

export const Video = styled.div`
  background-image: url(${props => props.cover});
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 56%;

  & button.vjs-big-play-button {
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 24px;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    color: ${props => props.theme.colors.white};
    font-size: 55px;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    border: none;
  }
  & .vjs-poster {
    background-size: cover;
  }
`;

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const VideoTitle = styled.div`
  margin: 11px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconBookmark = styled.div`
  color: ${props => props.theme.colors.lightSilver};
  font-size: 30px;
  /* padding-right: 12px; */
  cursor: pointer;

  &.selected {
    color: ${props => props.theme.colors.primary.base};
  }
`;

export const VideoRow = styled.div`
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${props =>
    (props.noBorder ? 0 : `1px solid ${props.theme.colors.platinum}`)};

  @media ${props => props.theme.device.mobile} {
    padding: 16px 0;
  }

  & div:first-child {
    flex-grow: 2;
  }

  &.icons-container{
      justify-content: flex-end;
      .unset-grow{
        flex-grow: 0!important;
      }
  }

  .completedLabel{
    max-width: 100px;
    margin-left: 0;
    margin-right: auto;
  }
`;

export const VideoCol = styled.div`
  &.flex {
    display: flex;
    align-items: center;
  }
`;

export const BtnContainer = styled.div`
  min-width: 95px;

  button {
    @media ${props => props.theme.device.mobile} {
      padding: 0;
      min-width: 77px;
      max-width: 77px;
    }
  }

  @media ${props => props.theme.device.mobile} {
    min-width: unset;
    max-width: 50%;
  }
`;

export const IconPlay = styled.span`
  background-color: ${props => props.theme.colors.americanSilver};
  color: ${props => props.theme.colors.white};
  padding-left: 4px;
  font-size: 12px;
  line-height: 18px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  margin-right: 7px;

  &.small {
    padding-left: 3px;
    font-size: 8px;
    line-height: 13px;
    width: 13px;
    height: 13px;
  }
`;

export const IconClock = styled.span`
  color: ${props => props.theme.colors.philippineGray};
  font-size: 18px;
  width: 18px;
  height: 18px;
  margin-right: 7px;
`;
export const Completed = styled.div`
  min-width: 97px;
  height: 20px;
  margin-left: 35px;
  background-color: ${props => props.theme.colors.primary.lighter};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LikeWrapper = styled.div`
  width: 16px;
`;

export const Like = styled.div`
  color: ${props => props.theme.colors.lightSilver};
  font-size: 30px;
  /* font-weight: 600; */
  /* width: 30px;
  height: 30px; */
  padding: 0 6px;
  cursor: pointer;

  &.selected {
    color: ${props => props.theme.colors.primary.darker};
  }

`;

export const Dislike = styled(Like)`
  transform: rotate(180deg);
  padding: 0 6px;
`;

export const CollectionItem = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lightSilver};
  width: 100%;
  min-width: 420px;
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;

  &.active {
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
  }

  @media ${props => props.theme.device.laptop} {
    min-width: 320px;
  }
`;

export const Index = styled.div`
  width: 8%;
  text-align: center;
`;

export const Image = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 6px;
  object-fit: cover;
  object-position: left;
`;

export const Description = styled.div`
  width: 60%;
`;

export const IconCheckCustom = styled.div`
  margin: 0 15px 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    ${props => props.theme.colors.primary.base},
    ${props => props.theme.colors.primary.lighter}
  );
`;

export const InnerIcon = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary.base};
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconPlayVideo = styled.div`
  width: 80px;
  height: 80px;
  padding: 10px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  color: ${props => props.theme.colors.white};
  font-size: 60px;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
`;

export const ExternalResource = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  position: absolute;
  top: 0;
`;


export const Link = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    border: none;
    color: ${props => props.theme.colors.white};
    min-height: 35px;
    transition: all 0.3s;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    white-space: nowrap;
    letter-spacing: normal;
    padding: 22px 80px;
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary.base};
    span{
    line-height: 1.3;
    }
`;

export const SharedWrapper = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: calc(100% - 200px);

  @media ${props => props.theme.device.laptop} {
    max-width: calc(100% - 80px);
  }

  @media ${props => props.theme.device.tablet} {
    max-width: 100%;
  }
`;


export const HtmlWrapper = styled.div`
  width: 100%;
  min-height: min-content;
  p {
    margin-bottom: 10px;
  }

  & h5 {
    font-size: 20px;
  }

  & ul {
    margin-top: 23px;
  }

  & li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
  }

  & li::after {
    content: ${checkRounded};
    font-family: 's4b' !important ;
    font-size: 20px;
    height: 20px;
    color: ${props => props.theme.colors.primary.base};
    position: absolute;
    top: -1px;
    left: 0;
  }

  ${props =>
    props.withEllipsis &&
    css`
      & p:after {
        content: ' [...]'};
      }
    `}
`;