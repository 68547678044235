import { takeLatest, put, call, select /*, delay*/ } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';

import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  ADD_COMMENT,
  ADD_NOTE,
  ADD_PARTECIPANTS,
  DELETE_NOTE,
  EDIT_NOTE,
  GET_NOTES,
  GET_USERS,
  GET_ALL_USERS,
} from 'redux/actions/notes';
import notes from 'api/notes';

const getNewUsers = (allUsers, oldUsers) => {
  const newUsers = allUsers.filter(
    u => oldUsers.findIndex(oldUser => oldUser === u) < 0
  );
  return newUsers;
};

function* getNotes() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { data } = yield call(notes.getNotes, usrId);

    yield put({
      type: GET_NOTES._SUCCESS,
      data,
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > getNotes', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNotesWatch() {
  yield takeLatest(GET_NOTES._REQUEST, getNotes);
}

function* addNote({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    // const { user: { userId } = {} } = yield select(
      //   state => state.authentication
    // );
    // payload.owner = userId;
    const response = yield call(notes.addNote, payload);
    if (response.status === 200 || response.resultcode?.toString() === '200') {
      yield put({
        type: ADD_NOTE._SUCCESS,
        data: response.data,
      });
      yield put({ type: GET_NOTES._REQUEST });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > addNote', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addNoteWatch() {
  yield takeLatest(ADD_NOTE._REQUEST, addNote);
}

function* editNote({ payload, oldUsers }) {
  try {
    yield put({ type: LOADER_OPEN });
    //payload.owner = payload.owner.userId;
    const { users: allUsers } = payload;
    const newUsers = getNewUsers(allUsers, oldUsers);
    const body = {
      participantList: newUsers.map(u => ({ userId: u })),
    };

    if (newUsers.length > 0) {
      const resAddPart = yield call(
        notes.addPartecipants,
        payload.id,
        payload.owner,
        body
      );
      if (resAddPart.status === 200) {
        yield put({ type: ADD_PARTECIPANTS._SUCCESS, data: resAddPart.data });
      }
    }

    const { status, data } = yield call(notes.editNote, payload);
    if (status === 200 || data.resultcode.toString() === '200') {
      yield put({
        type: EDIT_NOTE._SUCCESS,
        data,
      });
      yield put({ type: GET_NOTES._REQUEST });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > editNote', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* editNoteWatch() {
  yield takeLatest(EDIT_NOTE._REQUEST, editNote);
}

function* getUsers({ nextPage = null }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      user: { userId: usrId, organizationId: orgId } = {},
    } = yield select(state => state.authentication);
    const { users = [], followed = [], nextPagePresent } = yield select(
      state => state.social
    );
    if (nextPagePresent === false && users.length > 0) {
      const data = {
        allusers: [],
        followed,
        nextpagepresent: false,
        nextpage: null,
      };
      yield put({
        type: GET_USERS._SUCCESS,
        data,
      });
    } else if (nextPage || (nextPage === null && users.length === 0)) {
      // if (nextPage !== null) yield delay(1500);
      const { status, data } = yield call(
        notes.getUsers,
        usrId,
        orgId,
        nextPage
      );
      if (status === 200 || data.resultcode.toString() === '200') {
        yield put({
          type: GET_USERS._SUCCESS,
          data,
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > getUsers', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getUsersWatch() {
  yield takeLatest(GET_USERS._REQUEST, getUsers);
}

function* getAllUsers() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId, organizationId: orgId } = {} } = yield select(state => state.authentication);
    let nextpage = null; let nextpagepresent = true;
    while (nextpagepresent) {
      const { status, data } = yield call(notes.getUsers, usrId, orgId, nextpage);
      if (status !== 200 || data.resultcode.toString() !== '200') throw new Error('Errore di caricamento');
      yield put({ type: GET_USERS._SUCCESS, data });
      ({ nextpagepresent, nextpage } = data);
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: 'Errore di caricamento' },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > getAllUsers', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getAllUsersWatch() {
  yield takeLatest(GET_ALL_USERS._REQUEST, getAllUsers);
}

function* deleteNote({ id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { status, data } = yield call(notes.deleteNote, id/*, usrId*/);
    if (status === 200 || data.resultcode?.toString() === '200') {
      yield put({
        type: DELETE_NOTE._SUCCESS,
        id,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > getNotes', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* deleteNoteWatch() {
  yield takeLatest(DELETE_NOTE._REQUEST, deleteNote);
}

function* addPartecipants({ noteId, body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const parsedBody = {
      participantList: body.map(u => ({ userId: u })),
    };
    if (parsedBody.participantList?.length > 0) {
      const { status, data } = yield call(
        notes.addPartecipants,
        noteId,
        //usrId,
        parsedBody
      );
      if (status === 200 || data.resultcode.toString() === '200') {
        yield put({
          type: ADD_PARTECIPANTS._SUCCESS,
        });
        yield put({
          type: GET_NOTES._REQUEST,
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > addPartecipants', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addPartecipantsWatch() {
  yield takeLatest(ADD_PARTECIPANTS._REQUEST, addPartecipants);
}

function* addComment({ idNote, body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const payload = {
      idNote,
      //owner: usrId,
      body,
      pic: '',
    };
    const { status, data } = yield call(notes.addComment, payload);
    if (status === 200 || data.resultcode.toString() === '200') {
      yield put({
        type: ADD_COMMENT._SUCCESS,
        data,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > notes > addComment', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addCommentWatch() {
  yield takeLatest(ADD_COMMENT._REQUEST, addComment);
}

export default [
  getNotesWatch(),
  addNoteWatch(),
  editNoteWatch(),
  getUsersWatch(),
  getAllUsersWatch(),
  deleteNoteWatch(),
  addPartecipantsWatch(),
  addCommentWatch(),
];
