import produce from 'immer';
import {
  PROFILE_DATA_COMPANY_GET,
  PROFILE_DATA_COMPANY_SET,
  PROFILE_DATA_COMPANY_UPDATE,
  PROFILE_DATA_INITIAL_STATE,
  PROFILE_DATA_PAYMENTS_GET,
  PROFILE_GET_DATA_PRIVACY,
  PROFILE_POST_DATA_PRIVACY,
  PROFILE_SELECTED_DATA_PAYMENT,
  PROFILE_USER_PICTURE_SET,
} from 'redux/actions/profile';
import { USERTYPE_UPDATE } from 'redux/actions/signup';

const initialState = {
  billing: undefined,
  company: undefined,
  selected_payment: undefined,
  profilePic: undefined,
  pathProgress: undefined,
  privacy: {
    dataPrivacylist: [],
    organizationType: undefined,
  },
};

export const findPaymentInAssocSubscription = (
  paymentId = '',
  assoc_subscriptions = []
) => {
  let check = 0;
  (assoc_subscriptions || []).forEach(
    ({ field_payment_method: paymentMethod = [] } = {}) => {
      const pmInd = paymentMethod.findIndex(
        ({ id = '' } = {}) => id?.toString() === paymentId.toString()
      );
      check += pmInd >= 0 ? 1 : 0;
    }
  );
  return check > 0;
};

const profileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case PROFILE_DATA_COMPANY_UPDATE._SUCCESS:
      case PROFILE_DATA_COMPANY_GET._SUCCESS:
      case PROFILE_DATA_COMPANY_SET: {
        const { company } = action;
        draft.company = {
          ...state.company,
          ...company,
        };
        break;
      }

      case PROFILE_DATA_PAYMENTS_GET._SUCCESS: {
        const { payments: { orders = [], nextpage, nextpagepresent } } = action;
        draft.billings = {
          ...state.billings,
          orders: state.billings?.orders ? [...state.billings?.orders, ...orders] : orders,
          nextpage,
          nextpagepresent,
        };
        break;
      }

      case PROFILE_SELECTED_DATA_PAYMENT: {
        const { payment } = action;
        draft.selected_payment = payment;
        break;
      }

      case PROFILE_DATA_INITIAL_STATE: {
        draft.billings = initialState.billings;
        draft.company = initialState.company;
        draft.selected_payment = initialState.selected_payment;
        break;
      }

      case PROFILE_USER_PICTURE_SET:
        draft.profilePic = action.image || initialState.profilePic;
        break;

      case USERTYPE_UPDATE: {
        const { userType } = action;
        draft.privacy.organizationType = userType;
        break;
      }

      case PROFILE_GET_DATA_PRIVACY._SUCCESS:
      case PROFILE_POST_DATA_PRIVACY._SUCCESS:
        draft.privacy = action.payload;
        break;

      default:
        return state;
    }
    return draft;
  });

export default profileReducer;
