import styled from "styled-components/macro";
import { PackageProgress } from "ui/components/ContentCardUser/style";
import { Container as GridContainer } from 'ui/gridSystem';

export const PackageHeader = styled.div`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => `
    linear-gradient(90deg, rgba(0, 0, 0, 0.6) 2.24%, rgba(0, 0, 0, 0.174) 85.09%),
    url(${props.bg})
  `};
  background-size: cover;
  background-position: center;

  .bold {
    font-weight: 700;
  }
`;

export const Container = styled(GridContainer)`
  .react-multi-carousel-list {
    position: unset !important;
  }
`; 

export const PackageProgresss = styled(PackageProgress)`
  justify-content: flex-start;
`;

export const Section = styled.div`
  padding-top: 25px;

  &.gray-bg {
    position: relative;
    padding-bottom: 90px;

    @media ${(props) => props.theme.device.mobile} {
      padding-bottom: 50px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(-50vw);
      right: calc(-50vw);

      background: ${(props) => props.theme.colors.cultured};
    }
    * {
      z-index: 1;
      position: relative;
      border: none;
    }
  }

  .link-doc-row {
    padding: 0 45px;
    min-height: 150px;

    .link-doc-row__links,
    .link-doc-row__docs {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .value, .item {
        display: flex;
        align-items: center;
      }
    }

    .item {
      display: flex;
      width: 100%;

      &:last-of-type {
        margin-top: 28px;
      }
      
      &:last-child {
        margin-bottom: 0;
      }
      .value {
        word-break: break-word;
        a {
          text-decoration: none;
          span {
            line-height: 24px;
            color: ${(props) => props.theme.colors.darkCharcoal};
            text-decoration: none;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .icon {
        margin-right: 10px;
      }
      .icon-icona-allega {
        font-size: 25px;
      }
      a {
        color: ${(props) => props.theme.colors.links};
        text-decoration: underline;
        line-height: 24px;
      }
      .icon-download-link {
        text-decoration: none;
      }
      .icon-download {
        color: ${(props) => props.theme.colors.black};
      }
    }
    .link-doc-row__links {
      position: relative;
      padding-right: 40px;

      @media ${(props) => props.theme.device.mobile} {
        padding-bottom: 32px;
        padding-right: 0px;
        padding-left: 0px;
      }

      &:after {
        content: "";
        display: block;
        width: 1px;
        background: ${(props) => props.theme.colors.lightSilver};
        position: absolute;
        right: 0;
        top: 20px;
        bottom: 20px;
        height: calc(100% - 40px);

        @media (max-width: 767px) {
          bottom: 0;
          left: 30%;
          right: 30%;
          top: auto;
          width: auto;
          height: 1px;
        }
        @media ${(props) => props.theme.device.mobile} {
          bottom: 0;
          left: 30%;
          right: 30%;
          top: auto;
          width: auto;
          height: 1px;
        }
      }
    }
    .link-doc-row__docs {
      padding-left: 40px;
      padding-right: 0;

      @media ${(props) => props.theme.device.mobile} {
        padding-bottom: 32px;
        padding-top: 32px;
        padding-left: 0px;
      }

      .item {
        justify-content: center;
        line-height: 27px;
        .value {
          margin-right: auto;
          a {
            color: ${(props) => props.theme.colors.darkCharcoal};
            text-decoration: none;
          }
        }
      }
    }
  }
`;

// export const Title = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   margin-bottom: 15px;
// `;

export const Link = styled.a`
  padding-right: 20px;
  &.transparent {
    color: transparent;
  }
`;

export const NoMaterials = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 30px 0 60px;
`;

// export const Text = styled.div`
//   padding: 0 16px 0 0;

//   &.align-right {
//     margin-left: auto;
//     cursor: pointer;
//   }
// `;

export const PackageFooterCard = styled.div`
  color: ${(props) => props.theme.colors.white};
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  background: ${(props) => `
    linear-gradient(90deg, rgba(0, 0, 0, 0.6) 2.24%, rgba(0, 0, 0, 0.174) 85.09%),
    url(${props.bg})
  `};
  border-radius: 23px;
  background-size: cover;
  background-position: center;
  padding: 47px 68px;
  margin: 78px 30px 30px 30px;

  @media ${(props) => props.theme.device.mobile} {
    margin-left: 0;
    margin-right: 0;
    padding: 68px 25px;
  }

  .col-12 div {
    @media ${(props) => props.theme.device.mobile} {
      width: 100%
    }
  }
`;
