import React from 'react';
import PropTypes from 'prop-types';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { TableCard } from 'ui/components';
import { SubscriptionsInterface } from 'interface/subscriptions';
import {
  Content,
  Labels,
} from 'pages/MySubscriptions/sections/Subscriptions/ActiveSubscriptions/style';
import { Col, Row } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { getVariation } from 'redux/reducers/subscriptions';

const OldSubscriptions = ({
  subscriptions,
  getFilteredArray,
  assocSubscriptions,
  currentViewport,
  viewportTypes,
}) => {
  const getVariationOffer = objCheck => getVariation(objCheck, assocSubscriptions);
  const isProspectValid = (email, subscriptionId, variationId) => {
    const subscription_checking = subscriptions.find(p => p.email === email);
    if (!subscription_checking?.offerts) return true;
    // check intersection of subscription_tocheck.offerts and subscripiton_checking.offerts
    return subscription_checking.offerts?.every(checking => (
      checking.subscriptionId !== subscriptionId
      && checking.variationId !== variationId
    ))
  };

  return (
    <Content>
      <Labels>
        <Row>
          <Col xs={12} md={3}>
            <ProDisplay type="tableLabel">Utenti</ProDisplay>
          </Col>
          {(viewportTypes[currentViewport] > viewportTypes.mediaIsTablet) ? (
            <>
              <Col md={3}>
                <ProDisplay type="tableLabel">Pacchetti Base</ProDisplay>
              </Col>
              <Col md={3}>
                <ProDisplay type="tableLabel">Pacchetti Premium<span className='icon-premium'></span></ProDisplay>
              </Col>
            </>
          ) : null          }
        </Row>
      </Labels>
      {subscriptions?.length > 0 &&
        (getFilteredArray(subscriptions) || []).map((subscription, index) => (
          <TableCard
            key={`subscription-${index}`}
            tableKey={`subscription-${index}`}
            subscription={subscription}
            getVariationOffer={getVariationOffer}
            isProspectValid={isProspectValid}
          />
        ))}
    </Content>
  );
};

OldSubscriptions.propTypes = {
  subscriptions: SubscriptionsInterface,
  assocSubscriptions: PropTypes.object,
  getFilteredArray: PropTypes.func,
};

export default withMediaQueries(OldSubscriptions);
