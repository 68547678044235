import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { GET_FIVE_MINUTES } from 'redux/actions/fiveMinutes';
import {
  GET_CATEGORIES_USER,
  GET_COLLECTIONS,
  GET_EVENTS,
  GET_KEEP_WATCHING,
  GET_PACKAGES,
  GET_RECOMMENDED_COLLECTIONS,
  GET_SELECTIONS,
  SET_CATEGORIES_FILTER,
  SET_PLAYLIST_HISTORY,
  SET_SECTION,
  RESET_PLAYLIST,
  GET_CATEGORIES_PILLS,
  GET_CATEGORY_PLAYLIST
} from 'redux/actions/playlist';
import routes from 'routes';
import { detailType } from 'utils/common';
import { getFirstLastDayOfMonth } from 'utils/date-format';
import UkFlag from 'ui/assets/img/uk_flag.svg';

/**
 * custom hook to fetch the config objects for the sections of the homepage
 * @param currentViewport the current viewport key
 * @param viewportTypes the map of available viewports
 * @returns a config object with a list of sections to display.
 */
const useSections = (currentViewport, viewportTypes) => {
  // HOC, state
  const categories = useSelector(state => state.playlist.categories);
  const categoriesPills = useSelector(state => state.playlist.categoriesPills);
  const collections = useSelector(state => state.playlist.collections.items);
  const domainSettings = useSelector(state => state.domain.domain_settings);
  const domainId = useSelector(state => state.domain.id);
  const domainTitle = useSelector(state => state.domain.title);
  const englishPlatform = useSelector(state => state.authentication.user?.userDomainAttributes?.englishPlatform);
  const events = useSelector(state => state.playlist.events.plannedEvents);
  const fiveMinutes = useSelector(state => state.fiveMinutes.fiveMinutes);
  const keepWatching = useSelector(state => state.playlist.keepWatching);
  const packages = useSelector(state => state.playlist.packages.packageList);
  const recommendedCollections = useSelector(state => state.playlist.recommendedCollections.items);
  const router = useSelector(state => state.router);
  const selections = useSelector(state => state.playlist.selections.items);
  // HOC, dispatch
  const dispatch = useDispatch();
  const getCategories = useCallback(() => dispatch({ type: GET_CATEGORIES_USER._REQUEST }), [dispatch]);
  const getCategoriesPrelios = useCallback(() => dispatch({ type: GET_CATEGORIES_PILLS._REQUEST }), [dispatch]);
  const getCategoryPlaylist = useCallback((filterId) => dispatch({ type: GET_CATEGORY_PLAYLIST._REQUEST, filterId }), [dispatch]);
  const getCollections = useCallback(() => dispatch({ type: GET_COLLECTIONS._REQUEST }), [dispatch]);
  const getRecommendedCollections = useCallback(() => dispatch({ type: GET_RECOMMENDED_COLLECTIONS._REQUEST }), [dispatch]);
  const getEvents = useCallback(({ startDate, endDate }) => dispatch({ type: GET_EVENTS._REQUEST, startDate, endDate }), [dispatch]);
  const getFiveMinutes = useCallback(() => dispatch({ type: GET_FIVE_MINUTES._REQUEST, forceReload: true }), [dispatch]);
  const getKeepWatching = useCallback(() => dispatch({ type: GET_KEEP_WATCHING._REQUEST }), [dispatch]);
  const getPackages = useCallback(() =>  dispatch({ type: GET_PACKAGES._REQUEST }), [dispatch]);
  const getSelections = useCallback(() => dispatch({ type: GET_SELECTIONS._REQUEST }), [dispatch])
  const pushUrl = (url) => dispatch(push(url));
  const setFilters = useCallback((filters) => dispatch({ type: SET_CATEGORIES_FILTER, filters }), [dispatch]);
  const setPlaylistHistory = (source) => dispatch({ type: SET_PLAYLIST_HISTORY, source });
  const setSection = (section) => dispatch({ type: SET_SECTION, section });
  const resetPlaylist = useCallback(() => dispatch({ type: RESET_PLAYLIST._SUCCESS }), [dispatch]);
  useEffect(() => {
    console.log("ran useSections init hook")
    switch(domainId) {
      //Prelios
      case 301:
      case 2485:
        {
          resetPlaylist(); //clear playlists
          getSelections();
          getPackages();
          getCollections();
          const { firstDay, lastDay } = getFirstLastDayOfMonth();
          getEvents({
            // startDate: getIsoDateStringFromDate(firstDay),
            // endDate: getIsoDateStringFromDate(lastDay),
            startDate: firstDay.getTime(),
            endDate: lastDay.getTime(),
          });
          getRecommendedCollections();
          getFiveMinutes();
          getKeepWatching();
          getCategoriesPrelios();
          getCategories();
          setFilters({ filterId: undefined, name: '' });
        }
        break;
      default:
        {
          getFiveMinutes();
          getPackages();
          const { firstDay, lastDay } = getFirstLastDayOfMonth();
          getEvents({
            // startDate: getIsoDateStringFromDate(firstDay),
            // endDate: getIsoDateStringFromDate(lastDay),
            startDate: firstDay.getTime(),
            endDate: lastDay.getTime(),
          });
          getKeepWatching();
          getCategories();
          setFilters({ filterId: undefined, name: '' });
        }
    }
  }, [
    domainId,
    getCategories,
    getCollections,
    getEvents,
    getFiveMinutes,
    getKeepWatching,
    getPackages,
    getRecommendedCollections,
    getSelections,
    setFilters,
  ]);

  const bannerSection = {
    bannerImage: domainSettings?.english_course_platform_image,
    bannerLink: domainSettings?.english_course_platform_url,
    bannerLogo: UkFlag,
    bannerText: domainSettings?.english_course_platform_title,
    carouselCardClickHandler: null,
    carouselType: null,
    data: null,
    description: null,
    getData: null,
    grayBg: null,
    link: 'https://qa.learn.corporate.ef.com/',
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: null,
    type: 'banner',
    vertical: null,
  };

  const KnowBe4BannerSection = {
    bannerImage: domainSettings?.banner_course_platform_image,
    bannerLink: domainSettings?.banner_course_platform_url,
    bannerLogo: domainSettings?.banner_course_platform_logo,
    bannerText: domainSettings?.banner_course_platform_title,
    type: 'knowBe4Banner',
  };

  const categoriesSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: null,
    carouselType: null,
    data: categoriesPills, //Prelios
    description: null,
    getData: null,
    grayBg: null,
    link: null,
    pillsClickHandler: (pill) => {
      getCategoryPlaylist(pill)
      /*setFilters({
        filterId: pill.id,
        name: pill.title,
        filteredCollections: pill.items.map(i => ({ ...i, itemId: i.id })),
      });*/
      pushUrl(routes.allPlaylistsCategories.path);
    },
    shouldHide: !(Array.isArray(categoriesPills) && categoriesPills.length > 0),
    showCalendar: null,
    title: null,
    type: 'pills',
    vertical: null,
  };

  const collectionsSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: collections?.slice(0,8),
    description: 'Per ampliare le tue conoscenze, per sviluppare competenze e conoscenze complementari. Pensati per te, che vuoi andare “oltre”',
    getData: null,
    grayBg: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsCollections.path}`
    },
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(collections) && collections.length > 0),
    showCalendar: null,
    title: 'Formazione Complementare',
    type: 'carousel',
    vertical: null,
  };

  const eventsSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: null,
    carouselType: null,
    data: events?.slice(0,8),
    description: "Scopri tutti gli eventi in programma",
    getData: ({ action, activeStartDate, value, view } = {}) => {
      console.log("ran getDate for events section")
      const { firstDay, lastDay } = getFirstLastDayOfMonth(activeStartDate);
      getEvents({
        // startDate: getIsoDateStringFromDate(firstDay),
        // endDate: getIsoDateStringFromDate(lastDay),
        startDate: firstDay.getTime(),
        endDate: lastDay.getTime(),
      });
    },
    grayBg: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allEvents.path}`
    },
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(packages) && packages.some(pkg => pkg.packageType.toLowerCase() === 'premium')),
    showCalendar: (viewportTypes[currentViewport] > viewportTypes.mediaIsTablet),
    title: 'Eventi Live',
    type: 'calendar',
    vertical: viewportTypes[currentViewport] < viewportTypes.mediaIsLaptop,
  };

  const keepWatchingSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        type: detailType.COURSE,
        section: 'keepWatching',
      });
    },
    carouselType: detailType.COURSE,
    data: keepWatching,
    description: 'Completa i contenuti che hai iniziato',
    getData: null,
    grayBg: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allCourses.path}`
    },
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(keepWatching) && keepWatching.length > 0),
    showCalendar: null,
    title: 'Continua a guardare',
    type: 'carousel',
    vertical: null,
  };

  const newsSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'fiveMinutes',
        type: card.type === detailType.RELATED_COURSE
          ? detailType.COURSE
          : card.type,
        }
      )
    },
    carouselType: 'news',
    data: fiveMinutes,
    description: 'Soddisfa la tua curiosità: esplora la nostra vasta gamma di articoli per espandere le tue conoscenze!',
    link: {
      text: 'Vedi tutti',
      to: `${routes.allFiveMinutes.path}`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(fiveMinutes) && fiveMinutes.length > 0),
    showCalendar: null,
    title: 'News',
    type: 'carousel',
    vertical: null,
  };

  const packagesSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      pushUrl(`${routes.package.path}/${card.packageId}`)
    },
    carouselType: detailType.PACKAGE,
    data: packages,
    description: null,
    getData: null,
    grayBg: null,
    link: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(packages) && packages.length > 0),
    showCalendar: null,
    title: 'La tua formazione',
    type: 'carousel',
    vertical: null,
  };

  const recommendedCollectionsSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: recommendedCollections?.slice(0,8),
    description: 'Non accontentarti! Esplora ulteriori contenuti formativi scelti per te',
    getData: null,
    grayBg: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsRecommendedCollections.path}`
    },
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(recommendedCollections) && recommendedCollections.length > 0),
    showCalendar: null,
    title: 'Suggeriti per te',
    type: 'carousel',
    vertical: null,
  };

  const selectionsSections = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: selections?.slice(0,8),
    description: 'Qui trovi tutti i corsi da completare per soddisfare i requisiti di formazione del tuo programma',
    getData: null,
    grayBg: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsSelections.path}`
    },
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(selections) && selections.length > 0),
    showCalendar: null,
    title: 'Formazione Obbligatoria',
    type: 'carousel',
    vertical: null,

  };

  const preliosSections = [
    { ...selectionsSections, grayBg: true },
    eventsSection,
    collectionsSection,
    KnowBe4BannerSection,
    recommendedCollectionsSection,
    newsSection,
    keepWatchingSection,
    categoriesSection,
  ];
  if (englishPlatform) {
    preliosSections.splice(3, -1, bannerSection);
  };

  const s4cSections = [
    newsSection,
    { ...packagesSection, grayBg: true },
    { ...eventsSection, grayBg: true },
    { ...keepWatchingSection, grayBg: true },
    { ...categoriesSection },
  ];

  const sections = {
    Prelios: preliosSections,
    Skills4Capital: s4cSections,
  };

  switch(domainId) {
    case 301:
    case 2485:
      return sections['Prelios'];
    default:
      return sections['Skills4Capital']
  }

  function openDetails({id, type, section}) {
    setSection(section);
    setPlaylistHistory(router.location.pathname);
    const mappedType =
      ['COLLECTION', 'SELECTION'].includes(type)
        ? 'playlist'
        : type;
    pushUrl(`${routes.detail.path}/${mappedType}/${id}`);
  };
};

export default useSections;
