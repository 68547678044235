import registrationRoutes from 'routes';

export const menuRefs = {
  // TRAINING_OFFER: 'offertaformativa',
  // NEWS_EVENTS: 'newsedeventi',
  // EVENTS: 'events',
  // FILE_ARTICLE: 'filearticle',
  // CAROUSEL_SDS: 'storiedisuccesso',
  // INITIATIVES: 'slidersp',
  // CONTACTS: 'contacts',
};

const menu = [
  {
    label: 'Pacchetti attivi',
    link: registrationRoutes.allSubsriptions.path,
  },
  {
    label: 'La redazione consiglia',
    link: registrationRoutes.recommended.path,
  },
  {
    label: 'Al tuo team interessa',
    link: registrationRoutes.teamsInterest.path,
  },
  {
    label: 'Catalogo',
    link: registrationRoutes.s4bCatalog.path,
  },
];

export default menu;
