import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Button, Checkbox, RadioButton } from 'ui/atoms';
import ProgressBar from 'ui/atoms/ProgressBar';
import ProText from 'ui/typography/proText';
import { ADD_SURVEY, GET_QUESTIONS } from 'redux/actions/playlist';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Icon,
  Head,
  Form,
  TextArea,
  BtnContainer,
  Texts,
  BarContainer,
  KnobContainer,
  Knob,
  Spacer,
  Labels,
  Bottom,
  QuestionContainer,
  LikeWrapper,
  IconLike,
  IconDislike,
  CheckboxWrapper,
  CommentWrapper
} from './style';
import { ModalGlobalContainer } from '../Modal.style';
import Modal, { stopEvent } from '../Modal';

import { HtmlWrapper } from 'pages/CollectionDetails/style';

const modalId = 'AssessmentModal';

const AssessmentModal = ({
  onClose,
  getQuestions,
  survey,
  questions,
  addSurvey,
  surveyId,
  packageId,
  playlist,
  playlistId,
  type
}) => {
  const [step, setStep] = useState(0);
  const [stepMax, setStepMax] = useState();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (playlist.length) {
      const uncompletedLO = playlist.filter(lo => !lo.completed);
      if (uncompletedLO.length <= 0) getQuestions(surveyId);
    }
    if (playlist.type === 'collection'|| playlist.type === 'selection' || playlist?.packageType?.toLowerCase() === 'base' || playlist?.packageType?.toLowerCase() === 'premium'){
      getQuestions(surveyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist]);

  useEffect(() => {
    if (answers[step]) {
      if (Array.isArray(answers[step].answer)) {
        if (answers[step].answer.length > 0) setBtnDisabled(false);
        else setBtnDisabled(true);
      } else if (answers[step].answer) {
        setBtnDisabled(false);
      }
    }
  }, [answers, step]);

  useEffect(() => {
    if (questions) {
      const newAnswers = questions.map(qst => {
        return {
          id: qst.idQuestion,
          question: qst.question,
          hasComment: qst.hasComment,
          answer: qst.questionType === 'question_multiple' ? [] : '',
          comment: '',
          maxValue: qst.maxValue,
          minLabel: qst.minLabel,
          maxLabel: qst.maxLabel,
          questionType: qst.questionType
        };
      });
      setAnswers(newAnswers);
      setStepMax(questions.length);
    }
  }, [survey, questions]);

  const setComment = (value, qstIndex) => {
    const newAnswers = [...answers];
    newAnswers[qstIndex].comment = value;
    setAnswers(newAnswers);
  };

  const handleEvaluation = (index, qstIndex) => {
    const newValues = [...answers];
    newValues[qstIndex].answer = index + 1;
    setAnswers(newValues);
    setBtnDisabled(false);
  };

  const handleChangeInput = (value, qstIndex) => {
    const newAnswers = [...answers];
    newAnswers[qstIndex].answer = value;

    setAnswers(newAnswers);
    if (value) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const handleLike = (value, qstIndex) => {
    const newAnswers = [...answers];
    if (answers[qstIndex] === value) {
      newAnswers[qstIndex].answer = null;
    } else {
      newAnswers[qstIndex].answer = value;
    }

    setAnswers(newAnswers);
    if (newAnswers[qstIndex]) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const handleOnChange = (value, qstIndex) => {
    const newAnswers = [...answers];
    let index = newAnswers[qstIndex].answer.findIndex(a => a === value);
    if (index >= 0) {
      newAnswers[qstIndex].answer.splice(index, 1);
    } else {
      newAnswers[qstIndex].answer.push(value);
    }
    setAnswers(newAnswers);
  };

  const setSingleAnswer = (value, qstIndex) => {
    const newAnswers = [...answers];
    newAnswers[qstIndex].answer = value;
    setAnswers(newAnswers);
  };

  const onBack = () => {
    if (step === 0) onClose();
    else setStep(step - 1);
  };

  const onNext = () => {
    setBtnDisabled(true);
    setStep(step + 1);
  };

  const onSubmit = () => {
    const body = {
      title: survey.title,
      description: survey.description,
      itemType: type?.toUpperCase(),
      questions: answers
    };
    body.questions = body.questions.map(q => {
      if (q.questionType === 'question_multiple') q.answer = q.answer.join();
      if (q.questionType === 'question_like_dislike')
        q.answer = q.answer === 'like' ? 1 : 0;
      return q;
    });
    addSurvey({ body, packageId, playlistId, surveyId: survey.idItem, type: type });
    onClose();
  };

  const getQuestionByType = (question, qstIndex) => {
    switch (question.questionType) {
      case 'question_open':
        return (
          <QuestionContainer key={`q-${qstIndex}`}>
            <ProText type="label">
              <HtmlWrapper
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            </ProText>
            <TextArea
              onChange={e => {
                handleChangeInput(e.target.value, qstIndex);
              }}
              value={answers[qstIndex]?.answer || ''}
            />
          </QuestionContainer>
        );
      case 'question_evaluation':
        const knobs = [];
        for (let i = 1; i <= question.maxValue; i++) knobs.push(i);
        return (
          <QuestionContainer key={`q-${qstIndex}`}>
            <ProText type="label">
              <HtmlWrapper
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            </ProText>
            <KnobContainer>
              {knobs.map((item, index) => {
                return (
                  <>
                    <Knob
                      maxValue={question.maxValue}
                      key={`val-${qstIndex}-${index}`}
                      className={
                        index < answers[qstIndex]?.answer ? 'selected' : ''
                      }
                      onClick={() => {
                        handleEvaluation(index, qstIndex);
                      }}
                    />
                    {index !== question.maxValue - 1 && <Spacer />}
                  </>
                );
              })}
            </KnobContainer>
            <Labels>
              <ProText type="label">{question.minLabel}</ProText>
              <ProText type="dots">{' · '.repeat(25)}</ProText>
              <ProText type="label">{question.maxLabel}</ProText>
            </Labels>
            {question.hasComment === true && (
              <CommentWrapper>
                <ProText type="label">Inserisci un commento:</ProText>
                <TextArea
                  onChange={e => {
                    setComment(e.target.value, qstIndex);
                  }}
                  value={answers[qstIndex]?.comment}
                />
              </CommentWrapper>
            )}
          </QuestionContainer>
        );
      case 'question_like_dislike':
        return (
          <QuestionContainer key={`q-${qstIndex}`}>
            <ProText type="label">
              <HtmlWrapper
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            </ProText>
            <LikeWrapper>
              <IconLike
                className={
                  answers[qstIndex]?.answer === 'like'
                    ? 'icon-icona-like active'
                    : 'icon-icona-like'
                }
                onClick={() => handleLike('like', qstIndex)}
              />
              <IconDislike
                className={
                  answers[qstIndex]?.answer === 'dislike'
                    ? 'icon-icona-like active'
                    : 'icon-icona-like'
                }
                onClick={() => handleLike('dislike', qstIndex)}
              />
            </LikeWrapper>
            {question.hasComment === true && (
              <CommentWrapper>
                <ProText type="label">Inserisci un commento:</ProText>
                <TextArea
                  onChange={e => {
                    setComment(e.target.value, qstIndex);
                  }}
                  value={answers[qstIndex]?.comment}
                />
              </CommentWrapper>
            )}
          </QuestionContainer>
        );
      case 'question_single':
        return (
          <QuestionContainer key={`q-${qstIndex}`}>
            <ProText type="label">
              <HtmlWrapper
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            </ProText>
            {question?.answers?.length &&
              answers?.length &&
              question.answers.map((opt, index) => (
                <CheckboxWrapper key={`opt-${index}`}>
                  <RadioButton
                    label={opt.answer}
                    name={`question-${question.idQuestion}`}
                    onChange={() => setSingleAnswer(opt.answer, qstIndex)}
                    value={question.idQuestion}
                    checked={answers[qstIndex]?.answer === opt.answer}
                  />
                </CheckboxWrapper>
              ))}
            {question.hasComment === true && (
              <CommentWrapper>
                <ProText type="label">Inserisci un commento:</ProText>
                <TextArea
                  onChange={e => {
                    setComment(e.target.value, qstIndex);
                  }}
                  value={answers[qstIndex]?.comment}
                />
              </CommentWrapper>
            )}
          </QuestionContainer>
        );
      case 'question_multiple':
        return (
          <QuestionContainer key={`q-${qstIndex}`}>
            <ProText type="label">
              <HtmlWrapper
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            </ProText>
            {question?.answers?.length > 0 &&
              question.answers.map((opt, index) => (
                <CheckboxWrapper key={`opt-${index}`}>
                  <Checkbox
                    label={opt.answer}
                    onChange={() => handleOnChange(opt.answer, qstIndex)}
                    value={opt.answer}
                    checked={
                      answers[qstIndex]?.answer.find(a => a === opt.answer) ||
                      false
                    }
                  />
                </CheckboxWrapper>
              ))}
            {question.hasComment === true && (
              <CommentWrapper>
                <ProText type="label">Inserisci un commento:</ProText>
                <TextArea
                  onChange={e => {
                    setComment(e.target.value, qstIndex);
                  }}
                  value={answers[qstIndex]?.comment}
                />
              </CommentWrapper>
            )}
          </QuestionContainer>
        );
      default:
        break;
    }
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="90vh"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Head>
                <Texts>
                  <Title>
                    <ProDisplay type="modalTitle">{survey?.title}</ProDisplay>
                  </Title>
                  <Subtitle>
                    <ProDisplay type="label">
                    <HtmlWrapper
                        dangerouslySetInnerHTML={{ __html: survey?.description }}
                    />
                    </ProDisplay>
                  </Subtitle>
                </Texts>
              </Head>
              <BarContainer>
                <ProgressBar
                  percentage={(step / stepMax) * 100}
                  isAssessment
                  height={4}
                />
                <ProText type="barLabel">
                  {step + 1} di {stepMax}
                </ProText>
              </BarContainer>
              <Form>
                {questions?.length && getQuestionByType(questions[step], step)}
              </Form>
              <Bottom>
                <BtnContainer>
                  <Button text="Indietro" type="TERTIARY" onClick={onBack} />
                </BtnContainer>
                <BtnContainer>
                  <Button
                    text="Continua"
                    type="PRIMARY"
                    onClick={step + 1 === questions?.length ? onSubmit : onNext}
                    disabled={btnDisabled}
                  />
                </BtnContainer>
              </Bottom>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

AssessmentModal.propTypes = {
  surveyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  packageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  playlist: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  playlistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),

  // HOC (connect, state)
  title: PropTypes.string,
  description: PropTypes.string,
  questions: PropTypes.array,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  getQuestions: PropTypes.func,
  addSurvey: PropTypes.func
};

AssessmentModal.id = modalId;

export default connect(
  state => {
    const { survey } = state.playlist;
    const { questions } = state.playlist.survey;
    return {
      survey,
      questions
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    getQuestions: surveyId =>
      dispatch({ type: GET_QUESTIONS._REQUEST, surveyId }),
    addSurvey: payload => dispatch({ type: ADD_SURVEY._REQUEST, payload })
  })
)(AssessmentModal);
