import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {MODAL_CLOSE, MODAL_OPEN} from 'redux/actions';
import { ADD_NOTE, GET_NOTES, EDIT_NOTE, GET_USERS } from 'redux/actions/notes';

import useFileUpload from 'customHooks/useFileUpload';
import InfiniteScroll from 'react-infinite-scroll-component';
import app from 'api/app';

import { Button, Checkbox } from 'ui/atoms';
import ScrollLoader from 'ui/atoms/ScrollLoader';
import { ProfilePicture } from 'ui/components';
import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Icon,
  Head,
  IconUser,
  Form,
  Input,
  TextArea,
  BtnContainer,
  Texts,
  InputFile,
  Label,
  IconUplaod,
  ContentSearch,
  Results,
  UserWrapper,
  Name,
  ButtonsWrapper,
  UsersBar,
  UploadWrapper,
  Thumbnail,
  AddUserWrapper,
  CheckboxWrapper,
  EmptyMsg
} from './style';

import Modal, { stopEvent } from '../Modal';
import { ModalGlobalContainer } from '../Modal.style';
import ModalErrors from "../ModalErrors";
import SuccessModal from "../SuccessModal";

const modalId = 'NotesModal';

const emptyNote = {
  title: '',
  body: '',
  owner: {}, // only for edit, { familyName, givenName, imageUrl, userId }
  users: [], // same shape as state.notes.users
  imageUrl: '' //l'url dell'immagine salvata sui server
};

const NotesModal = ({
  notes,
  noteId,
  onClose,
  addNote,
  isEdit,
  editNote,
  getUsers,
  users,
  addUser,
  format,
  domainId,
  acceptedFiles,
  nextPagePresent,
  nextPage,
  openModalError,
  openSuccessModal
}) => {
  const [noteForm, setNoteForm] = useState(emptyNote);
  const [isSearch, setIsSearch] = useState(!!addUser);
  const [usersAdded, setUsersAdded] = useState([]);
  const [usersConfirmed, setUsersConfirmed] = useState([]);
  const [imageData, setImageData] = useState(''); //Data URL, ex. data:image/jpeg;base64,/9j/4AAQSkZ..
  const {
    acquiredFile: acquiredPicture,
    uploadedFile: uploadedPicture,
    fileIsHandled: pictureIsHandled,
    readFile: readPicture,
    resetFile: resetPicture,
    uploadFile: uploadPicture,
    hasError: fileError
  } = useFileUpload({ domainId });

  useEffect(() => {
    if (isEdit) {
      setNoteForm(notes.find(nt => nt.id === noteId));
      setUsersConfirmed(() => {
        const selNote = notes.find(nt => nt.id === noteId);
        if (selNote?.users) return selNote.users;
        return selNote?.partecipants;
      });
    } else {
      setNoteForm(emptyNote);
    }
  }, [noteId, notes, isEdit]);

  useEffect(() => {
    if (fileError ) {
      closeAndReset();
      openModalError({
        errorText:
            "Si è verificato errore imprevisto. Ci scusiamo per il disagio"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileError]);

  useEffect(() => {
    if (uploadedPicture ) {
      openSuccessModal({
        description: "Invio effettuato con successo.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedPicture]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    setIsSearch(addUser);
  }, [addUser]);

  useEffect(() => {
    setUsersAdded(usersConfirmed);
  }, [usersConfirmed]);

  useEffect(() => {
    pictureIsHandled && saveNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureIsHandled]);

  const handleUser = user => {
    const index = usersAdded.findIndex(u => u.userId === user.userId);
    const newUsers = [...usersAdded];
    if (index < 0) {
      newUsers.push(user);
    } else {
      newUsers.splice(index, 1);
    }
    setUsersAdded(newUsers);
  };

  const saveNote = () => {
    if (isEdit) {
      editNote(
        {
          ...noteForm,
          users: usersConfirmed.map(user => user.userId),
          imageUrl: uploadedPicture?.url ? uploadedPicture?.url : noteForm.imageUrl
        },
        noteForm.users
          ? noteForm.users?.map(u => u.userId)
          : noteForm.partecipants?.map(u => u.userId)
      );
    } else {
      addNote({
        ...noteForm,
        users: usersConfirmed.map(user => user.userId),
        imageUrl: uploadedPicture?.url ? uploadedPicture?.url : noteForm.imageUrl
      });
    }
    closeAndReset();
    openSuccessModal({
      description: "Invio effettuato con successo.",
    });
  };

  const onClickSave = () => {
    if (acquiredPicture) {
      uploadPicture({ endpoint: 'uploadFile?profile=false' });
    } else {
      saveNote();
    }
  };

  useEffect(() => {
    if (noteForm?.imageUrl) {
      try {
        app
          .getPicture(noteForm.imageUrl)
          .then(response => {
            const imageDecoded = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );

            setImageData(
              `data:${response.headers[
                'content-type'
              ].toLowerCase()};base64,${imageDecoded}`
            );
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('app.getPicture', err);
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }, [noteForm]);

  const checkBodyTitle = () => {
    let valid = true;
    valid =
      valid &&
      noteForm?.title &&
      noteForm?.title.length > 0 &&
      noteForm?.body &&
      noteForm?.body.length > 0;

    return !valid;
  };

  const fetchData = () => {
    getUsers(nextPage);
  };

  const closeAndReset = () => {
    setImageData('');
    setNoteForm(emptyNote);
    setUsersConfirmed([]);
    resetPicture();
    onClose();
  };

  return (
    <Modal id={modalId} onClose={closeAndReset}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="520px"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={closeAndReset} />
              <Head>
                <Texts>
                  <Title>
                    <ProDisplay type="modalTitle">Note</ProDisplay>
                  </Title>
                </Texts>
                <UsersBar>
                  {usersConfirmed?.length > 0 &&
                    usersConfirmed.slice(0, 5).map(prof => (
                      <ProfilePicture
                        key={prof.userId}
                        widthContainer="25px"
                        heightContainer="25px"
                        fontSizeInitials={14}
                        profile={{
                          pictureId: prof.imageUrl,
                          firstname: prof.givenName,
                          lastname: prof.familyName
                        }}
                      />
                    ))}
                  {usersConfirmed?.length > 5 && (
                    <ProDisplay
                      type="label"
                      configuration={{ color: 'primary', colorType: 'base' }}
                    >
                      + Altri {usersConfirmed?.length - 5}
                    </ProDisplay>
                  )}
                  <IconUser
                    className="icon-icona-add-user1"
                    onClick={() => {
                      setIsSearch(!isSearch);
                      setUsersAdded(usersConfirmed);
                    }}
                  />
                </UsersBar>
                <Subtitle>
                  <ProDisplay type="label">
                    Inserisci qua le tue note e condividile coi tuoi colleghi
                  </ProDisplay>
                </Subtitle>
              </Head>
              {isSearch && (
                <AddUserWrapper
                  onClick={() => {
                    setUsersConfirmed(usersAdded);
                    setIsSearch(false);
                  }}
                >
                  <ContentSearch onClick={e => e.stopPropagation()}>
                    <Results id="scrollableDiv">
                      <InfiniteScroll
                        dataLength={users?.length}
                        height={300}
                        next={fetchData}
                        hasMore={nextPagePresent}
                        loader={<ScrollLoader />}
                        scrollableTarget="scrollableDiv"
                      >
                        {users?.length > 0 ? (
                          users.map(user => (
                            <UserWrapper key={user.userId}>
                              <ProfilePicture
                                profile={{
                                  pictureId: user.imageUrl,
                                  firstname: user.givenName,
                                  lastname: user.familyName
                                }}
                                widthContainer="35px"
                                heightContainer="35px"
                                fontSizeInitials={14}
                              />
                              <Name>
                                <ProDisplay type="nameUsers">
                                  {user.givenName} {user.familyName}
                                </ProDisplay>
                              </Name>
                              <CheckboxWrapper>
                                <Checkbox
                                  checked={
                                    !!usersAdded?.find(
                                      u => u.userId === user.userId
                                    )
                                  }
                                  onChange={() => handleUser(user)}
                                  rounded
                                  disabled={
                                    !!usersAdded?.find(
                                      u => u.userId === user.userId
                                    )
                                  }
                                />
                              </CheckboxWrapper>
                            </UserWrapper>
                          ))
                        ) : (
                          <EmptyMsg>
                            <ProDisplay type="1">
                              Non sono al momento presenti utenti con cui
                              condividere la nota.
                            </ProDisplay>
                          </EmptyMsg>
                        )}
                      </InfiniteScroll>
                    </Results>
                  </ContentSearch>
                </AddUserWrapper>
              )}
              <Form>
                <Input
                  placeholder="Titolo"
                  type="text"
                  onChange={e =>
                    setNoteForm({ ...noteForm, title: e.target.value })
                  }
                  value={noteForm?.title || ''}
                />
                <TextArea
                  placeholder="Descrizione"
                  type="text"
                  onChange={e =>
                    setNoteForm({ ...noteForm, body: e.target.value })
                  }
                  value={noteForm?.body || ''}
                />

                <UploadWrapper>
                  <Label htmlFor="uploadFile">
                    <IconUplaod className="icon-icona-allega" />
                    <ProText
                      type="label"
                      configuration={{
                        lineHeight: '1.14',
                        color: 'primary',
                        colorType: 'base'
                      }}
                    >
                      {uploadedPicture?.size
                        ? (uploadedPicture.url || '').split('/').at(-1)
                        : acquiredPicture?.size
                          ? (acquiredPicture.name || '')
                          : noteForm?.imageUrl
                            ? 'Sostituisci file'
                            : 'Allega file'}
                    </ProText>
                  </Label>

                  <InputFile
                    type="file"
                    id="uploadFile"
                    accept={acceptedFiles.join(',')}
                    onChange={(changeEvent) => {
                      readPicture({ file: changeEvent.currentTarget?.files[0], format });
                    }}
                  />
                  {noteForm?.imageUrl && !uploadedPicture?.size && (
                    <Thumbnail src={imageData} className="img-thumbnail" />
                  )}
                </UploadWrapper>
              </Form>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button
                    text="Salva"
                    type="PRIMARY"
                    onClick={onClickSave}
                    disabled={isSearch || checkBodyTitle()}
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

NotesModal.FORMAT = {
  BINARY: 'BINARY',
  BASE64: 'BASE64'
};

NotesModal.propTypes = {
  // HOC (connect, state)
  notes: PropTypes.array,
  noteId: PropTypes.string,
  isEdit: PropTypes.bool,
  addUser: PropTypes.bool,
  users: PropTypes.array,
  domainId: PropTypes.number,
  nextPagePresent: PropTypes.bool,
  nextPage: PropTypes.string,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  editNote: PropTypes.func.isRequired,

  //useFileUploader
  format: PropTypes.string,
  acceptedFiles: PropTypes.arrayOf(PropTypes.string)
};

NotesModal.defaultProps = {
  acceptedFiles: ['.jpg', '.jpeg', '.png'],
  format: NotesModal.FORMAT.BINARY
};

NotesModal.id = modalId;
export const NotesModalId = NotesModal.id;

export default connect(
  state => {
    //const { noteId, isEdit, addUser } = state.modal.payload;
    const { addUser } = state.modal.payload;
    const { notes } = state.notes.notes;
    const {
      users,
      file: { fileDownloadUri: imgUrl }
    } = state.notes;
    const { nextPagePresent, nextPage } = state.social;
    const { id: domainId } = state.domain;
    return {
      /*noteId,
      isEdit,*/
      notes,
      users,
      imgUrl,
      addUser,
      domainId,
      nextPagePresent,
      nextPage
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    getNotes: () => dispatch({ type: GET_NOTES._REQUEST }),
    addNote: payload => dispatch({ type: ADD_NOTE._REQUEST, payload }),
    editNote: (payload, oldUsers = []) =>
      dispatch({ type: EDIT_NOTE._REQUEST, payload, oldUsers }),
    getUsers: (nextPage = null) =>
      dispatch({ type: GET_USERS._REQUEST, nextPage }),
    openModalError: payload => dispatch({ type: MODAL_OPEN, id: ModalErrors.id, payload }),
    openSuccessModal: payload => dispatch({type: MODAL_OPEN, id: SuccessModal.id, payload})
  })
)(NotesModal);
