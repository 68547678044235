import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';

import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { Footer, RenderLandingParagraph } from 'ui/components';
import { Container } from 'ui/gridSystem';
// import { useAnalytics } from 'providers/analyticsProvider';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { GET_CONTENTS_CAROUSEL } from 'redux/actions/landingPage';
import { FLOW_TYPE_SET } from 'redux/actions/signup';
import compose from 'utils/compose';
import routes from 'routes';
import {
  LandingPageContainer,
  Accedi,
  SubTitle,
  Title,
  TitleWrapper,
  Body,
  BodyContainer,
  Logo,
  HeadWrapper,
  Head,
  RowStripe,
  HtmlWrapper,
  IconLock,
  Label,
  ImageWrapper,
  ImageAppWrapper,
  Image,
} from './style';

const LandingPage = ({
  mediaIsPhone,
  pushUrl,
  data,
  domainSettings,
  setFlowType,
  getContentsCarousel,
  isFromBank,
  brand,
  middleware_configs,
}) => {
  const location = useLocation();
  const [paragraphs, setParagraphs] = useState([]);

  // const { ga4IsInitialized, sendGA4Event } = useAnalytics();

  // const sendAnalytics = (label) => {
  //   ga4IsInitialized && sendGA4Event({
  //     eventCategory: 'homepage',
  //     eventAction: 'click',
  //     eventLabel: label,
  //   });
  // };

  const redirectHomepage = () => {
    pushUrl(routes.landingPage.path);
  };

  useEffect(() => {
    const params = location.search.slice(1, location.search.length).split('&');
    if (params[0]?.length) {
      const newParams = params.map(str => ({
        key: str.split('=')[0],
        value: str.split('=')[1],
      }));
      if (newParams.find(p => p.key === 'isFromBank')) {
        setFlowType();
      }
    }
  }, [location, setFlowType]);

  useEffect(() => {
    const pars = data?.paragraphs?.data
      ? data?.paragraphs?.data
      : data?.paragraphs || [];
    setParagraphs(pars);
  }, [data]);

  useEffect(() => {
    if (paragraphs) {
      paragraphs.forEach((par, index) => {
        par.type === 'Contents Carousel' &&
          getContentsCarousel({
            ids: par.content_ids.replace(/\s/g, ''),
            index,
          });
      });
    }
  }, [paragraphs, getContentsCarousel]);


  const urlSSO = domainSettings?.middleware_configs.base_url_cognito+'oauth2/authorize?identity_provider='+domainSettings?.middleware_configs.identity_pool_name+'&redirect_uri='+domainSettings?.middleware_configs.redirect_uri+'&response_type=CODE&client_id='+domainSettings?.middleware_configs.client_id+'&scope=openid';

  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' || window.location.hostname === 's4c.local'
      // [::1] is the IPv6 localhost address.
      || window.location.hostname === '[::1]'
      // 127.0.0.1/8 is considered localhost for IPv4.
      || window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo onClick={redirectHomepage} img={brand?.logo_white} />
            {!isFromBank && urlSSO && (
              <Accedi onClick={() => window.location.href=urlSSO}>
                <IconLock className="icon-lock-reverse" />
                <ProText
                    type="buttonText"
                    configuration={{ fontSize: '10', fontWeight: 100 }}
                >
                  ACCEDI
                </ProText>
              </Accedi>
              )}

              {isLocalhost && (
               <Accedi onClick={() => pushUrl(routes.access.path)}>
                 <IconLock className="icon-lock-reverse" />
                 <ProText
                   type="buttonText"
                   configuration={{ fontSize: '10', fontWeight: 100 }}
                 >
                   ACCEDI
                 </ProText>
               </Accedi>
            )}

          </Head>
        </Container>
      </HeadWrapper>
      <LandingPageContainer background={data?.image_background}>
        <Body>
          <Container>
            {mediaIsPhone && (
              <ImageWrapper>
                <ImageAppWrapper className="aligned-start">
                  <Image alt="" src={data?.image_background} />
                </ImageAppWrapper>
              </ImageWrapper>
            )}
            <BodyContainer>
              {data?.label && (
                <Label>
                  <ProDisplay
                    type="3"
                    configuration={{ fontSize: 26, letterSpacing: '0.5px' }}
                  >
                    {data.label}
                  </ProDisplay>
                </Label>
              )}
              {data?.title && (
                <TitleWrapper>
                  <Title>
                    <ProDisplay
                      type="landingtitle"
                      configuration={{ fontSize: 40, fontWeight: 500 }}
                    >
                      {data.title}
                    </ProDisplay>
                  </Title>
                  <SubTitle>
                    <ProDisplay
                      type="title"
                      configuration={{
                        fontWeight: 700,
                        fontSize: '20',
                      }}
                    >
                      <HtmlWrapper
                        dangerouslySetInnerHTML={{ __html: data?.subtitle }}
                      />
                    </ProDisplay>
                  </SubTitle>
                </TitleWrapper>
              )}
              {data?.description && (
                <ProDisplay
                  type="categories"
                  configuration={{
                    lineHeight: 1.5,
                    color: 'mineShaft',
                    fontSize: 16,
                  }}
                >
                  <HtmlWrapper
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </ProDisplay>
              )}
            </BodyContainer>
          </Container>
        </Body>
      </LandingPageContainer>
      {paragraphs?.length ?
        paragraphs.map((item, index) => (
          <RowStripe key={`${item.type}-${item.title}`}>
            <RenderLandingParagraph
              item={item}
              itemIndex={index}
              onClick={url => {
                window.open(url, '_blank');
              }}
              pushUrl={pushUrl}
            />
          </RowStripe>
        ))
      : null}
      <Footer />
    </>
  );
};

LandingPage.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  // HOC (connect, state)
  data: PropTypes.object,
  domainSettings: PropTypes.object,
  isFromBank: PropTypes.bool,
  brand: PropTypes.object,
  middleware_configs: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  setFlowType: PropTypes.func.isRequired,
  getContentsCarousel: PropTypes.func.isRequired,
};

const composed = compose(
  connect(
    state => {
      const { data } = state.landingPage;
      const { isFromBank } = state.signup;
      const { brand, domain_settings, middleware_configs } = state.domain;
      return {
        data,
        domainSettings: domain_settings,
        isFromBank,
        brand,
        middleware_configs,
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      setFlowType: () => dispatch({ type: FLOW_TYPE_SET }),
      getContentsCarousel: payload =>
        dispatch({ type: GET_CONTENTS_CAROUSEL._REQUEST, payload }),
    })
  ),
  withMediaQueries
)(LandingPage);
export default composed;
