import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'ui/gridSystem';
import { Checkbox, CustomInput } from 'ui/atoms';

import EmailModal from 'ui/components/Modals/EmailModal';
import ProDisplay from 'ui/typography/proDisplay';
import validator from 'utils/regex-validator';

import { SIGNUP_UPDATE_ACCOUNT } from 'redux/actions/signup';
import { AgreementsContentsInterface } from 'interface/signup';
import AgreementCheck from 'ui/components/AgreementCheck';
import {
  CheckboxWrapper,
  CheckboxWrapperText,
} from 'ui/components/AgreementCheck/style';
import { push } from 'redux-first-history';
import routes from 'routes';
import ProText from 'ui/typography/proText';
import PasswordStrength from 'ui/atoms/PasswordStrenght';
import { errorPwd } from 'utils/password';
import {
  FormGroup,
  TitleContainer,
  Title,
  SubTitle,
  Form,
  TitleBackWrapper,
  IconBack,
  PasswordLegend,
} from './style';
import SummaryTableSimpler from '../../../../ui/components/SummaryTableSimpler';
//import PasswordSuggestion from 'ui/atoms/PasswordSuggestion';

const newField = () => ({
  value: undefined,
  valid: true,
});

const allFields = {
  firstName: newField(),
  lastName: newField(),
  email: newField(),
  pwd: newField(),
  confirmpwd: newField(),
  superManagerCode: newField(),
  section: newField(),
};

const initialForm = {
  firstName: newField(),
  lastName: newField(),
  email: newField(),
  pwd: newField(),
  confirmpwd: newField(),
};

const initialFormBank = {
  firstName: newField(),
  lastName: newField(),
  email: newField(),
  superManagerCode: newField(),
  section: newField(),
};

const UserAccount = ({
  onChange,
  updateAccount,
  agreements,
  isFromBank,
  domain,
  pushUrl,
}) => {
  const [form, setForm] = useState(allFields);
  const [checkConds, setCheckConds] = useState(!isFromBank);

  useEffect(() => {
    isFromBank ? setForm(initialFormBank) : setForm(initialForm);
  }, [isFromBank]);

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const validatePassword = () => {
    setForm(s => {
      const newState = { ...s };
      if (newState.pwd?.value !== undefined) {
        newState.pwd.valid = validator.password(newState.pwd.value);
        newState.pwd.error = !validator.password(newState.pwd.value) ? 'La password deve essere composta da almeno 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero ed un carattare speciale tra cui = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `' : '';
      }
      if (newState.confirmpwd?.value !== undefined) {
        newState.confirmpwd.valid =
          newState.pwd?.value === newState.confirmpwd.value;
      }
      return newState;
    });
  };

  const handleOnBlur = (type, field, callback) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });

    if (callback) callback();
  };
  const formValid = (Object.keys(form) || []).map(key =>
    Boolean(form[key].value && form[key].value.length > 0 && form[key].valid));

  const formConfirmed = () => {
    let valid = true;
    if (agreements?.user_clauses) {
      (Object.keys(agreements.user_clauses) || []).forEach(key => (valid = valid && agreements.user_clauses[key]?.valid));
    } else {
      valid = false;
    }
    return valid && !!agreements?.userType;
  };

  useEffect(() => {
    if (onChange) {
      let isValid;
      isFromBank
        ? (isValid = formValid.filter(bool => bool === false).length <= 0)
        : (isValid =
            formValid.filter(bool => bool === false).length <= 0 &&
            formConfirmed() === true);

      isValid = isValid && (isFromBank ? checkConds : true);
      onChange(isValid);
      if (isValid) updateAccount(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, checkConds, agreements]);

  useEffect(() => {
    const isValid =
      formValid.filter(bool => bool === false).length <= 0 &&
      formConfirmed() === true;
    onChange(isValid);
    if (isValid) updateAccount({ ...form, domainId: { value: domain.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreements.user_clauses]);

  /*   useEffect(() => {
    if (!cart.total_price) {
      pushUrl(routes.subscription.path);
    }
  }, [cart.total_price, pushUrl]); */

  return (
    <Container>
      <Row>
        <Col md={{ size: 10, offset: 1 }} sm={12}>
          <div>
            <TitleContainer>
              <TitleBackWrapper>
                {isFromBank && (
                  <IconBack
                    className="icon-arrow-right"
                    onClick={() => pushUrl(routes.subscription.path)}
                  />
                )}
                <Title>
                  <ProDisplay type="title" configuration={{ fontWeight: 300 }}>
                    {isFromBank
                      ? "Crea l'account del tuo Cliente"
                      : 'Crea il tuo account'}
                  </ProDisplay>
                </Title>
              </TitleBackWrapper>

              <SubTitle>
                <ProText type="description">
                  {isFromBank
                    ? "Crea l'account del tuo Cliente per accedere alla dashboard di amministrazione"
                    : 'Crea il tuo account per accedere alla dashboard di amministrazione'}
                </ProText>
              </SubTitle>
            </TitleContainer>
            <FormGroup>
              <Form>
                <Row>
                  <Col md={6} sm={12} className="mb-3">
                    <CustomInput
                      type="text"
                      placeholder="Nome *"
                      value={form.firstName.value}
                      onChange={val => handleOnChange('firstName', val)}
                      onBlur={() => handleOnBlur('string', 'firstName')}
                      autoFocus
                      error={!form.firstName.valid}
                    />
                  </Col>
                  <Col md={6} sm={12} className="mb-3">
                    <CustomInput
                      type="text"
                      placeholder="Cognome *"
                      value={form.lastName.value}
                      onChange={val => handleOnChange('lastName', val)}
                      onBlur={() => handleOnBlur('string', 'lastName')}
                      error={!form.lastName.valid}
                    />
                  </Col>
                  <Col sm={12} className="mb-3">
                    <CustomInput
                      type="email"
                      placeholder="Indirizzo email *"
                      value={form.email.value}
                      onChange={val => handleOnChange('email', val)}
                      onBlur={() => handleOnBlur('email', 'email')}
                      error={!form.email.valid}
                    />
                  </Col>
                  {isFromBank ? (
                    <>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Codice gestore *"
                          value={form.superManagerCode.value}
                          onChange={val =>
                            handleOnChange('superManagerCode', val)
                          }
                          onBlur={() => handleOnBlur('sdi', 'superManagerCode')}
                          error={!form.superManagerCode.valid}
                        />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Filiale di riferimento *"
                          value={form.section.value}
                          onChange={val => handleOnChange('section', val)}
                          onBlur={() => handleOnBlur('string', 'section')}
                          error={!form.section.valid}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          autocomplete="new-password"
                          type="password"
                          placeholder="Password *"
                          value={form.pwd.value}
                          onChange={val => handleOnChange('pwd', val)}
                          onBlur={() =>
                            handleOnBlur('password', 'pwd', validatePassword)
                          }
                          error={
                            typeof form.pwd.valid === 'string'
                              ? form.pwd.valid
                              : !form.pwd.valid
                          }
                          displayError={!form.pwd.error}
                        />
                        <PasswordLegend
                          error={!!form.pwd.error}
                        >
                          <ProDisplay type="2" configuration={{ fontSize: 12 }}>
                            {errorPwd}
                          </ProDisplay>
                        </PasswordLegend>
                        <PasswordStrength password={form.pwd.value} />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          autocomplete="new-password"
                          type="password"
                          placeholder="Conferma password *"
                          value={form.confirmpwd.value}
                          onChange={val => handleOnChange('confirmpwd', val)}
                          onBlur={validatePassword}
                          error={!form.confirmpwd.valid}
                        />
                      </Col>
                    </>
                  )}
                  {!isFromBank && (
                    <>
                      {/* <PasswordSuggestion password={form.pwd} padding={20} /> */}
                      <AgreementCheck validator={formConfirmed} checkUserType />
                    </>
                  )}
                  {isFromBank && (
                    <Col md={12} className="mb-3">
                      <CheckboxWrapper
                        onClick={() => setCheckConds(!checkConds)}
                      >
                        <Checkbox
                          checked={checkConds}
                          disabled
                          onChange={() => null}
                        />
                        <CheckboxWrapperText>
                          <ProDisplay type="label">
                            Confermo che sono stati rilasciati i consensi
                            necessari all'attivazione degli abbonamenti
                          </ProDisplay>
                        </CheckboxWrapperText>
                      </CheckboxWrapper>
                    </Col>
                  )}
                </Row>
              </Form>
            </FormGroup>
          </div>
        </Col>
        {isFromBank && (
          <Col md={{ size: 10, offset: 1 }} sm={12} className="mb-5">
            <SummaryTableSimpler />
          </Col>
        )}
      </Row>
      <EmailModal />
    </Container>
  );
};

UserAccount.propTypes = {
  onChange: PropTypes.func.isRequired,
  isFromBank: PropTypes.bool,
  domain: PropTypes.object,

  // HOC (connect, dispatch)
  updateAccount: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  agreements: AgreementsContentsInterface,
};

export default connect(
  state => {
    const { agreements, isFromBank } = state.signup;
    return {
      agreements,
      isFromBank,
      domain: state.domain,
    };
  },
  dispatch => ({
    updateAccount: account =>
      dispatch({ type: SIGNUP_UPDATE_ACCOUNT, account }),
    pushUrl: url => dispatch(push(url)),
  })
)(UserAccount);
