import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ProductVariation } from 'interface/signup';
import { ProductSubscriptionContainer } from './style';
import Product from './Product';

const ProductSubscription = ({
  product,
  onChangeQuantity,
  cart,
  isCatalog,
}) => {
  const [parentVal, setParentVal] = useState(
    cart?.products[Object.keys(cart?.products).find(k => k === product.sku)]
      ?.num || 0
  );

  const onChange = (num, selProd) => {
    setParentVal(num);
    if (onChangeQuantity) onChangeQuantity(num, selProd);
  };

  const onChangeChild = (num, selProd) => {
    if (onChangeQuantity) onChangeQuantity(num, selProd);
  };

  return (
    <ProductSubscriptionContainer>
      <Product product={product} onChange={onChange} isCatalog={isCatalog} />
      {product?.child_products?.length > 0 &&
        product.child_products.map(child_product => (
          <Product
            isChild
            key={child_product.product_id + child_product.variation_id}
            product={child_product}
            onChange={onChangeChild}
            disabled={!parentVal}
            isCatalog={isCatalog}
            cart={cart}
          />
        ))}
    </ProductSubscriptionContainer>
  );
};

ProductSubscription.propTypes = {
  product: ProductVariation,
  onChangeQuantity: PropTypes.func.isRequired,
  cart: PropTypes.object,
  isCatalog: PropTypes.bool,
};

export default connect(({ signup: { cart } }) => ({ cart }))(
  ProductSubscription
);
