import React from 'react';
import PropTypes from 'prop-types';

import useBookmarks from 'customHooks/useBookmarks';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import TYPES from 'ui/typography/pro.types';
import PlaceholderImg from 'ui/assets/img/S4C_THUMBNAIL.png';
import { detailType } from 'utils/common';
import { getDatesDay, getDatesHour, getDates, getDateMonth, getDateDay} from 'utils/date-format';
import {
  // clockToTime,
  secsToTime
} from 'utils/duration-format';

import {
  CourseCard,
  CourseLabel,
  CourseTitle,
  CoursePlay,
  EventCard,
  EventHeader,
  ColorLabel,
  EventFooter,
  IconAssets,
  IconBookmark,
  IconLock,
  NewsCard,
  NewsImage,
  NewsLabel,
  NewsFooter,
  NewsTitle,
  NewsPlayL,
  NewsPlayS,
  PackageCard,
  PackageContents,
  PackageFooter,
  PackageHeader,
  PackageImage,
  PackageProgress,
  ExpirationDate,
  Logo,
} from './style';

const ContentCard = ({
  type,
  data,
  onClick,
  ellipsis,
  homepage,
  currentViewport,
  viewportTypes,
}) => {
  const { handleBookmark, isBookmarked } = useBookmarks();
  const cardIsBookmarked = isBookmarked(data);

  const onCardClickHandler = (card)=> {
    if (!card.link){ // se ho un link lo gestisco con anchor tag
      onClick(card);
    }
  }

  const getDuration = duration => `${Math.round(duration / 60)} min`;

  const getContentByType = typeContent => {
    const typeContentLC = typeContent.toLowerCase();
    switch (typeContentLC) {
      case detailType.NEWS:
      case detailType.RELATED_COURSE:
        return (
          <NewsCard onClick={onClick}>
            <NewsImage src={data.cover ?? PlaceholderImg} />
            <NewsFooter>
              {data.format === 'video' && (
                <NewsPlayL className="icon-icona-play" />
              )}
              <NewsTitle>
                <ProText
                  type={TYPES.TEXT.NEWS_CARD_TITLE}
                  currentViewport={currentViewport}
                  ellipsis={ellipsis}
                  tooltip={data.title}
                >
                  {data.title}
                </ProText>
              </NewsTitle>
              <NewsLabel>
                {data.type === detailType.RELATED_COURSE && (
                  <NewsPlayS className="icon-play" />
                )}
                <ProText
                  type={data.type === detailType.RELATED_COURSE
                    ? TYPES.TEXT.NEWS_CARD_TITLE
                    : TYPES.TEXT.NEWS_CARD_LABEL
                  }
                  currentViewport={currentViewport}
                >
                  {data.type === detailType.RELATED_COURSE
                    ? getDuration(data.duration)
                    : 'News'}
                </ProText>
              </NewsLabel>
            </NewsFooter>
          </NewsCard>
        );
      case detailType.COURSE:
        return (
          <CourseCard onClick={onClick} bg={data.cover ?? PlaceholderImg}>
            <CourseTitle>
              <ProDisplay
                type={TYPES.DISPLAY.COURSE_CARD_TITLE}
                currentViewport={currentViewport}
                ellipsis={2}
                tooltip={data.title}
              >
                {data.title}
              </ProDisplay>
            </CourseTitle>
            <CourseLabel>
              <CoursePlay className="icon-play" />
              <ProDisplay
                type={TYPES.DISPLAY.COURSE_CARD_LABEL}
                currentViewport={currentViewport}
              >
                {getDuration(data.duration)}
              </ProDisplay>
            </CourseLabel>
          </CourseCard>
        );
      case detailType.PACKAGE:
        const progress = data.numberOfAssetContentsCompleted / data.numberOfAssetContents
        return (
          <PackageCard onClick={onClick}>
            <PackageHeader>
              <div
                className={'icon-container'}>
                <PackageImage src={data.cover ?? PlaceholderImg}/>
                <ProText
                  type={TYPES.TEXT.PACKAGE_CARD_TITLE}
                  currentViewport={currentViewport}
                  ellipsis={
                    (viewportTypes[currentViewport] < viewportTypes.mediaIsLaptop) ? 1 :2
                  }
                >
                  { data.title }
                  {data.packageType.toLowerCase() === 'premium' ?
                    <span className='premium'>
                      Premium
                      <span className='icon-premium'></span>
                    </span>
                    : ''}
                </ProText>
              </div>
              <PackageContents>
                <IconAssets className="icon-collection align-left" />
                <ProText
                  type={TYPES.TEXT.PACKAGE_CARD_CONTENTS}
                  currentViewport={currentViewport}
                >
                  { data.numberOfAsset } Moduli
                </ProText>
              </PackageContents>
            </PackageHeader>
            <PackageFooter>
              <ProText
                type={TYPES.TEXT.PACKAGE_CARD_FOOTER}
                currentViewport={currentViewport}
              >
                Durata: <span className='bold'>{ secsToTime(data.totalDuration) }</span>
              </ProText>
              <PackageProgress progress={ progress }>
                <ProText
                  type={TYPES.TEXT.PACKAGE_CARD_FOOTER}
                  currentViewport={currentViewport}
                >
                  Avanzamento: <span className='bold'>{
                  data.numberOfAssetContentsCompleted ?
                      Math.round((data.numberOfAssetContentsCompleted / data.numberOfAssetContents) * 100)
                      : 0}%</span>
                </ProText>
                <span className="bar bg">
                  <span className="bar fg"></span>
                </span>
              </PackageProgress>
            </PackageFooter>
          </PackageCard>
        )
      case detailType.LIVE_EVENT:
      case detailType.LIVE_EVENT_LOCKED:
      case detailType.PLAYLIST:
      case detailType.COLLECTION:
      case detailType.SELECTION:
        const isPlaylist = (type) =>
          [detailType.COLLECTION, detailType.PLAYLIST, detailType.SELECTION]
            .includes(type)

        const getLabelConfig = labelType => {
          switch(labelType) {
            case 'virtual':
              return { bgColor: '#C8D300', label: 'Virtual Classroom' }
            case 'live':
              return { bgColor: '#6DC4BD', label: 'Live Classroom' }
            case 'collection':
            case 'selection':
            case 'playlist':
              return { bgColor: '#B1BB02', label: 'Digitale' }
            default:
          }
        };

        const getColorLabel = (type, count) => {
          const config = getLabelConfig(type)
          return config ? (
            <ColorLabel bgColor={config.bgColor} large={!count && count !== 0}>
              <ProText
                type={TYPES.TEXT.EVENT_LABEL_HOMEPAGE}
                currentViewport={currentViewport}
              >{count ?? config.label}</ProText>
            </ColorLabel>
          ) : null;
        }

        const eventCard = <EventCard homepage={homepage} bg={data.cover ?? PlaceholderImg} onClick={()=> onCardClickHandler(data)} className={data.link ? 'link' : ''}>
          <EventHeader homepage={homepage}>
            {data.toBeCompletedByDate && (typeContentLC === detailType.SELECTION  || typeContentLC === 'playlist' )? (
                <ExpirationDate>
                  <ProText className="until" configuration={{ fontWeight: 'bold', marginRight: '5px'  }}>
                    {'Entro'}
                  </ProText>
                  <ProText configuration={{ color: '#ffffff', fontWeight: 'bold'  }}>
                    {getDateDay(data.toBeCompletedByDate)}&nbsp;{getDateMonth(data.toBeCompletedByDate)}
                  </ProText>

                </ExpirationDate>
            ) : null}
            {getColorLabel(isPlaylist(data.type.toLowerCase())
                ? data.type
                : data.session?.sessionType?.toLowerCase()
            )}
            {data.locked ? (
                <IconLock className="icon-icona-lucchetto" />
            ) : null}
            {!data.noBookmark && <IconBookmark
                className={
                  cardIsBookmarked
                      ? 'icon-icona-salva-attivo selected'
                      : 'icon-icona-salva-active '
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleBookmark(data);
                }}
            />}
          </EventHeader>
          <EventFooter homepage={homepage} className={data.link ? 'link' : ''}>
            {(data.weight || data.weight === 0) ? (
                getColorLabel(
                    isPlaylist(data.type.toLowerCase())
                        ? data.type
                        : data.session?.sessionType?.toLowerCase(),
                    data.weight
                )
            ) : null}
            {data.logo && <Logo src={data.logo} alt="logo"/>}
            <ProText
                type={TYPES.TEXT.EVENT_TITLE_HOMEPAGE}
                ellipsis={ellipsis}
                tooltip={data.title}
                configuration={data.link ? { color: 'mineShaft' } : null}
                currentViewport={currentViewport}
            >
              {data.title}
            </ProText>
            {typeContent.toLowerCase() === detailType.LIVE_EVENT && data.session ? (
                <>
                  <ProText
                      type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                      currentViewport={currentViewport}
                  >
                    <span className='capitalize'>{getDatesDay(data.session).text}</span>
                  </ProText>
                  {getDatesDay(data.session).sameDay ? (
                      <ProText
                          type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                          currentViewport={currentViewport}
                      >
                        <span className='capitalize'>{getDatesHour(data.session)}</span>
                      </ProText>
                  ) : null}
                </>
            ) : null}
            {isPlaylist(typeContentLC) && data.totalDuration ? (
                <ProText
                    type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                    currentViewport={currentViewport}
                >
                  Durata: <b>{secsToTime(data.totalDurationSec)}</b>
                </ProText>
            ) : null}
            {data.numberOfContents && isPlaylist(typeContentLC) && typeContent !== detailType.LIVE_EVENT_LOCKED ? (
                <ProText
                    type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                    currentViewport={currentViewport}
                >
                  Avanzamento: <b>{data.numberOfContentsCompleted ?
                    Math.round((data.numberOfContentsCompleted / data.numberOfContents) * 100)
                    : 0}%</b>
                </ProText>
            ) : null}
          </EventFooter>
        </EventCard>

        return (
            <>{ data.link ? <a href={data.link} target={"_blank"} rel={"noopener noreferrer"}>{eventCard}</a> : eventCard }</>
        );
      default:
        return null;
    }
  };

  return (
    getContentByType(type)
  );
};

ContentCard.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  ellipsis: PropTypes.number,
  index: PropTypes.number,
  onClick: PropTypes.func,
  //HOC (connect, dispatch)

  //HOC withMediaQueries
};

ContentCard.defaultProps = {
  ellipsis: 3,
  index: 1
};

export default withMediaQueries(ContentCard);

// TODO: should I use h3's for the card titles?
