import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import { push } from 'redux-first-history';
import routes from 'routes';
import { detailType } from 'utils/common';
import { ADD_BOOKMARK, REMOVE_BOOKMARK } from 'redux/actions/social';
import { SET_PLAYLIST_HISTORY, SET_SECTION } from 'redux/actions/playlist';
import { GridContainer, ShowMore } from './style';
import ContentCardUser from '../ContentCardUser';
import RecommendModal from '../Modals/RecommendModal';
import { useLocation } from 'react-router-dom';

const Grid = ({
  elements,
  ellipsis,
  location,
  section,
  // HOC (connect, state)
  bookmarks,
  router,
  // HOC (connect, dispatch)
  addBookmark,
  pushUrl,
  removeBookmark,
  setPlaylistHistory,
  setSection,
  // HOC media queries

  mediaIsPhone,
  mediaIsTablet,
}) => {
  const [showNumber, setShowNumber] = useState(12);
  const [showMore, setShowMore] = useState(0);

  const pageLocation = useLocation().pathname;

  useEffect(() => {
    if (mediaIsPhone || mediaIsTablet) setShowMore(12);
    else setShowMore(20);
  }, [mediaIsPhone, mediaIsTablet]);

  const onChangeLocation = item => {
    setSection(section);
    setPlaylistHistory(router.location.pathname);
    switch (item.type.toLowerCase()) {
      case 'news':
        pushUrl(`${routes.detail.path}/${detailType.NEWS}/${item.itemId}`);
        break;
      case 'training':
      case 'learning_object':
        pushUrl(`${routes.detail.path}/${detailType.COURSE}/${item.itemId}`);
        break;
      case 'collection':
      case 'selection': {
        const productId= item.productId;
        const itemId = item.itemId ? item.itemId : item.id;
        if (item.locked) {
            pushUrl(`${routes.lockedDetails.path}/${itemId}${!!productId ? '/' + productId : ''}`)
        } else pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${itemId}`);
        break;
      }
      case 'live_event': {
          const itemId = item.id ? item.id : item.itemId;
          const productId= item.productId;
          if (item.locked) {
            pushUrl(`${routes.eventLockedDetail.path}/${itemId}/${productId}`)
          } else {
            pushUrl(`${routes.event.path}/${itemId}`);
          }
          break;
        }
      default:
        break;
    }
  };

  return (
    <>
      <GridContainer location={location}>
        {!pageLocation.includes('playlist') && elements?.slice(0, showNumber).map(item => (
          <ContentCardUser
            key={item.itemId}
            type={location}
            data={item}
            onClick={() => onChangeLocation(item)}
            ellipsis={ellipsis}
          />
        ))}
        {!!pageLocation.includes('playlist') && elements?.map(item => (
          <ContentCardUser
            key={item.itemId}
            type={location}
            data={item}
            onClick={() => onChangeLocation(item)}
            ellipsis={ellipsis}
          />
        ))}
      </GridContainer>
      {showNumber < elements?.length && !pageLocation.includes('playlist') && (
        <ShowMore onClick={() => setShowNumber(showNumber + showMore)}>
          <ProText type="showMore">Mostra altri</ProText>
        </ShowMore>
      )}
      <RecommendModal />
    </>
  );
};

Grid.propTypes = {
  elements: PropTypes.array,
  ellipsis: PropTypes.number,
  location: PropTypes.string,
  section: PropTypes.string,

  // HOC (connect, state)
  bookmarks: PropTypes.array,
  router: PropTypes.object,

  // HOC (connect, dispatch)
  addBookmark: PropTypes.func,
  pushUrl: PropTypes.func,
  removeBookmark: PropTypes.func,
  setPlaylistHistory: PropTypes.func,
  setSection: PropTypes.func,

  // HOC (MediaQueries)
  mediaIsPhone: PropTypes.bool,
  mediaIsTablet: PropTypes.bool
};

export default withMediaQueries(
  connect(
    state => {
      const { bookmarklist: bookmarks } = state.social.bookmarks;
      const { router } = state;
      return { bookmarks, router };
    },
    dispatch => ({
      addBookmark: payload =>
        dispatch({ type: ADD_BOOKMARK._REQUEST, payload }),
      pushUrl: url => dispatch(push(url)),
      removeBookmark: payload =>
        dispatch({ type: REMOVE_BOOKMARK._REQUEST, payload }),
      setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),
      setSection: section => dispatch({ type: SET_SECTION, section }),
    })
  )(Grid)
);
