import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Img from 'ui/assets/img/group.png';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';

import { Container } from 'ui/gridSystem';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { push } from 'redux-first-history';
import routes from 'routes';
import { AUTHENTICATION_SIGNOUT } from 'redux/actions/authentication';
import { ContentWrapper, Image, Title, Subtitle, BtnContainer } from './style';

const SessionExpired = ({ pushUrl, triggerResetStore }) => {
  const gotoHome = () => {
    pushUrl(routes.landingPage.path);
  };

  const isPreliosAppAndroid = navigator.userAgent.includes('DigitedGuscio_Android');
  const isPreliosAppIOS = navigator.userAgent.includes('DigitedGuscio_iOS');

  useEffect(() => {
    triggerResetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <Image src={Img} />
        <Title>
          <ProDisplay type="modalTitle">Sessione scaduta</ProDisplay>
        </Title>
        <Subtitle>
          <ProDisplay configuration={{ lineHeight: 1.63 }}>
            L'utenza risulta attiva in un altro dispositivo
          </ProDisplay>
            {isPreliosAppAndroid &&
                <ProDisplay configuration={{ lineHeight: 1.63, marginTop: 20 }}>
                      Torna indietro per accedere nuovamente
                 </ProDisplay>
            }
            {isPreliosAppIOS &&
                <ProDisplay configuration={{ lineHeight: 1.63, marginTop: 20 }}>
                      Clicca sul pulsante "Logout" per tornare alla pagina iniziale
                 </ProDisplay>
            }
        </Subtitle>
        {!isPreliosAppAndroid && !isPreliosAppIOS &&
        <BtnContainer>
          <Button type={Button.TYPE.PRIMARY} onClick={gotoHome}>
            Torna alla Home
          </Button>
        </BtnContainer>
        }
      </ContentWrapper>
    </Container>
  );
};

SessionExpired.propTypes = {
  // HOC withMediaQueires

  // HOC (connect, state)
  pushUrl: PropTypes.func.isRequired,
  triggerResetStore: PropTypes.func.isRequired
};

export default withMediaQueries(
  connect(
    () => ({}),
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      triggerResetStore: () =>
        dispatch({ type: AUTHENTICATION_SIGNOUT._REQUEST })
    })
  )(SessionExpired)
);
