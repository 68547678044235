import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';
import menu from 'utils/menu';

import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  MenuContainer,
  ItemList,
  PriceWrapper,
  Text,
  Price,
  Wrapper,
  Notification
} from './style';
import { isManager } from '../../../utils/roles';
import { priceTotalFormat } from '../../../utils/price-format';

const Menu = ({
                customClass,
                activeMenu,
                pushUrl,
                hasNotification,
                all_prospect,
                // teams_interests,
                filtered_teams_interests,
                totalAmountAnnualPayment = 0
              }) => {

  const changeLocation = url => {
    pushUrl(url);
  };

  const filteredMenu = menu.map(menuItem => ({
    ...menuItem,
    disabled: (
        (
            Object.keys(all_prospect).length < 1
            || Object.values(all_prospect).every(p => Array.isArray(p) && p.length < 1)
        )
        && menuItem.label !== 'Pacchetti attivi'
    )
  }));

  return (
      <MenuContainer customClass={customClass}>
        <Container>
          <Wrapper>
            <ItemList className="items-list">
              {(filteredMenu || []).map((item = {}) => (
                  <div
                      key={item.label}
                      className={`items-list-item${
                          activeMenu === item.link
                              ? ' active'
                              : ''
                      }${
                          item.label === 'Al tuo team interessa'
                          && filtered_teams_interests?.length
                          && !item.disabled
                              ? ' notification'
                              : ''
                      }${
                          item.disabled
                              ? ' disabled'
                              : ''
                      }`}
                      onClick={() => !item.disabled && changeLocation(item.link)}
                  >
                    <ProDisplay type="menutitle" configuration={{ fontSize: '24' }}>
                      {item.label}
                    </ProDisplay>
                  </div>
              ))}
            </ItemList>
            {hasNotification && <Notification />}
            <PriceWrapper>
              <Text>
                <ProDisplay type="2" configuration={{ fontSize: '12' }}>
                  il mio canone annuo (IVA esclusa)
                </ProDisplay>
              </Text>
              <Price>
                <ProDisplay type="title" configuration={{ fontSize: '24' }}>
                  {totalAmountAnnualPayment ? priceTotalFormat(totalAmountAnnualPayment) : '--,--'}€
                </ProDisplay>
              </Price>
            </PriceWrapper>
          </Wrapper>
        </Container>
      </MenuContainer>
  );
};

Menu.propTypes = {
  customClass: PropTypes.string,
  activeMenu: PropTypes.string,
  hasNotification: PropTypes.bool,

  // HOC (connect, state)
  all_prospect: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  totalAmountAnnualPayment: PropTypes.number,
  teams_interests: PropTypes.array,
  filtered_teams_interests: PropTypes.array,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired
};

export default withMediaQueries(
    connect(
        state => {
          const {
            all_prospect,
            // teams_interests,
            totalAmountAnnualPayment,
            filtered_teams_interests
          } = state.subscriptions;
          const { user: { clientEnabled = [] } = {} } = state.authentication;
          return {
            all_prospect,
            filtered_teams_interests,
            totalAmountAnnualPayment,
            isUserManager: isManager(clientEnabled)
          };
        },
        dispatch => ({
          pushUrl: url => dispatch(push(url))
        })
    )(Menu)
);
