/* eslint-disable no-sequences */
import {
  getProfileDataAccountMock,
  getProfileDataCompanyMock,
  shouldUseMock,
  deletePaymentMethodMock,
  getDataPrivacyMock,
  postDataPrivacyMock, updateProfileCompanyMock
} from './mock';
import {
  apiCall,
  baseUrl,
  extendUrl,
  bePrefix,
  socialPrefix,
  channel
} from './config';

export default {
  getProfileDataAccount: ({ userId }) => {
    if (shouldUseMock()) {
      return getProfileDataAccountMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}userinfo?userId=${userId}&channel=${channel}`,
      'GET'
    );
  },
  getProfileDataCompany: ({ userId }) => {
    if (shouldUseMock()) {
      return getProfileDataCompanyMock();
    }

    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}getorginfo`,
      'GET',
      { userId }
    );
  },
  updateProfileCompany: body => {
    if (shouldUseMock()) {
      return updateProfileCompanyMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}organization/update`,
      'PUT',
      body
    );
  },
  deletePaymentMethod: ({ paymentId }) => {
    if (shouldUseMock()) {
      return deletePaymentMethodMock();
    }
    return apiCall(`/ecommerce/api/frontend/payment_method/delete/?id=${paymentId}`, 'DELETE');
  },

  getDataPrivacy: () => {
    if (shouldUseMock()) {
      return getDataPrivacyMock();
    }

    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}dataprivacy`,
      'GET'
    );
  },

  postDataPrivacy: ({ body }) => {
    if (shouldUseMock()) {
      return postDataPrivacyMock();
    }

    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}dataprivacy`,
      'POST',
      body
    );
  }
};
