import { takeLatest, put, call, select } from 'redux-saga/effects';

import { push } from 'redux-first-history';
import {
  AUTH_LOGIN,
  LOADER_CLOSE,
  LOADER_OPEN,
  MODAL_OPEN,
  HIDE_NAVBAR,
} from 'redux/actions';
import {
  USER_RESET_PASSWORD,
  GET_LANDING_PAGE,
  UPDATE_PASSWORD,
  RESET_PASSWORD_EMAIL,
  CREATE_USER,
  GET_CONTENTS_CAROUSEL,
  SET_PASSWORD_MANAGER,
  SET_PASSWORD_MANAGER_FROM_BANK,
  GET_FULL_CATALOG,
} from 'redux/actions/landingPage';

import landingPage from 'api/landingPage';
import routes from 'routes';
import { channel } from 'api/config';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  AUTHENTICATION_SIGNIN,
  AUTHENTICATION_SIGNOUT,
} from 'redux/actions/authentication';
import onBoarding from 'api/onBoarding';
import { getToken, setToken } from 'utils/token';
import authentication from 'api/authentication';
import { PROFILE_DATA_ACCOUNT_GET } from 'redux/actions/profile';
import { ONBOARDING_GET_NOT_CONFIGURED_ASSETS } from 'redux/actions/onBoarding';

function* userPasswordReset({ email }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { id: domainId } = yield select(state => state.domain);
    const body = { username: email, domainId };
    const response = yield call(landingPage.userPasswordReset, body);
    const { status = '' } = response;

    if (status?.toString() === '200') {
      yield put({ type: USER_RESET_PASSWORD._SUCCESS, response });
      yield put({ type: RESET_PASSWORD_EMAIL, email });
      yield put(push(`${routes.confirmPassword.path}`));
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > userPasswordReset', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* userPasswordResetWatch() {
  yield takeLatest(USER_RESET_PASSWORD._REQUEST, userPasswordReset);
}

function* updatePasswordReset({ body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const response = yield call(landingPage.updatePassword, body);
    const { sdkHttpMetadata, sdkResponseMetadata } = response;

    if (!sdkHttpMetadata && !sdkResponseMetadata) {
      yield put({ type: UPDATE_PASSWORD._SUCCESS, response });
      yield put({ type: AUTHENTICATION_SIGNOUT._REQUEST });
      yield put(push(routes.confirmChangedPassword.path));
      // yield delay(500);
      // window.location.replace(`${window.location.origin}${routes.access.path}`);
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > updatePasswordReset', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* updatePasswordResetWatch() {
  yield takeLatest(UPDATE_PASSWORD._REQUEST, updatePasswordReset);
}

function* setPasswordManager({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { status } = yield call(landingPage.setPasswordManager, payload);
    if (status.toString() === '204') {
      yield put({
        type: AUTHENTICATION_SIGNIN._REQUEST,
        payload: {
          passwordUser: payload.passwordUser,
          username: payload.email,
          channel,
        },
      });
    }
  } catch (err) {
    let errorText = ''
    switch(err?.response.status) {
      case 406:
        errorText = 'Account già registrato. Accedere attraverso la pagina di login o reimpostare la password utilizzando la stessa email'
        break;
      case 422:
        errorText = 'L\'utente che si sta provando ad abilitare non esiste'
        break;
      default:
        errorText = err?.message
    }
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > setPasswordManager', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setPasswordManagerWatch() {
  yield takeLatest(SET_PASSWORD_MANAGER._REQUEST, setPasswordManager);
}

function* setPasswordManagerFromBank({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const res = yield call(landingPage.setPasswordManagerFromBank, payload);
    if (res?.status.toString() === '200') {
      yield put({
        type: AUTHENTICATION_SIGNIN._REQUEST,
        payload: {
          passwordUser: payload.password,
          username: payload.email,
          channel,
        },
      });
    } else if (res?.status.toString() >= '400') {
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: { errorText: res?.err?.message },
      });
    }
  } catch (err) {
    let errorText = ''
    switch(err?.response.status) {
      case 406:
        errorText = 'Account già registrato. Accedere attraverso la pagina di login o reimpostare la password utilizzando la stessa e-mail'
        break;
      case 422:
        errorText = 'L\'utente che si sta provando ad abilitare non esiste'
        break;
      default:
        errorText = err?.message
    }
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > setPasswordManagerFromBank', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setPasswordManagerFromBankWatch() {
  yield takeLatest(SET_PASSWORD_MANAGER_FROM_BANK._REQUEST, setPasswordManagerFromBank);
}

function* landingPageGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { title } = {} } = yield select(state => state.landingPage);
    const { isFromBank } = yield select(state => state.signup);
    if (!title) {
      if (isFromBank) {
        const { data: { data: response = {} } = {} } = yield call(
          landingPage.getBankLandingPage
        );
        yield put({ type: GET_LANDING_PAGE._SUCCESS, response });
      } else {
        const { data: { data: response = {} } = {} } = yield call(
          landingPage.getLandingPage
        );
        yield put({ type: GET_LANDING_PAGE._SUCCESS, response });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > landingPageGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* landingPageGetWatch() {
  yield takeLatest(GET_LANDING_PAGE._REQUEST, landingPageGet);
}

function* createUser({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const res = yield call(onBoarding.createUser, {
      ...payload,
      channel,
    });

    const {
      data: {
        username,
        givenname,
        familyname,
        email,
        role,
        pictureid,
        organizationid,
        prospectid,
        token,
        accesstoken,
        refreshtoken,
        expiresin,
        clientenabled,
        domainlist,
      } = {},
    } = res;

    if (token) {
      const { rememberMe } = yield select(state => state.landingPage);
      setToken(
        {
          token,
          accesstoken,
          refreshtoken,
          expiresin,
          userid: username,
        },
        !!rememberMe
      );

      yield put({
        type: AUTHENTICATION_SIGNIN._SUCCESS,
        user: {
          userId: username,
          firstname: givenname,
          lastname: familyname,
          email,
          role,
          pictureId: pictureid,
          organizationId: organizationid,
          prospectId: prospectid,
          clientEnabled: clientenabled,
          domainList: domainlist,
        },
      });
      if (!clientenabled) {
        const { id } = yield select(state => state.domain);
        const { userId } = yield select(state => state.authentication.user);
        const store = getToken();

        const {
          data: {
            token: tokenFromMdw,
            accesstoken: accesstokenFromMdw,
            refreshtoken: refreshtokenFromMdw,
            clientenabled: clientenabledFromMdw,
            expiresin: expiresinFromMdw,
          },
        } = yield call(authentication.getDomain, {
          userId,
          refreshToken: store.refreshtoken,
          accessToken: store.accesstoken,
          channel,
          domainId: id,
        });

        setToken(
          {
            token: tokenFromMdw,
            accesstoken: accesstokenFromMdw,
            refreshtoken: refreshtokenFromMdw,
            expiresin: expiresinFromMdw,
            userid: userId,
          },
          !!rememberMe
        );

        yield put({
          type: PROFILE_DATA_ACCOUNT_GET._SUCCESS,
          response: { clientEnabled: clientenabledFromMdw },
        });
      }
      yield put({ type: AUTH_LOGIN._SUCCESS });
      try {
        // eslint-disable-next-line no-unused-vars
        const registerProspectResponse = yield call(
          onBoarding.registerProspect,
          email
        );
        if (registerProspectResponse.status === 200) {
          yield put({
            type: ONBOARDING_GET_NOT_CONFIGURED_ASSETS._REQUEST,
          });
        }
      } catch (e) {
        //not warn
      } finally {
        yield put(push(routes.drawProfile.path));
      }

      yield put({ type: HIDE_NAVBAR });
    }
  } catch (err) {
    let error = {};
    switch(err.response?.status) {
      case 400:
        error.errorTitle = 'Accesso non autorizzato.';
        error.errorText =
          'Non sei autorizzato a effettuare l’accesso in piattaforma. Contatta il tuo manager per avere maggiori chiarimenti';
        break;
      case 406:
        error.errorText = 'Account già registrato. Accedere attraverso la pagina di login o reimpostare la password utilizzando la stessa email';
        break;
      default:
        error.errorText = err.message;
    };
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: error,
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > createUser', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* createUserWatch() {
  yield takeLatest(CREATE_USER._REQUEST, createUser);
}

function* getContentsCarousel({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response = {} } = {} } = yield call(
      landingPage.getContentsCarousel,
      payload?.ids
    );
    yield put({
      type: GET_CONTENTS_CAROUSEL._SUCCESS,
      response,
      index: payload?.index,
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > getContentsCarousel', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getContentsCarouselWatch() {
  yield takeLatest(GET_CONTENTS_CAROUSEL._REQUEST, getContentsCarousel);
}

function* getFullCatalog() {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { data },
      status,
    } = yield call(landingPage.getFullCatalog);
    if (status?.toString() === '200') {
      yield put({
        type: GET_FULL_CATALOG._SUCCESS,
        data,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > getFullCatalog', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFullCatalogWatch() {
  yield takeLatest(GET_FULL_CATALOG._REQUEST, getFullCatalog);
}

export default [
  userPasswordResetWatch(),
  landingPageGetWatch(),
  updatePasswordResetWatch(),
  createUserWatch(),
  getContentsCarouselWatch(),
  setPasswordManagerWatch(),
  setPasswordManagerFromBankWatch(),
  getFullCatalogWatch(),
];
