import styled from 'styled-components/macro';

export const ActiveSubscriptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  height: 36px;

  @media ${props => props.theme.device.laptop} {
    flex-wrap: wrap;
    height: auto;
  }

  @media ${props => props.theme.device.tablet} {
    justify-content: flex-start;
    width: 100%;
    height: 36px;
  }

  @media ${props => props.theme.device.mobile} {
    height: auto;
  }
`;

export const Add = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;

  @media ${props => props.theme.device.mobile} {
    height: auto;
  }

  &:hover {
    & span,
    a {
      color: ${props => props.theme.colors.primary.darker};
    }
  }
`;

export const Load = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;
  padding-left: 5px;
   &.link-btn{
    min-width: 160px;
    height: 35px;
    color:white;
    text-align: center;
    text-transform: uppercase;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: ${props => props.theme.colors.primary.redWave2};
    * {
    color:white;
    font-weight: 500;
    }
  }

  &:hover {
    & span,
    a {
      color: ${props => props.theme.colors.primary.darker};
    }
  }
`;

export const MenuLabel = styled.div`
  color: ${props => props.theme.colors.azureDark};
  font-family: ${props => props.theme.fontset.arial};
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 10px;

  @media ${props => props.theme.device.mobile} {
    height: auto;
  }
`;

export const Head = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 5px;
  min-height: 70px;
  padding-bottom: ${props => (props.hasBanner ? '5px' : '15px')};
  position: relative;
  /*   overflow: hidden; */
  @media ${props => props.theme.device.tablet} {
    /* background-color: white; */
    padding: 10px 0 0 0;
    margin-bottom: ${props => (props.hasBanner ? '0px' : '20px')};
    flex-direction: column;
    align-items: center;

    &.filters-opened {
      ${Filters} {
        height: auto;
      }
    }
  }

  @media ${props => props.theme.device.mobile} {
    padding: 10px 0 0 0;
  }
`;

export const Icon = styled.span`
  font-size: x-large;
  opacity: 0.65;
  order: 3;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
  margin: 6px 0;
  font-weight: bolder;

  @media ${props => props.theme.device.tablet} {
    order: 2;
    margin-right: 15px;
  }
`;

export const IconHidden = styled.span`
  display: none;
  order: 1;
  @media ${props => props.theme.device.tablet} {
    display: block;
    font-size: 20px;
    margin-right: 25px;
    padding-left: 10px;
  }
  @media ${props => props.theme.device.mobile} {
    order: 1;
  }
`;

export const Link = styled.a`
  color: ${props => props.theme.colors.primary.base};
  margin-right: 10px;

`;

export const Content = styled.div``;

// export const LoadMore = styled.div`
//   margin: 20px 0;
//   width: 100%;
//   text-align: center;
//   font-weight: bold;
//   color: ${props => props.theme.colors.primary.base};
//   span {
//     cursor: pointer;
//   }
// `;

export const TextCenter = styled.div`
  text-align: center;
`;

export const Right = styled.div`
  text-align: right;

  @media ${props => props.theme.device.mobile} {
    position: absolute;
    right: 20px;
  }
`;

export const Labels = styled.div`
  padding: 0 25px 4px 25px;
  opacity: 0.5;
  color: ${props => props.theme.colors.greenDark};
  position: relative;

  @media (max-width: 971px) {
    padding-left: 17px;
    padding-right: 17px;
    span{
      font-weight: 500;
      font-size: 14px;
    }
    opacity: 1;
    color: ${props => props.theme.colors.redWave2};
  }

  .playlist_premium {
    text-align: center;
  }
`;

export const AddWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.lightSilver};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  min-width: 290px;
  height: 36px;
`;

export const IconPlus = styled.span`
  color: ${props => props.theme.colors.primary.base};
  font-size: 26px;
`;

export const IconNotes = styled.span`
  color: ${props => props.theme.colors.primary.base};
  font-size: 22px;
  margin-right: 5px;
`;

export const SearchWrapper = styled.div`
  width: 250px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    margin-top: 15px;
  }
`;

export const IconSearch = styled.div`
  height: 30px;
  width: 30px;
  font-size: 30px;
  margin-left: 6px;
  color: ${props => props.theme.colors.primary.base};
`;

export const FiltersWrapper = styled.div`
  width: 280px;
  margin: 20px 20px 20px 0;
  z-index: 0;
`;

export const FiltersHidden = styled.div`
  order: 4;
  width: 100%;
  flex-wrap: wrap;
  padding: ${props => (props.isConfig ? '24px 0 0 0' : '12px 0 6px 0')};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    background-color: ${props => props.theme.colors.filterColor};
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
  }

  & > div,
  & > button {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${props => props.theme.device.tablet} {
    display: flex;
    & > div,
    & > button {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  @media ${props => props.theme.device.mobile} {
    & > div,
    & > button {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 177px;
  margin-left: auto;
  margin-top: 43px;
  &.submitSubscription{
    margin-left: 0;
    width: auto;
    text-align: right;
    button{
      display:inline-flex;
    }
  }
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const NewSubscriptionsCTA = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${props => props.theme.device.mobile} {
    justify-content: center;
    flex-direction: column;
  }
`;

export const Banner = styled.div`
  width: 100%;
  padding: 7px;
  background-color: ${props => props.theme.colors.slateGray};
  color: ${props => props.theme.colors.white};
  border-radius: 3px;
  position: relative;
  top: -18px;
  display: flex;
  align-items: center;

  & div:last-child {
    margin-left: auto;
    cursor: pointer;
  }
`;

export const IconBanner = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: 20px;
  padding: 0 10px;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  line-height: 0;
  border-bottom: 22px solid ${props => props.theme.colors.slateGray};
  border-left: 30px solid ${props => props.theme.colors.transparent};
  border-right: 30px solid ${props => props.theme.colors.transparent};
  position: relative;
  top: 0;
  left: 88%;

  @media ${props => props.theme.device.tablet} {
    left: 70%;
  }
`;
