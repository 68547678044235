import styled from 'styled-components';
import { NavBarHeight } from '../../../const';

export const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${NavBarHeight}px;
  background-color: ${props => props.theme.colors.primary.base};
  top: 0;
  left: 0;
  border-bottom: 1px solid lightgray;
  z-index: 1;
  position: fixed;

  @media ${props => props.theme.device.tablet} {
    &.open {
      position: fixed;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 35px;
  cursor: ${props => (props.step === 3 ? 'pointer' : 'auto')};
  width: auto;
  min-width: 200px;
  max-width: 260px;
`;
export const PageContainer = styled.div`
  min-height: calc(100vh - ${NavBarHeight}px);
  width: 100%;
  color: ${props => props.theme.colors.greenDark};
  background-color: ${props => props.theme.colors.white};
`;

export const StepContainer = styled.div`
  color: ${props => props.theme.colors.white};
`;
export const Step = styled.span`
  font-size: 26px;
  color: ${props => props.theme.colors.white};
  font-weight: 700;
`;

export const StepBar = styled.div`
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.colors.primary.lighter};
  width: ${props => props.width}%;
  transition: width 500ms;
`;
