import {
  registerProspectMock,
  shouldUseMock,
  getNotConfiguredAssetsMock,
  getOnBoardingMock,
  putAssetsActiveMock,
  createUserMock,
  completeManagerMock
} from './mock';
import {
  apiCall,
  authPrefix,
  baseUrl,
  extendUrl,
  internalPrefix,
  bePrefix,
  catalogPrefix
} from './config';

export default {
  createUser: body => {
    if (shouldUseMock()) {
      return createUserMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}signup/user${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  registerProspect: () => {
    if (shouldUseMock()) {
      return registerProspectMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}prospect/registered`,
      'GET'
    );
  },
  getNotConfiguredAssets: () => {
    if (shouldUseMock()) {
      return getNotConfiguredAssetsMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}asset/notconfigured`,
      'GET'
    );
  },
  getOnBoarding: ({ userId }) => {
    if (shouldUseMock()) {
      return getOnBoardingMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}onboarding?htmlEnabled=true`,
      'GET'
    );
  },
  putAssetsActive: params => {
    if (shouldUseMock()) {
      return putAssetsActiveMock();
    }
    return apiCall(`${bePrefix}${baseUrl}${extendUrl}asset/active`, 'PUT', {
      request: params
    });
  },
  completeManager: payload => {
    if (shouldUseMock()) {
      return completeManagerMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}updateuserprofile${internalPrefix}`,
      'PUT',
      payload,
      {
        encryptBody: true
      }
    );
  }
};
