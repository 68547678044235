import styled, { css } from 'styled-components/macro';

// eslint-disable-next-line import/no-cycle
import Button from './index';

const funzioneDisabledStile = props => {
  let style = css`
    ${props.disabled &&
      `
    background-color: ${props.theme.colors.white};
    border: 1px solid ${props.theme.colors.lightSilver};
    color: ${props.theme.colors.lightSilver};
    cursor: not-allowed;
    
    &:hover {
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.lightSilver};
      color: ${props.theme.colors.lightSilver};
    }
  `}
  `;
  switch (props.btnType) {
    case Button.TYPE.PRIMARY:
    case Button.TYPE.LOGIN:
      style = css`
        ${props.disabled &&
          `
    background-color: ${props.theme.colors.primary.base};
    border: 0;
    color: ${props.theme.colors.white};
    cursor: not-allowed;
    
    &:hover {
      background-color: ${props.theme.colors.primary.base};
      color: ${props.theme.colors.white};
    }

    &:before {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      width:100%;
      top: 0;
      left: 0;
      background: rgba(255,255,255, 0.5);
    }
  `}
      `;
      return style;
    case Button.TYPE.UPLOAD:
      style = css`
        ${props.disabled &&
          `
    background-color: ${props.theme.colors.primary.base};
    border: 0;
    color: ${props.theme.colors.white};
    cursor: not-allowed;
    
    &:hover {
      background-color: ${props.theme.colors.primary.base};
      color: ${props.theme.colors.white};
    }

    &:before {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      width:100%;
      top: 0;
      left: 0;
      background: rgba(255,255,255, 0.5);
    }
        max-width: 150px!important;
  `}
      `;
      return style;

    default:
      return style;
  }
};

const funzioneActiveStile = props => css`
  ${props.active &&
    `
    background-color:  ${props.theme.colors.primary.base};
    color: ${props.theme.colors.white};
  `}
`;

const funzioneCalcoloStile = props => {
  let style;
  switch (props.btnType) {
    case Button.TYPE.PRIMARY:
      style = css`
        background-color: ${props.theme.colors.primary.base};

        &:hover {
          background-color: ${props.theme.colors.primary.darker};
        }

        ${funzioneDisabledStile(props)};
      `;
      break;
    case Button.TYPE.SECONDARY:
      style = css`
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.primary.base};
        border: 1px solid ${props.theme.colors.primary.base};

        &:hover {
          color: ${props.theme.colors.primary.darker};
          border: 1px solid ${props.theme.colors.primary.darker};
        }

        ${funzioneDisabledStile(props)};
      `;
      break;
    case Button.TYPE.TERTIARY:
      style = css`
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.philippineGray};
        border: 1px solid ${props.theme.colors.philippineGray};

        &:hover {
          color: ${props.theme.colors.philippineGray};
          border: 1px solid ${props.theme.colors.philippineGray};
        }

        ${funzioneDisabledStile(props)};
      `;
      break;
    case Button.TYPE.LANDING:
      style = css`
          background-color: ${props.theme.colors.primary.base};
          color: ${props.theme.colors.white};
          /* border: 1px solid ${props.theme.colors.primary.base}; */
  
          &:hover {
            background-color: ${props.theme.colors.primary.darker};
            border: 1px solid ${props.theme.colors.primary.darker};
          }
  
          ${funzioneDisabledStile(props)};
        `;
      break;
    case Button.TYPE.STORE:
      style = css`
        border: 1px solid ${props.theme.colors.azureDark};
        background-color: ${props.theme.colors.white};
      `;
      break;
    case Button.TYPE.FILTER:
      style = css`
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.primary.base};
        border: 1px solid ${props.theme.colors.primary.base};
        height: 32px;
        width: auto;
        padding: 5px 15px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: ${props.theme.colors.primary.base};
          color: ${props.theme.colors.white};
        }
        ${funzioneActiveStile(props)};
      `;
      break;
    case Button.TYPE.TEAM:
      style = css`
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.primary.base};
        border: 1px solid ${props.theme.colors.primary.base};
        height: 32px;
        width: auto;
        padding: 5px 15px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: ${props.theme.colors.primary.darker};
          color: ${props.theme.colors.white};
        }
        ${funzioneActiveStile(props)};
      `;
      break;
    case Button.TYPE.LOGIN:
      style = css`
        background-color: ${props.theme.colors.primary.base};
        height: 70px;
        padding: 0 80px;
        line-height: 1.2
        font-size: 20px;
        font-weight: 400;

        @media ${props => props.theme.device.desktop} { height: 55px; padding: 0 65px; font-size: 16px; }
        @media ${props => props.theme.device.laptop} { height: 64px; padding 0 55px ; font-size: 19px; }
        @media ${props => props.theme.device.tablet} { width: 100%; height: 60px; padding: 0; font-size: 19px; font-weight: 700 }
        @media ${props => props.theme.device.mobile} { width: 100%; height: 50px; padding: 0; font-size: 16px; font-weight: 700 }

        &:hover {
          background-color: ${props.theme.colors.primary.darker};
        }

        ${funzioneDisabledStile(props)};
      `;
      break;
    case Button.TYPE.UPLOAD:
      style = css`
        max-width: 150px;
        background-color: ${props.theme.colors.primary.base};

        &:hover {
          background-color: ${props.theme.colors.primary.darker};
        }

      `;
      break;
    default:
      break;
  }

  return style;
};

const Btn = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  border: none;
  color: ${props => props.theme.colors.white};
  height: ${props => props.height}px;
  transition: all 0.3s;
  font-family: ${props => props.theme.fontset.protext};
  font-size: ${props => props.fontSize}px;
  /* font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.63;
  white-space: nowrap;
  letter-spacing: normal;
  padding: 22px 80px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${props => funzioneCalcoloStile(props)};

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
`;

const Icon = styled.span`
  margin-left: 10px;
  color: white;
  font-size: 20px;
`;

const IconStore = styled.span`
  color: ${props => props.theme.colors.azureDark};
  font-size: 30px;
`;

export { Btn, Icon, IconStore };
