import styled, {css} from 'styled-components/macro';

export const EventTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

export const SectionTitle = styled.div`
    margin-top: 20px;
    margin-bottom: 50px;
`;

export const SharedWrapper = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: calc(100% - 200px); 

  @media ${props => props.theme.device.laptop} {
    max-width: calc(100% - 80px); 
  }

  @media ${props => props.theme.device.tablet} {
    max-width: 100%; 
  }
`;

const centeredImage = css`
  background-position: center;
  background-size: cover !important; // (possible conflict with carousel styles)
  background-repeat: no-repeat;

  background-image: ${props => props.bg
      ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 96.81%), url(${props.bg})`
      : `linear-gradient(45deg, ${props.theme.colors.primary.base} 0%, ${props.theme.colors.ghostWhite} 100%)`
  };
`;

export const Card = styled.div`
  border-radius: 25px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.white};
  height: 100vh;
  max-height: 920px;
  width: 45%;
  min-width: 45%;

  @media ${props => props.theme.device.laptop} {
    width: 50%;
    max-height: 800px;
  }

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    max-height: 500px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    max-height: 150px;
  }
`;

export const EventCard = styled(Card)`
  color: ${props => props.theme.colors.white};
  ${centeredImage}
`;

export const IconLock = styled.span`
  display: flex;
  width: 40px;
  font-size: 40px;
  margin-left: auto;
  color: ${props => props.theme.colors.white};
  padding: 35px 50px;
  justify-content: center;
`;

export const EventText = styled.div`
  padding: 0 16px 0 0;

  &.align-right {
    margin-left: auto;
    cursor: pointer;
  }
`;

export const TitleWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;


    div {
      margin-bottom: 0;
      
      &:nth-child(1) {
        @media ${props => props.theme.device.mobile} {
          max-width: 100%;
        }
      }
    }

    > button[name="btn-PRIMARY"]{
        @media ${props => props.theme.device.mobile} {
            height: 37px;
            font-size: 20px;
        }

    }

    span {
        font-size: 35px;

      @media ${props => props.theme.device.mobile} {
        font-size: 20px;
      }
    }

    button {
      padding: 15px 33px;
      height: 57px;
      background-color: ${props => props.theme.colors.bitterLemon};
    }
`;

export const DetailWrapper = styled.div`
    margin-bottom: 140px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${props => props.theme.device.tablet} {
      display: flex;
      flex-direction: column;
    }
`;

export const DateContainer = styled.div`
  span {
    margin-bottom: 15px;
  }

  margin-right: 200px;
  
  @media ${props => props.theme.device.laptop} {
    margin-right: 80px;
  }

  @media ${props => props.theme.device.tablet} {
    margin-right: 0;
  }
`;

export const TextContainer = styled.div`
    width: 55%;
    height: 100vh;
    max-height: 920px;
    margin-left: 90px;

    @media ${props => props.theme.device.desktop} {
      margin-left: 40px;
      max-height: 800px;
    }

    @media ${props => props.theme.device.laptop} {
      width: 50%;
      margin-left: 30px;

    }

    @media ${props => props.theme.device.tablet} {
      width: 100%;
      margin-left: 0px;
      margin-top: 50px;
      max-height: 100%;
      height: 100%;
    }

    .location {
      margin-top: 30px;
    }

    .body {
      margin-top: 30px;
      margin-bottom: 30px;

      @media ${props => props.theme.device.mobile} {
        font-size: 14px;
        line-height: 1.2;
        }
    }

    .title {
      margin-bottom: 38px;
      line-height: 1.3;

      @media ${props => props.theme.device.mobile} {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .teacher-list-title {
      margin-bottom: 25px;

      @media ${props => props.theme.device.mobile} {
        font-size: 18px;
      }
    }
      @media ${props => props.theme.device.mobile} {
        ul li .avatar{
        margin: 10px;
        width: 40px;
        height: 40px;
        }
    }
`;

export const TeachersListWrapper = styled.div`
  margin-right: 200px;

  @media ${props => props.theme.device.laptop} {
    margin-right: 80px;
  }

  @media ${props => props.theme.device.tablet} {
    margin-right: 0;
  }
`;