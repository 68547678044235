import { createAsyncActionType } from './index';

export const GET_PATH = createAsyncActionType('playlist', 'GET_PATH');

export const GET_PLAYLIST = createAsyncActionType('playlist', 'GET_PLAYLIST');

export const GET_COURSE = createAsyncActionType('playlist', 'GET_COURSE');

export const GET_RELATED = createAsyncActionType('playlist', 'GET_RELATED');

export const SET_CATEGORIES_FILTER = '@@playlist/SET_CATEGORIES_FILTER';

export const GET_QUESTIONS = createAsyncActionType(
  'configuration',
  'GET_QUESTIONS'
);

export const ADD_SURVEY = createAsyncActionType('playlist', 'ADD_SURVEY');

export const ADD_REVIEW = createAsyncActionType('playlist', 'ADD_REVIEW');

export const SET_RECOMMENDED = createAsyncActionType(
  'playlist',
  'SET_RECOMMENDED'
);

export const GET_CATEGORIES_USER = createAsyncActionType(
  'playlist',
  'GET_CATEGORIES_USER'
);

export const GET_CATEGORIES_PILLS = createAsyncActionType(
  'playlist',
  'GET_CATEGORIES_PILLS'
);

export const GET_KEEP_WATCHING = createAsyncActionType(
  'playlist',
  'GET_KEEP_WATCHING'
);

export const SHARE_COMMENT = createAsyncActionType('playlist', 'SHARE_COMMENT');

// export const UPDATE_PLAYLIST = '@@playlist/UPDATE_PLAYLIST';
export const SET_SECTION = '@@playlist/SET_SECTION';

export const PLAYLISTS_INITIALSTATE = '@@playlist/PLAYLISTS_INITIALSTATE';

export const GET_PACKAGES = createAsyncActionType('playlist', 'GET_PACKAGES');

export const GET_PACKAGE = createAsyncActionType('playlist', 'GET_PACKAGE');

export const GET_EVENTS = createAsyncActionType('playlist', 'GET_EVENTS');

export const GET_ALL_EVENTS = createAsyncActionType('playlist', 'GET_ALL_EVENTS');

export const GET_EVENT = createAsyncActionType('playlist', 'GET_EVENT');

export const GET_EVENT_LOCKED = createAsyncActionType('playlist', 'GET_EVENT_LOCKED');

export const GET_EVENTS_LOCKED = createAsyncActionType('playlist', 'GET_EVENTS_LOCKED');

export const GET_EVENT_SESSIONS= createAsyncActionType('playlist', 'GET_EVENT_SESSIONS');

export const GET_SELECTIONS = createAsyncActionType('playlist', 'GET_SELECTIONS');

export const GET_COLLECTIONS = createAsyncActionType('playlist', 'GET_COLLECTIONS');

export const GET_CATEGORIES = createAsyncActionType('playlist', 'GET_CATEGORIES');

export const GET_CATEGORY_PLAYLIST = createAsyncActionType('playlist', 'GET_CATEGORY');

export const GET_RECOMMENDED_COLLECTIONS = createAsyncActionType('playlist', 'GET_RECOMMENDED_COLLECTIONS');

export const RESET_PLAYLIST = createAsyncActionType('playlist', 'RESET_PLAYLIST');

export const CLEAR_PACKAGE_BREADCRUMB = '@@playlist/CLEAR_PACKAGE_BREADCRUMB';

export const SET_PLAYLIST_HISTORY = '@@playlist/SET_PLAYLIST_HISTORY';