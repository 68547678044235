import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {push} from 'redux-first-history';
import PropTypes from 'prop-types';
import {withMediaQueries} from 'hoc/withMediaQueries';
import SectionTitle from 'ui/components/Homepage/SectionTitle';
import {TeachersList} from '../EventDetails/style';
import { Button, CustomSelect } from 'ui/atoms';
import { getDatesDay, getDatesHour} from 'utils/date-format';
import { styles } from 'utils/multi-select';
import 'react-multi-carousel/lib/styles.css';
import {Container} from 'ui/gridSystem';
import {Footer} from 'ui/components';
import {GET_EVENT_LOCKED, GET_EVENT_SESSIONS} from 'redux/actions/playlist';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import {Spinner, Share} from 'ui/components';
import routes from 'routes';
import { SelectWrapper } from 'ui/components/TableCard/style';
import MainContainer from '../style';
import {detailType} from 'utils/common';
import {MODAL_CLOSE, MODAL_OPEN} from 'redux/actions';
import { getPreviousPath } from 'utils/router';
import SuccessModal from "ui/components/Modals/SuccessModal";
import ModalErrors from "ui/components/Modals/ModalErrors";
import {
	EventTitle,
	EventText,
	EventCard,
	TitleWrapper,
	DetailWrapper,
	TextContainer,
	TeachersListWrapper,
	IconLock,
	DateContainer,
	SharedWrapper
} from './style';
import {
	HeaderWrapper,
	FirstHalfWrapper,
	FirstHalf,
	HeaderIcon,
	HtmlWrapper
} from 'pages/CollectionDetails/style';
import PlaceholderImg from 'ui/assets/img/S4C_THUMBNAIL.png';
import { EVENT_LOCKED_SUBSCRIBE } from 'redux/actions/subscriptions';

const EventLockedDetails = ({
		// HOC store
		event,
		eventSessions,
		// HOC dispatch
		getEventLocked,
		getEventSessions,
		eventLockedSubscribe,
		pushUrl,
		openSuccessModal,
		openModalError,
		myUserId,
		router
	}) => {
	const {id, productId} = useParams();

	const [currentSessionId, setCurrentSessionId] = useState(null);
	const [currentSessionTeachers, setCurrentSessionTeachers] = useState(null);
	const [currentSessionLocation, setCurrentSessionLocation] = useState(null);
	const [sessionOptions, setSessionOptions] = useState(null);

	useEffect(() => {
		getEventLocked(id, productId);
		getEventSessions(id);
	}, [id, getEventLocked, getEventSessions]);

	useEffect(() => {
		if (Boolean(eventSessions)) {
			setSessionOptions(eventSessions?.map(session => 
				({label: getDatesDay(session).sameDay ? `${getDatesDay(session, true).text} - ${getDatesHour(session)}`: `${getDatesDay(session, true).text}`, 
				value: session.sessionId})));
			setCurrentSessionId(eventSessions[0]?.sessionId);
			setCurrentSessionLocation(eventSessions[0]?.locations[0]?.city);
			setCurrentSessionTeachers(eventSessions[0]?.teachers);
		}
	}, [eventSessions]);

	const onChangeSession = value => {
		setCurrentSessionId(value.value);
		setCurrentSessionTeachers(eventSessions.filter(session => session.sessionId === value.value)[0].teachers);
		setCurrentSessionLocation(eventSessions.filter(session => session.sessionId === value.value)[0].locations[0]?.city);
	};

    const sendRequest = () => {
        const payload = {
            userId: myUserId,
            productId: event.productId,
            sessionId: currentSessionId,
            collId: event.itemId,
            type: "live_event",
            domainId: 301
        };

		eventLockedSubscribe(payload);

        /*const xhr = new XMLHttpRequest();

          xhr.open('POST', 'https://s4b2api.ispfdevelop.com/asset/api/v1/reccomendation');
            xhr.setRequestHeader('Domain', 301);
            xhr.setRequestHeader('Authorization', sessionStorage.Authorization);
              xhr.onload = function() {
                if (xhr.status === 200) {
                  openSuccessModal({
                    description: "Richiesta effettuata con successo.",
                  });
                } else {
                  openModalError({
                    description: "Si è verificato errore imprevisto. Ci scusiamo per il disagio"
                  });
                }
              };
          xhr.send(JSON.stringify(payload));*/
    }

	if (!event) return <Spinner/>;

	return (
		<>
			<MainContainer>
				<HeaderWrapper>
					<FirstHalfWrapper>
						<Container>
							<FirstHalf>
								<HeaderIcon
									className="icon-arrow-right first"
									onClick={() => pushUrl(getPreviousPath(router))}
								/>
								<EventTitle id="playlist-title" className='mb-0'>
									<EventText className="flex-start">
										<ProText
											type="playlistTitle"
											configuration={{color: 'white'}}
										>
											Indietro
										</ProText>
									</EventText>
								</EventTitle>
							</FirstHalf>
						</Container>
					</FirstHalfWrapper>
				</HeaderWrapper>
				<Container>
					<TitleWrapper>
						<SectionTitle>
							{event?.eventType === detailType.VIRTUAL ? 'Virtual Classroom' : 'Live Classroom'}
						</SectionTitle>
						<Button
							type="PRIMARY"
							text="Iscriviti"
							height={35}
							fontSize={25}
							onClick={sendRequest}
						/>
					</TitleWrapper>
					<DetailWrapper>
						<EventCard bg={event.cover ?? PlaceholderImg}>
							<IconLock className="icon-icona-lucchetto"/>
						</EventCard>
						<TextContainer>
							<ProDisplay className="title"
								configuration={{
									fontSize: 50,
									fontWeight: 500,
								}}>
								{event.title}
							</ProDisplay>
							<DateContainer>
							<SelectWrapper>
								<CustomSelect
									id='session'
									hideSelectedOptions={true}
									placeholder="Sessione"
									options={sessionOptions}
									styles={styles}
									selected={!!sessionOptions ? sessionOptions.filter(session => session.value === currentSessionId) : ''}
									onChange={onChangeSession}
								/>
							</SelectWrapper>
							</DateContainer>
							{ !!currentSessionLocation && <ProDisplay className="location"
							configuration={{
								fontSize: 20,
								fontWeight: 400,
							}}>
								{currentSessionLocation}
							</ProDisplay>}
							<ProDisplay className="body"
								type="5"
								configuration={{
									fontSize: 25,
									fontWeight: 400,
								}}>
							<HtmlWrapper 		
								dangerouslySetInnerHTML={{ __html: event.body }}
							/>
							</ProDisplay>
							<SharedWrapper>
								<Share text='Condividi nella bacheca' 
									eventId={event.itemId}
									eventType={'LIVE_EVENT'}
									eventTitle={event.title}
									eventDescription={event.body}
									eventImage={event.cover}></Share>
							</SharedWrapper>
							<ProDisplay className="teacher-list-title"
								configuration={{
									fontSize: 30,
									fontWeight: 500,
								}}>
									Docenti
							</ProDisplay>
							<TeachersListWrapper>
								<TeachersList>
									{currentSessionTeachers ? (
										currentSessionTeachers.map((teach, idx) =>
											<li key={`${teach.name}-${teach.lastName}-${idx}`}>
												<div className={`avatar ${!teach.cover ? 'no-cover' : ''}`}>
													{teach.cover
														?
														<img alt={`${teach.name} ${teach.lastName}`} src={teach.cover}/>
														:
														<ProText>{!!teach.name ? teach.name?.charAt(0) : '' + teach.lastName ? teach.lastName?.charAt(0) : ''}</ProText>
													}
												</div>
												<div className='teach-info'>
													<ProDisplay
														type="5"
														configuration={{
															fontSize: 18,
															fontWeight: 500,
														}}
													>
														{teach.name} {teach.lastName}
													</ProDisplay>
													<ProDisplay
														type="5"
														configuration={{
															fontSize: 12,
															fontWeight: 400,
															lineHeight: 1.8
														}}
													>
														{teach.info}
													</ProDisplay>
												</div>
											</li>
										)
									) : null}
								</TeachersList>
							</TeachersListWrapper>
						</TextContainer>
					</DetailWrapper>
				</Container>
			</MainContainer>
			<Footer/>
		</>
	);
};

EventLockedDetails.defaultProps = {
	event: {},
	eventSessions: []
};

EventLockedDetails.propTypes = {
	// HOC (connect, state)
	event: PropTypes.object,
	eventSessions: PropTypes.array,
	router: PropTypes.object,
	
	// HOC (connect, dispatch)
	pushUrl: PropTypes.func,
	openModal: PropTypes.func,
    myUserId: PropTypes.string
};

export default withMediaQueries(
	connect(
		state => {
			const {
				events: {selectedEventLocked: event}
			} = state.playlist;
			const {router} = state;
			const eventSessions = event.eventSessions;
            const {
                  user: { userId: myUserId }
                } = state.authentication;
			return {
				event,
				eventSessions,
				router,
				myUserId
			};
		},
		dispatch => ({
			eventLockedSubscribe: (payload) => dispatch({type: EVENT_LOCKED_SUBSCRIBE._REQUEST, payload}),
			getEventLocked: (itemId, productId) => dispatch({type: GET_EVENT_LOCKED._REQUEST, itemId, productId}),
			getEventSessions: itemId => dispatch({type: GET_EVENT_SESSIONS._REQUEST, itemId}),
			pushUrl: url => dispatch(push(url)),
            openModalError: payload => dispatch({ type: MODAL_OPEN, id: ModalErrors.id, payload }),
            openSuccessModal: payload => dispatch({type: MODAL_OPEN, id: SuccessModal.id, payload})

		})
	)(EventLockedDetails)
);
