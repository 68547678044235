import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Col, Container, Row } from 'ui/gridSystem';
import { Button, CustomInput } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import routes from 'routes';
import validator from 'utils/regex-validator';
import OnBoardingLayout from 'ui/components/OnBoardingLayout';
import ProText from 'ui/typography/proText';
import PasswordStrength from 'ui/atoms/PasswordStrenght';
import {
  ONBOARDING_STORE_DATA,
  stepsOnboarding
} from 'redux/actions/onBoarding';
import getQueryParams from 'utils/getQueryParams';
import { errorPwd } from 'utils/password';

import {
  ChangePasswordContainer,
  Title,
  Body,
  ButtonWrapper,
  InputWrapper
} from './style';
import PasswordSuggestion from 'ui/atoms/PasswordSuggestion';

const newField = () => ({
  value: undefined,
  valid: true
});

const initialForm = {
  password: newField(),
  confirmpassword: newField()
};

const ChangePassword = ({
  mediaIsPhone,
  pushUrl,
  storePassword,
  stepOnBoarding
}) => {
  const [form, setForm] = useState(initialForm);

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const validatePassword = () => {
    setForm(s => {
      const newState = { ...s };
      if (newState.password.value !== undefined) {
        newState.password.valid =
          validator.password(newState.password.value) || errorPwd;
      }
      if (newState.confirmpassword.value !== undefined) {
        newState.confirmpassword.valid =
          newState.password.value === newState.confirmpassword.value;
      }
      return newState;
    });
  };

  const handleOnBlur = (type, field, callback) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });

    if (callback) callback();
  };

  const buttonDisabled =
    form.password.value === undefined ||
    form.confirmpassword.value === undefined ||
    form.password.value !== form.confirmpassword.value ||
    form.password.valid !== true;

  const handleUpdatePassword = () => {
    const prospectId = getQueryParams('prospectId');
    storePassword({ password: form.password?.value, prospectId });
  };

  useEffect(() => {
    if (stepOnBoarding === stepsOnboarding.STEP2) {
      pushUrl(routes.compileProfile.path);
    }
  }, [stepOnBoarding, pushUrl]);

  useEffect(() => {
    const prospectId = getQueryParams('prospectId');
    if (!prospectId) {
      pushUrl(routes.landingPage.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnBoardingLayout step={1}>
      <Container>
        <Row>
          <ChangePasswordContainer>
            <Col lg={6} md={8} sm={12}>
              <Body>
                <Title>
                  {mediaIsPhone ? (
                    <ProDisplay
                      type="title"
                      configuration={{
                        fontSize: '20',
                        color: 'primary',
                        colorType: 'base'
                      }}
                    >
                      Benvenuto in Skills4Capital
                    </ProDisplay>
                  ) : (
                    <ProDisplay
                      type="title"
                      configuration={{
                        color: 'primary',
                        colorType: 'base'
                      }}
                    >
                      Benvenuto in Skills4Capital
                    </ProDisplay>
                  )}
                </Title>
                <Title>
                  <ProText type="description">
                    Imposta la tua password e inserisci i tuoi dati per accedere
                    alla piattaforma di formazione a te dedicata.
                  </ProText>
                </Title>

                <InputWrapper>
                  <CustomInput
                    type="password"
                    placeholder="Password"
                    value={form.password.value}
                    onChange={val => handleOnChange('password', val)}
                    onBlur={() =>
                      handleOnBlur('password', 'password', validatePassword)
                    }
                    error={
                      typeof form.password.valid === 'string'
                        ? form.password.valid
                        : false
                    }
                  />
                  <PasswordStrength password={form.password.value} />
                </InputWrapper>
                <InputWrapper>
                  <CustomInput
                    type="password"
                    placeholder="Conferma password"
                    value={form.confirmpassword.value}
                    onChange={val => handleOnChange('confirmpassword', val)}
                    onBlur={validatePassword}
                    error={!form.confirmpassword.valid}
                  />
                </InputWrapper>

                <PasswordSuggestion password={form.password} />

                <ButtonWrapper>
                  <Button
                    type={Button.TYPE.PRIMARY}
                    text="Conferma"
                    disabled={buttonDisabled}
                    onClick={handleUpdatePassword}
                  />
                </ButtonWrapper>
              </Body>
            </Col>
          </ChangePasswordContainer>
        </Row>
      </Container>
    </OnBoardingLayout>
  );
};

ChangePassword.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  // HOC (store)
  stepOnBoarding: PropTypes.string,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  storePassword: PropTypes.func.isRequired
};

export default connect(
  ({ onBoarding: { stepOnBoarding } }) => ({ stepOnBoarding }),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    storePassword: payload => dispatch({ type: ONBOARDING_STORE_DATA, payload })
  })
)(withMediaQueries(ChangePassword));
