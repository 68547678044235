import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'ui/gridSystem';

import { CustomInput } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import {
  SIGNUP_NEXT_STEP,
  SIGNUP_RESEND_CODE,
  SIGNUP_SET_OTPCODE
} from 'redux/actions/signup';
import ResendOTPModal from 'ui/components/Modals/ResendOTPModal';
import routes from 'routes';
import { push } from 'redux-first-history';
import {
  ResetPasswordContainer,
  TitleContainer,
  Title,
  Subtitle,
  ResendCode,
  ResendCodeWrapper
} from './style';

const newField = () => ({
  value: undefined,
  valid: true
});

const initialForm = {
  otp: newField()
};

const ConfirmOtp = ({
  onChange,
  resendConfirmCode,
  updateOptCode,
  step,
  setStepOTP,
  account,
  pushUrl
}) => {
  const [form, setForm] = useState(initialForm);

  const formValid = (Object.keys(form) || []).map(key =>
    Boolean(form[key].value && form[key].value.length > 0 && form[key].valid)
  );

  useEffect(() => {
    if (!account.email) pushUrl(routes.access.path);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (onChange) {
      const isValid = formValid.filter(bool => bool === false).length <= 0;
      onChange(isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleOnChange = (field, newValue) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });
    updateOptCode(newValue);
  };

  useEffect(() => {
    if (step.num !== 3) {
      setStepOTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Row>
          <ResetPasswordContainer>
            <Col xl={4} lg={5} md={8} sm={10}>
              <TitleContainer>
                <Title>
                  <ProDisplay
                    type="title"
                    configuration={{
                      color: 'primary',
                      colorType: 'base',
                      fontWeight: 400
                    }}
                  >
                    Codice OTP
                  </ProDisplay>
                </Title>
                <ProText>
                  <Subtitle>Inserisci il codice OTP ricevuto via mail</Subtitle>
                </ProText>
              </TitleContainer>
            </Col>

            <Col xl={4} lg={5} md={8} sm={10} className="mb-3">
              <CustomInput
                type="text"
                placeholder="Codice OTP"
                value={form.otp.value}
                onChange={val => handleOnChange('otp', val)}
                error={
                  typeof form.otp.valid === 'string'
                    ? form.otp.valid
                    : !form.otp.valid
                }
              />
            </Col>
            <Col xl={4} lg={5} md={8} sm={10} className="mb-3">
              <ResendCodeWrapper onClick={resendConfirmCode}>
                <ProText>
                  <ResendCode>Non ho ricevuto email. Invia di nuovo</ResendCode>
                </ProText>
              </ResendCodeWrapper>
            </Col>
          </ResetPasswordContainer>
        </Row>
      </Container>
      <ResendOTPModal />
    </>
  );
};

ConfirmOtp.propTypes = {
  onChange: PropTypes.func.isRequired,
  // HOC withMediaQueries

  // HOC (connect, state)
  step: PropTypes.object,
  account: PropTypes.object,

  // HOC (connect, dispatch)
  resendConfirmCode: PropTypes.func.isRequired,
  updateOptCode: PropTypes.func.isRequired,
  setStepOTP: PropTypes.func.isRequired,
  pushUrl: PropTypes.func
};

export default connect(
  ({ signup }) => ({ step: signup.step, account: signup.account }),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    resendConfirmCode: () => dispatch({ type: SIGNUP_RESEND_CODE._REQUEST }),
    updateOptCode: payload => dispatch({ type: SIGNUP_SET_OTPCODE, payload }),
    setStepOTP: () => dispatch({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 2 })
  })
)(withMediaQueries(ConfirmOtp));
